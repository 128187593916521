import {
  FormItem,
  DatePicker as FaveDatePicker,
  DatePickerProps,
} from 'fave-ui';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { formatDate } from '../../../utils/utilFunctions';

export type FaveDatePickerProps<T> = DatePickerProps & {
  name: keyof T;
  label?: string;
  dateFormat?: any;
};

const DatePicker = <T extends {}>({
  name,
  label,
  disabled,
  dateFormat = formatDate,
  placeholder = 'Select date',
}: FaveDatePickerProps<T>) => {
  const { errors, touched, handleBlur, values, setFieldValue } =
    useFormikContext<T>();

  const value = values[name] ? new Date(values[name] as Date) : null;

  const error = errors[name] as unknown as string;

  const isTouched = touched[name] as unknown as string;

  const helpText = isTouched && error !== undefined ? error : '';

  const datepicker = useMemo(
    () => (
      <FaveDatePicker
        name={name}
        value={value}
        onChange={(date) => setFieldValue(name, date)}
        onBlur={handleBlur}
        disabled={disabled}
        format={dateFormat}
        placeholder={placeholder}
      />
    ),
    [name, value, setFieldValue, disabled, dateFormat, placeholder]
  );

  return (
    <FormItem
      label={label}
      validateStatus={error !== undefined && isTouched ? 'error' : 'success'}
      help={helpText}
    >
      {datepicker}
    </FormItem>
  );
};

export default DatePicker;
