import { FormItem, Select as FaveSelect } from 'fave-ui';
import { useFormikContext } from 'formik';
import { TreeSelect } from 'antd';
import { CSSProperties } from 'react';

type SelectOptionsType = {
  label: string;
  key: string;
  value: string;
};

type SelectProps<T> = {
  name: keyof T;
  label: string;
  placeholder: string;
  defaultValue?: string;
  showArrow?: boolean;
  allowClear?: boolean;
  bordered?: boolean;
  options?: SelectOptionsType[];
  className?: string;
  isError?: boolean;
  help?: string;
  disabled?: boolean;
  withCheckbox?: boolean;
  treeDefaultExpandAll?: boolean;
  style?: CSSProperties;
  mode?: 'multiple' | 'tags' | undefined;
  isLoadingSelect? :boolean;
  onSelect?: (event: string) => void;
  onSearch?: (value: string) => void;
  onBlur?: () => void;
  filterOption?: boolean;
};

export const Select = <T extends {}>({
  name,
  label,
  placeholder,
  options,
  className,
  isError,
  help,
  withCheckbox,
  showArrow,
  treeDefaultExpandAll,
  style,
  mode,
  onSelect,
  onSearch,
  onBlur,
  isLoadingSelect,
  filterOption,
  ...otherProps
}: SelectProps<T>) => {
  const { errors, touched, values, setFieldValue, setFieldTouched } = useFormikContext<T>();

  const value = values[name];
  const error = errors[name] as unknown as string;
  const isTouched = touched[name] as unknown as string;

  const helpText =
    isTouched &&
      (error !== undefined || (isError !== undefined && help !== undefined))
      ? help && isError
        ? help
        : error
      : '';
  return (
    <FormItem
      label={label}
      validateStatus={error !== undefined && isTouched ? 'error' : 'success'}
      help={helpText}
    >
      {!withCheckbox ? (
        <FaveSelect
          onBlur={() => {
            onBlur && onBlur()
            setFieldTouched(String(name), true)}
          }
          placeholder={placeholder}
          options={options}
          onChange={(e) => {
            onSelect && onSelect(e)
            setFieldValue(name as string, e)
          }}
          onSearch={onSearch}
          className={className}
          value={value}
          mode={mode}
          loading={isLoadingSelect}
          filterOption={filterOption}
          {...otherProps}
        />
      ) : (
        <TreeSelect
          onBlur={() => setFieldTouched(String(name), true)}
          allowClear={true}
          placeholder={placeholder}
          treeCheckable={true}
          style={style}
          className={className}
          showArrow={showArrow}
          treeDefaultExpandAll={treeDefaultExpandAll}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          onChange={(e) => { setFieldValue(name as string, e) }}
          value={value}
          treeData={options?.length ? [
            {
              title:
                (values[name] as any)?.length > 0 ? (
                  <span>Unselect all</span>
                ) : (
                  <span>Select all</span>
                ),
              value: "all",
              children: options
            }
          ]
            : []}
        />
      )
      }
    </FormItem>
  );
};
