import { Card } from 'antd';
import { Descriptions, DescriptionsItem } from 'fave-ui';
import style from './style.module.css';

type CardSectionDataType = {
  key: string;
  label: string;
  children: React.ReactNode;
};

const CardSectionTitle = ({
  sectionNumber,
  title,
}: {
  sectionNumber: string;
  title: string;
}) => (
  <div className={style.cardSectionTitle}>
    <div className={style.sectionNumber}>{sectionNumber}</div>{' '}
    <span className={style.title}>{title}</span>
  </div>
);

export const CardSection = ({
  sectionNumber,
  title,
  data,
}: {
  sectionNumber: string;
  title: string;
  data: CardSectionDataType[];
}) => (
  <Card className={style.cardSection}>
    <CardSectionTitle sectionNumber={sectionNumber} title={title} />
    <Descriptions layout="vertical" colon={false} column={1}>
      {data.map((item) => (
        <DescriptionsItem {...item} key={item.key} />
      ))}
    </Descriptions>
  </Card>
);

export default CardSection;
