import React, { useMemo } from 'react';
import { Trash } from 'phosphor-react';
import { FormModal } from '../../../components/Modal/FormModal';
import GreyDivider from '../../../components/GreyDivider';
import { MakerCheckerModal } from '../helper/enum';

type ApproveDeleteFormModalType = {
  handleOkAction: () => void;
  visible: boolean;
  requestId: string;
  confirmLoading: boolean;
  onCancel: () => void;
  selectedAction: string;
};

const ApproveDeleteFormModal: React.FC<ApproveDeleteFormModalType> = (
  props
) => {
  const {
    handleOkAction,
    visible,
    requestId,
    confirmLoading,
    onCancel,
    selectedAction,
  } = props;

  const modalInformation = useMemo(() => {
    switch (selectedAction) {
      case MakerCheckerModal.Approve:
        return {
          title: 'Approve confirmation',
          width: 430,
          okText: 'Submit',
          chilren: (
            <div>
              <div className="text-favepink">R-{requestId}</div>
              <div>
                You’re about to make an irreversible action. Confirm to approve
                this change request?
              </div>
            </div>
          ),
        };
      case MakerCheckerModal.Delete:
        return {
          title: (
            <div className="flex">
              <Trash size={24} className='mr-4'/>
              <span>Delete Request?</span>
            </div>
          ),
          width: 400,
          okText: 'Delete',
          chilren: (
            <div>
              <div className="ml-10">
                <p className="text-favepink">R-{requestId}</p>
                <p>
                  You are about to perform an irreversible action, are you sure
                  to delete this change request?
                </p>
              </div>
            </div>
          ),
        };
      default:
        return {};
    }
  }, [selectedAction, requestId]);

  return (
    <FormModal
      destroyOnClose
      title={modalInformation.title}
      visible={visible}
      width={modalInformation.width}
      okText={modalInformation.okText}
      onOk={handleOkAction}
      confirmLoading={confirmLoading}
      onCancel={confirmLoading ? undefined : onCancel}
      cancelText="Cancel"
    >
      {modalInformation.chilren}
      <GreyDivider />
    </FormModal>
  );
};

export default ApproveDeleteFormModal;
