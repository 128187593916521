import React, { useMemo, useState } from 'react';
import {
  Descriptions,
  DescriptionsItem,
  FilterIcon,
  Popover,
  Table,
} from 'fave-ui';
import {
  useFetchDealsList,
  useFetchDealsSummary,
} from '../../../services/ProductDashboard/useFetchDealsSummary';
import { useParams } from 'react-router';
import style from '../style.module.css';
import { ColumnsType } from 'antd/lib/table/interface';
import { TableHeaderSort } from '../../../components/common/TableHeaderSort';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  dateOrDash,
  formatLongNumbersWithCommas,
  formatDecimalPlaces,
  formatDateRange,
  valueOrDash,
} from '../../../utils/utilFunctions';
import { TableSettingsType } from '../../../types/configTypes';
import { useFetchBDUsersList } from '../../../services/ProductDashboard/bdUsersList';
import { StatusTag } from '../../../components/common/StatusTag';
import { DealsType, OutletType } from '../../../types/dataTypes';
import { useFetchCompanyOutlets } from '../../../services/useFetchCompanyOutlets';
import { CaretDown } from 'phosphor-react';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

export const tableConfigDefault = {
  page: 1,
  limit: 10,
  sort_order: undefined,
  sort_by: undefined,
  filter: undefined,
};

export const DealsTab: React.FC = () => {
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(tableConfigDefault);
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : 0;
  const { data: bdUsersListData } = useFetchBDUsersList({
    company_id: parsedID,
  });
  const { data: dealsOutletsList } = useFetchCompanyOutlets({
    company_id: parsedID,
    scope: 'deals',
  });
  const { data: dealsSummaryData } = useFetchDealsSummary({
    company_id: parsedID,
  });
  const { data: dealsOutletsData, isFetching } = useFetchDealsList({
    company_id: parsedID,
    page: tableConfig.current,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: tableConfig.filter,
  });

  const descriptionItems = [
    {
      key: '1',
      label: 'Total Deals',
      children: dealsSummaryData?.total_deals,
    },
    {
      key: '2',
      label: 'Live Deals',
      children: dealsSummaryData?.live_deals,
    },
    {
      key: '3',
      label: 'Scheduled Deals',
      children: dealsSummaryData?.scheduled_deals,
    },
    {
      key: '4',
      label: 'Inactive Deals',
      children: dealsSummaryData?.inactive_deals,
    },
    {
      key: '5',
      label: 'Expired Deals',
      children: dealsSummaryData?.expired_deals,
    },
  ];

  const dealsTabColumns: ColumnsType<DealsType> = [
    {
      dataIndex: 'signature_date',
      title: 'Date Signed',
      width: 135,
      render: (date) => dateOrDash(date),
    },
    {
      dataIndex: 'bd_name',
      title: 'Signed by',
      filters: bdUsersListData
        ? bdUsersListData.map((item) => {
            return { text: item.bd_name, value: item.bd_email };
          })
        : [],
      filterIcon: FilterIcon,
      filterMode: 'tree',
      filterSearch: true,
      width: 130,
      render: (data) => data || 'N/A',
    },
    {
      dataIndex: 'status',
      title: 'Deal Status',
      filterIcon: FilterIcon,
      width: 136,
      filters: [
        { text: `Live (${dealsSummaryData?.live_deals})`, value: 'live' },
        {
          text: `Inactive (${dealsSummaryData?.inactive_deals})`,
          value: 'inactive',
        },
        {
          text: `Scheduled (${dealsSummaryData?.scheduled_deals})`,
          value: 'scheduled',
        },
      ],
      filterMode: 'tree',
      render: (data) => <StatusTag status={data} />,
    },
    {
      dataIndex: 'id',
      title: TableHeaderSort('Deal ID'),
      sorter: true,
      width: 100,
      render: (data) => data,
    },
    {
      dataIndex: 'deal_name',
      title: 'Deal Name',
      width: 236,
      render: (data) => data,
    },
    {
      title: 'Price',
      width: 170,
      render: (data) => (
        <>
          {data && data.discounted_price ? (
            <>
              <span className={style.strikeThroughText}>
                {data.original_price}
              </span>
              <span>{data.discounted_price}</span>
            </>
          ) : (
            <span>{data.original_price}</span>
          )}
        </>
      ),
    },
    {
      title: 'Feature Period',
      width: 236,
      render: (data) =>
        data && data.deal_feature_start_date && data.deal_feature_end_date
          ? formatDateRange(
              data.deal_feature_start_date,
              data.deal_feature_end_date
            )
          : 'N/A',
    },
    {
      dataIndex: 'margin',
      title: 'Margin',
      width: 100,
      render: (data) => valueOrDash(data && `${formatDecimalPlaces(data)}%`),
    },
    {
      title: 'Sold Count / Capacity',
      width: 180,
      render: (data) =>
        data && data.sold_count >= 0 && data.capacity
          ? `${formatLongNumbersWithCommas(
              data.sold_count
            )}/${formatLongNumbersWithCommas(data.capacity)}`
          : 'N/A',
    },
    {
      dataIndex: 'outlets',
      title: 'Outlets',
      filterIcon: FilterIcon,
      width: 200,
      filters: dealsOutletsList?.outlets.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      }),
      render: (data: OutletType[]) => {
        if (data) {
          const content = (
            <div>
              {data.map((item) => {
                return <p key={item.id}>{item.name}</p>;
              })}
            </div>
          );

          return data.length > 1 ? (
            <div className={style.outletColumn}>
              <span>All outlets</span>
              <Popover
                title={`${data.length} outlets`}
                content={content}
                trigger={'hover'}
                placement={'bottom'}
              >
                <CaretDown size={16} className={style.rotateCaret} />
              </Popover>
            </div>
          ) : (
            <span>{data[0]?.name || '-'}</span>
          );
        }
        return '-';
      },
    },
  ];

  const tableChangeHandler = useMemo(
    () =>
      createOnTableChangeHandler(setTableConfig, (filters) => {
        const filteredEmails = filters.bd_name
          ? filters.bd_name.toString()
          : '';
        const filteredStatuses = filters.status
          ? filters.status.toString()
          : '';
        const filteredOutlets = filters.outlets
          ? filters.outlets.toString()
          : '';

        return `bd_email=${filteredEmails}|status=${filteredStatuses}|outlet.id=${filteredOutlets}`;
      }),
    [setTableConfig]
  );

  return (
    <div className={style.dealsTab}>
      <Descriptions
        title={'Deals Summary'}
        layout="vertical"
        colon={false}
        column={4}
        className={style.descriptions}
      >
        {descriptionItems.map(({ key, ...item }) => (
          <DescriptionsItem key={key} {...item} />
        ))}
      </Descriptions>
      <Table
        size={'large'}
        dataSource={dealsOutletsData?.deals}
        rowKey={(row) => row.id}
        columns={dealsTabColumns}
        scroll={{
          x: tableWidth,
          y: tableHeight,
        }}
        loading={isFetching}
        onChange={tableChangeHandler}
        pagination={{
          hideOnSinglePage: true,
          total: dealsOutletsData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.current,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
