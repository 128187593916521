import React from 'react';
import { useParams } from 'react-router';
import Form from '../../../components/common/form/Form';
import { PageHeaderWithBackButton } from '../../../components/common/PageHeaderWithBackButton';
import {
  FetchCompanyApplicationFeeResponse,
  useFetchCompanyApplicationFee,
  useUpdateApplicationFee,
} from '../../../services/PaymentMode/usePaymentMode';
import { calcTableHeight } from '../../../utils/utilFunctions';
import style from '../style.module.css';
import * as Yup from 'yup';
import ApplicationFeeForm from '../ApplicationFeeForm';
import { Spin } from 'antd';
import { Message } from 'fave-ui';
import { toolRedirect } from '../../../services/http';
import { FormikHelpers } from 'formik';

const tableHeight = calcTableHeight(225);

const defaultValues = {
  annual_license_fee: undefined,
  annual_license_fee_remarks: undefined,
  one_time_setup_fee: undefined,
  one_time_setup_fee_remarks: undefined,
  annual_maintenance_fee: undefined,
  annual_maintenance_fee_remarks: undefined,
  any_other_fee: undefined,
  any_other_fee_remarks: undefined,
};

const formSchema = Yup.object().shape({
  annual_license_fee: Yup.number().min(0),
  annual_license_fee_remarks: Yup.string(),
  one_time_setup_fee: Yup.number().min(0),
  one_time_setup_fee_remarks: Yup.string(),
  annual_maintenance_fee: Yup.number().min(0),
  annual_maintenance_fee_remarks: Yup.string(),
  any_other_fee: Yup.number(),
  any_other_fee_remarks: Yup.string(),
});

const ApplicationFeeView: React.FC = () => {
  const { id } = useParams();

  const parsedID = parseInt(id || '');

  const { data: fetchCompanyApplicationFeeData, isFetching } =
    useFetchCompanyApplicationFee({ company_id: parsedID });

  const { mutate: updateApplicationFee } = useUpdateApplicationFee();

  const initialValues = fetchCompanyApplicationFeeData || defaultValues;

  const onSuccessSubmitForm = () =>
    Message.success({
      content: 'Successfully submitted Application Fee.',
    }).then(() =>
      toolRedirect(`/admin/merchant_connect/merchants/${parsedID}/details`)
    );

  const onSubmitForm = (
    values: FetchCompanyApplicationFeeResponse,
    { setSubmitting }: FormikHelpers<FetchCompanyApplicationFeeResponse>
  ) =>
    updateApplicationFee(
      { company_id: parsedID, params: values },
      {
        onSuccess: () => {
          onSuccessSubmitForm();
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      }
    );

  return (
    <div className={style.applicationFeeView}>
      <PageHeaderWithBackButton
        title={'Fee(s)'}
        subTitle={
          'Please fill in related fee(s) and select at least one payment mode'
        }
      />
      <div className={style.content}>
        <div
          className={style.form}
          style={{ height: tableHeight, width: '70%' }}
        >
          <p className={style.formTitle}>Other Applicable fee</p>
          <div className={style.greyDivider} />

          <Spin spinning={isFetching}>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmitForm}
              validationSchema={formSchema}
              validateOnMount
              validateOnChange
              validateOnSchemaChange
            >
              <ApplicationFeeForm />
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ApplicationFeeView;
