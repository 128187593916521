import { Popover } from 'fave-ui';
import { CheckCircle, Circle, XCircle } from 'phosphor-react';
import {
  BDDealsType,
  BDPaymentDetailsType,
  ECardsActionsType,
  MCContractSubmissionsType,
  ProductType,
} from '../../../../services/MySubmissions';
import style from './style.module.css';

const CompleteStatusIcon = () => (
  <CheckCircle size={13} color="#0e875f" weight="fill" />
);

const IncompleteStatusIcon = () => (
  <XCircle size={13} color="#d4d4d4" weight="fill" />
);

const StatusIcon = ({ title, status }: { title: string; status: boolean }) => (
  <div className={style.statusIcon}>
    <span>{title}</span>
    {status ? <CompleteStatusIcon /> : <IncompleteStatusIcon />}
  </div>
);

const SubmissionStatusWithPopover = ({
  productType,
  data,
}: {
  productType: ProductType;
  data:
    | BDPaymentDetailsType
    | BDDealsType
    | ECardsActionsType
    | MCContractSubmissionsType;
}) => {
  const isFavePay = productType === 'FavePay';
  const isDeals = productType === 'Deal';
  const isECards = productType === 'eCard';
  const isIPP = productType === 'IPP';
  const isFavePayDealsECards = isFavePay || isDeals || isECards;

  const favePayData = data as BDPaymentDetailsType;
  const dealsData = data as BDDealsType;
  const eCardsData = data as ECardsActionsType;
  const ippData = data as MCContractSubmissionsType;
  const favePayDealsOrECardsData = data as
    | BDPaymentDetailsType
    | BDDealsType
    | ECardsActionsType;

  const productStatus = {
    MRPDeals: isDeals && !!dealsData.cp_action_time,
    ComplianceFavePay: isFavePay && !!favePayData.quality_assurance_action_time,
    ComplianceEcard: isECards && !!eCardsData.compliance_action_time,
    ComplianceDeals: isDeals && !!dealsData.qa_action_time,
    ComplianceIPP:
      isIPP && !!ippData.mc_company_ipp_setting.quality_assurance_action_time,
    ContentFavePay: isFavePay && !!favePayData.partner_support_action_time,
    ContentECards: isECards && !!eCardsData.editorial_action_time,
    ContentDeals:
      isDeals &&
      !!dealsData.ps_action_time &&
      !!dealsData.editorial_action_time,
    ContentIPP:
      isIPP && !!ippData.mc_company_ipp_setting.partner_support_action_time,
    DesignerFavePayDealsECards:
      isFavePayDealsECards && !!favePayDealsOrECardsData.designer_action_time,
    DesignerIPP: isIPP && !!ippData.mc_company_ipp_setting.designer_action_time,
  };

  const submissionStatuses = [
    {
      key: '1',
      title: 'MRP',
      status: productStatus.MRPDeals,
      show: isDeals,
    },
    {
      key: '2',
      title: 'Compliance',
      status:
        productStatus.ComplianceFavePay ||
        productStatus.ComplianceEcard ||
        productStatus.ComplianceDeals ||
        productStatus.ComplianceIPP,
      show: true,
    },
    {
      key: '3',
      title: 'Content',
      status:
        productStatus.ContentFavePay ||
        productStatus.ContentECards ||
        productStatus.ContentDeals ||
        productStatus.ContentIPP,
      show: true,
    },
    {
      key: '4',
      title: 'Designers',
      status:
        productStatus.DesignerIPP || productStatus.DesignerFavePayDealsECards,
      show: true,
    },
  ];

  return (
    <>
      <div className={style.submissionStatus}>
        <Popover
          title="Contract"
          content={<StatusIcon title={'Signed'} status={true} />}
          trigger={'hover'}
          placement={'bottom'}
        >
          <Circle
            size={15}
            color="#6DC06D"
            weight="fill"
            className={style.circleIcon}
          />
        </Popover>
        <div className={style.dashedLine} />
        <Popover
          title="Pending Operations"
          content={submissionStatuses.map(
            ({ show, key, ...restItem }) =>
              show && <StatusIcon {...restItem} key={key} />
          )}
          trigger={'hover'}
          placement={'bottom'}
        >
          <Circle
            size={15}
            color="#FFC94C"
            weight="fill"
            className={style.circleIcon}
          />
        </Popover>
      </div>
    </>
  );
};

export default SubmissionStatusWithPopover;
