import { Popover } from 'fave-ui';
import { CaretDown } from 'phosphor-react';
import React from 'react';
import style from './style.module.css';

type DataType = {
  id: number;
  name: string | JSX.Element;
};

type TextWithPopoverProps = {
  text: string;
  popoverTitle: string;
  data?: DataType[];
};

export const TextWithPopover: React.FC<TextWithPopoverProps> = ({
  text,
  popoverTitle,
  data,
}) => {
  const content = (
    <div>
      {data &&
        data.map((item) => {
          return <p key={item.id}>{item.name}</p>;
        })}
    </div>
  );
  return (
    <div className={style.popoverContainer}>
      <span>{text}</span>
      <Popover
        title={popoverTitle}
        content={content}
        trigger={'hover'}
        placement={'bottom'}
      >
        <CaretDown size={16} className={style.rotateCaret} />
      </Popover>
    </div>
  );
};
