import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  ApprovalDocumentsType,
  EnabledStatusType,
  McApprovalDocumentsType,
} from '../../types/dataTypes';
import { CompanyIDPayloadType, OutletIDPayloadType } from '../../types/configTypes';
import http, { createFetchAPI, handleApiError } from '../http';

const paymentModeEndpoint = 'mc_company_payment_modes';
const applicationFeeEndpoint = 'application_fees';
const fetchCompanyPaymentModeQueryKey = 'fetchCompanyPaymentModeList';
const fetchFavePaymentModeQueryKey = 'fetchFavePaymentModeList';
const paymentModeForFaveEndpoint = 'mc_outlet_payment_modes';

const endpoint = (id: number, endpointURL: string) =>
  `v1/fave_save_merchants/${id}/${endpointURL}`;

export type PaymentModesType = {
  id?: number;
  group: string;
  variant: string;
};

export type PaymentModeType = PaymentModesType & {
  status: boolean | EnabledStatusType;
  rate: number | undefined;
  is_fpx?: boolean;
  fixed_amount?: number;
  payment_mode: PaymentModesType;
  [dynamicKey: string]: unknown;
};

export type PaymentModeObjectType = {
  [key: string]: PaymentModeType[];
};

export type ListOutletIds = { outlets?: string[] };

export type PaymentModeFormType = PaymentModeObjectType & ApprovalDocumentsType & ListOutletIds;

type PaymentModeListResponse = {
  payment_modes: PaymentModesType[];
};

export type PaymentModeListResponseError = { error: string };

export type McCompanyPaymentModesType = {
  id: number;
  rate: number;
  payment_mode: PaymentModesType;
  status: boolean;
  fixed_amount?: number;
};

type FetchCompanyPaymentModeResponse = {
  mc_company_payment_modes: McCompanyPaymentModesType[];
  approval_documents: McApprovalDocumentsType[];
};

type FetchFavePaymentModeResponse = {
  mc_outlet_payment_modes: McCompanyPaymentModesType[];
  approval_documents: McApprovalDocumentsType[];
}

type FetchOutletPaymentModeResponse = {
  id: number,
  name: string,
  has_draft_payment_mode: boolean,
  has_existing_payment_mode: boolean,
}[];

export type CreateEditPaymentModePayload = {
  company_id: number;
  params: FormData;
};

export type FetchCompanyApplicationFeeResponse = {
  annual_license_fee: number;
  annual_license_fee_remarks: string;
  one_time_setup_fee: number;
  one_time_setup_fee_remarks: string;
  annual_maintenance_fee: number;
  annual_maintenance_fee_remarks: string;
  any_other_fee: number;
  any_other_fee_remarks: string;
};

export type AdminToolCompanyPaymentModeResponse = {
  company_payment_modes: McCompanyPaymentModesType[];
};

export type AdminToolOutletPaymentModeResponse = {
  outlet_payment_modes: McCompanyPaymentModesType[];
};

export type ChangeDataOutletPaymentModeResponse = {
  id?: string;
  created_at: string;
  user: string;
  field: string;
  old_value: null | number | string;
  new_value: null | number | string;
}

export type AdminToolOutletPaymentModeChangeResponse = {
  group_name: string;
  variant_name: string;
  changes: ChangeDataOutletPaymentModeResponse[]
}

export type AdminToolCompanyPaymentModePayload = {
  company_id: number;
  params: FormData;
};

export type AdminToolOutletPaymentModeParam = {
  id?: number,
  payment_mode_id?: number,
  rate?: number,
  fixed_amount?: number,
  status?: EnabledStatusType,
  is_fpx?: boolean,
}

export type AdminToolOutletPaymentModePayload = {
  outlet_id: number;
  params: {
    outlet_payment_modes: AdminToolOutletPaymentModeParam[];
  };
};

export type UpdateAdminToolCompanyPaymentModePayload = PaymentModeFormType & {
  company_payment_modes: McCompanyPaymentModesType[];
  application_fee: {
    annual_license_fee_activation_date: Date;
    one_time_setup_fee_activation_date: Date;
    annual_maintenance_fee_activation_date: Date;
    any_other_fee_activation_date: Date;
  };
};

const fetchPaymentModeList = createFetchAPI<{}, PaymentModeListResponse>(
  'v1/payment_modes'
);

export const useFetchPaymentModeList = () =>
  useQuery<PaymentModeListResponse>(
    ['fetchPaymentModeList'],
    fetchPaymentModeList
  );

const createPaymentMode = ({
  company_id,
  params,
}: CreateEditPaymentModePayload) =>
  http.post(endpoint(company_id, paymentModeEndpoint), params);

const createPaymentModeForFave = ({
  company_id,
  params,
}: CreateEditPaymentModePayload) =>
  http.post(endpoint(company_id, paymentModeForFaveEndpoint), params);

const editPaymentMode = ({
  company_id,
  params,
}: CreateEditPaymentModePayload) =>
  http.put(endpoint(company_id, paymentModeEndpoint), params);

const editPaymentModeForFave = ({
  company_id,
  params,
}: CreateEditPaymentModePayload) =>
  http.put(endpoint(company_id, paymentModeForFaveEndpoint), params);

export const useCreateEditPaymentMode = ({
  isUpdate,
  isFastpay,
}: {
  isUpdate?: boolean;
  isFastpay?: boolean;
}) => {
  if (isFastpay) {
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    return isUpdate
      ? useMutation(editPaymentMode, { onError: handleApiError })
      : useMutation(createPaymentMode, { onError: handleApiError });
  } else {
    return isUpdate
      ? useMutation(editPaymentModeForFave, { onError: handleApiError })
      : useMutation(createPaymentModeForFave, { onError: handleApiError });
  }
};

const fetchCompanyPaymentModeList = createFetchAPI<
  { company_id: number; filter?: string },
  FetchCompanyPaymentModeResponse
>();

const fetchFavePaymentModeList = createFetchAPI<
  { company_id: number; filter?: string },
  FetchFavePaymentModeResponse
>();

const fetchOutletPaymentMode = createFetchAPI<
  { company_id: number; filter?: string },
  FetchOutletPaymentModeResponse
>();

export const useFetchCompanyPaymentModeList = ({
  company_id,
  filter,
  enable,
}: {
  company_id: number;
  filter?: string;
  enable?: boolean;
}) =>
  useQuery<FetchCompanyPaymentModeResponse>(
    [
      fetchCompanyPaymentModeQueryKey,
      { url: endpoint(company_id, paymentModeEndpoint), filter },
    ],
    fetchCompanyPaymentModeList,
    { enabled: enable },
  );

export const useFetchFavePaymentModeList = ({
  company_id,
  filter,
  enable,
}: {
  company_id: number;
  filter?: string;
  enable: boolean;
}) =>
  useQuery<FetchFavePaymentModeResponse>(
    [
      fetchFavePaymentModeQueryKey,
      { url: endpoint(company_id, paymentModeForFaveEndpoint), filter },
    ],
    fetchFavePaymentModeList,
    { enabled: enable}
  );

export const useFetchOutletsPaymentMode = ({
  company_id,
  filter,
  isFastpay,
}: {
  company_id: number;
  filter?: string;
  isFastpay?: boolean;
}) =>
  useQuery<FetchOutletPaymentModeResponse>(
    [
      fetchCompanyPaymentModeQueryKey,
      { url: endpoint(company_id, `${paymentModeForFaveEndpoint}/outlets?${filter}`) },
    ],
    fetchOutletPaymentMode,
    { retry: false, enabled: !isFastpay }
  );

const deletePaymentModeDocument = ({
  company_id,
  approval_document_id,
}: {
  company_id: number;
  approval_document_id: number;
}) =>
  http.delete(
    `v1/fave_save_merchants/${company_id}/approval_documents/${approval_document_id}`
  );

export const useDeletePaymentModeDocument = () => {
  const queryClient = useQueryClient();
  //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
  return useMutation(deletePaymentModeDocument, {
    onError: handleApiError,
    onSuccess: () =>
      queryClient.invalidateQueries(fetchCompanyPaymentModeQueryKey),
  });
};

const fetchCompanyApplicationFee = createFetchAPI<
  { company_id: number },
  FetchCompanyApplicationFeeResponse
>(undefined, {
  onParseResponse: (response) => response,
});

export const useFetchCompanyApplicationFee = ({
  company_id,
  isFastpay = true,
}: CompanyIDPayloadType) =>
  useQuery(
    [
      'fetchCompanyApplicationFee',
      { url: `/v1/companies/${company_id}/${applicationFeeEndpoint}` },
    ],
    fetchCompanyApplicationFee, { enabled: isFastpay }
  );

const updateApplicationFee = ({
  company_id,
  params,
}: CompanyIDPayloadType & {
  params: FetchCompanyApplicationFeeResponse;
}) => http.put(`v1/fave_save_merchants/${company_id}/application_fees`, params);

export const useUpdateApplicationFee = () =>
  useMutation(updateApplicationFee, {
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    onError: handleApiError,
  });

const updatePaymentModeAndApplicationFee = ({
  company_id,
  params,
}: CompanyIDPayloadType & { params: any }) =>
  http.put(
    `v1/companies/${company_id}/payment_modes_and_application_fee`,
    params
  );

export const useUpdatePaymentModeAndApplicationFee = () =>
  useMutation(updatePaymentModeAndApplicationFee, {
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    onError: handleApiError,
  });

const updatePaymentModeForAdmin = ({
  outlet_id,
  params,
}: AdminToolOutletPaymentModePayload) =>
  http.post(`v1/outlet_payment_modes?outlet_id=${outlet_id}`, params);

export const useUpdatePaymentModeForAdmin = () =>
  useMutation(updatePaymentModeForAdmin, {
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    onError: handleApiError,
  });

const fetchAdminToolCompanyPaymentMode = createFetchAPI<
  CompanyIDPayloadType,
  AdminToolCompanyPaymentModeResponse
>();

export const useFetchAdminToolCompanyPaymentMode = ({
  company_id,
  isFastpay = true,
}: CompanyIDPayloadType) =>
  useQuery(
    [
      'fetchAdminToolCompanyPaymentMode',
      { url: `v1/companies/${company_id}/company_payment_modes` },
    ],
    fetchAdminToolCompanyPaymentMode, { enabled: isFastpay },
  );

const fetchAdminToolOutletPaymentMode = createFetchAPI<
  OutletIDPayloadType,
  AdminToolOutletPaymentModeResponse
>();

const fetchAdminToolOutletPaymentModeChange = createFetchAPI<
  OutletIDPayloadType,
  AdminToolOutletPaymentModeChangeResponse[]
>();

export const useFetchAdminToolOutletPaymentMode = ({
  outlet_id,
  isFastpay = true,
}: OutletIDPayloadType) =>
  useQuery(
    [
      'fetchAdminToolOutletPaymentMode',
      { url: `v1/outlet_payment_modes`, outlet_id: outlet_id },
    ],
    fetchAdminToolOutletPaymentMode, { enabled: !isFastpay },
  );

export const useFetchAdminToolOutletPaymentModeChanges = ({
  outlet_id,
  isFastpay = true,
}: OutletIDPayloadType) =>
  useQuery(
    [
      'fetchAdminToolOutletPaymentModeChanges',
      { url: `v1/outlet_payment_modes/changes`, outlet_id: outlet_id },
    ],
    fetchAdminToolOutletPaymentModeChange, { enabled: !isFastpay },
  );
