import React, { useCallback, useRef, useState } from 'react';
import { FormikContextType } from 'formik';
import { useLocation } from 'react-router';
import { PageHeaderWithBackButton } from '../../components/common';
import { Button, Drawer, Message, TabPane, Tabs } from 'fave-ui';
import MakerCheckerRequest from './MakerCheckerRequest';
import MakerCheckerTableView from './MakerCheckerTable';
import {
  Request,
  RequestQueryParams,
  useApproveRequestMaker,
  useCancelRequestMaker,
  useFetchRequestPageForChecker,
  useRejectRequestMaker,
} from '../../services/MakerChecker/useMakerChecker';
import { Form } from '../../components/common/form';
import { initiallForm } from './helper/formSchema';
import cookieSet from '../../helpers/cookieSet';
import style from './style.module.css';
import { MKTab, MakerCheckerModal } from './helper/enum';
import { useUserContext } from '../../contexts/UserContext';
import ApproveDeleteFormModalType from './modals/ApproveDeleteFormModalType';
import RejectFormModal from './modals/RejectFormModal';
import { initialFilterObject, initialInfosecFilterObject } from './helper';
import {
  GroupQueryParams,
  useFetchCheckerGroup,
} from '../../services/MakerChecker/useMakerCheckerGroup';
import GroupTableView from './MakerCheckerGroup';

type LocationState = {
  activeTab: MKTab.Infosec_groups;
};

const MakerCheckerView: React.FC = () => {
  const { getPageSettings, user } = useUserContext();
  const locationState = useLocation().state as unknown as null | LocationState;

  const isChecker = !!getPageSettings('checker');
  const isAdmin = !!getPageSettings('checkmates_admin');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [deleteRequestId, setDeleteRequestId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<MKTab.Checker | MKTab.Maker>(
    isChecker ? MKTab.Checker : MKTab.Maker
  );
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [filterObject, setFilterObject] = useState<RequestQueryParams>({
    ...initialFilterObject,
    request_list_type: isChecker ? MKTab.Checker : isAdmin ? '' : MKTab.Maker,
  });
  const [filterInfosecObject, setFilterInfosecObject] =
    useState<GroupQueryParams>({
      ...initialInfosecFilterObject,
      isAdmin,
    });

  const formRejected = useRef<FormikContextType<{}>>();

  const { mutate: rejectMakerRequest, isLoading: isLoadingReject } =
    useRejectRequestMaker();
  const { mutate: approveMakerRequest, isLoading: isLoadingApprove } =
    useApproveRequestMaker();
  const { mutate: cancelMakerRequest, isLoading: isLoadingCancel } =
    useCancelRequestMaker();

  const {
    data: fetchListRequestForChecker,
    isFetching,
    refetch: refetchListRequest,
  } = useFetchRequestPageForChecker(filterObject);
  const { data: groups } = useFetchCheckerGroup(filterInfosecObject);

  const handleUpdateRequestDetailAfterCallApi = useCallback(
    async ({ status, currentRequest }) => {
      const { data: refetch } = await refetchListRequest();
      const updatedRequest = refetch?.requests.find(
        (req) => req.id === currentRequest?.id
      );
      handleCloseModal();
      if (updatedRequest) {
        setSelectedRequest(updatedRequest);
        return;
      }
      setSelectedRequest({
        ...currentRequest,
        status,
        reviewer: {
          email: user?.email,
          name: user?.name,
          reviewed_at: new Date(),
        },
      } as Request);
      await Message.success({ content: `Successfully ${status} request.` });
    },
    [user]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedAction('');
    setDeleteRequestId('');
  }, []);

  const handleSelectedTab = useCallback((selectedTab) => {
    setActiveTab(selectedTab);
    setFilterObject((filter) => ({
      ...filter,
      page: 1,
      request_list_type: selectedTab,
    }));
  }, []);

  const handleOpenDeleteModal = useCallback((id: string) => {
    setSelectedAction(MakerCheckerModal.Delete);
    setDeleteRequestId(id);
  }, []);

  const handleApproveModal = useCallback(
    () =>
      selectedRequest &&
      approveMakerRequest(
        { request_id: selectedRequest.id },
        {
          onSuccess: async () =>
            await handleUpdateRequestDetailAfterCallApi({
              status: 'approved',
              currentRequest: selectedRequest,
            }),
        }
      ),
    [selectedRequest]
  );

  const handleSubmitRejectForm = useCallback(() => {
    formRejected.current?.submitForm().then((values: unknown) => {
      if (values && selectedRequest)
        rejectMakerRequest(
          {
            request_id: selectedRequest.id,
            params: {
              ...(values as { rejected_reason: string }),
              tenant_code: cookieSet.currentTenant.get(),
            },
          },
          {
            onSuccess: async () =>
              await handleUpdateRequestDetailAfterCallApi({
                status: 'rejected',
                currentRequest: selectedRequest,
              }),
          }
        );
    });
  }, [formRejected, selectedRequest, cookieSet]);

  const handleDeleteModal = useCallback(
    () =>
      cancelMakerRequest(
        { request_id: Number(deleteRequestId) },
        {
          onSuccess: async () =>
            await handleUpdateRequestDetailAfterCallApi({
              status: 'cancelled',
              currentRequest: selectedRequest,
            }),
        }
      ),
    [selectedRequest, deleteRequestId]
  );

  const handleViewRequestDrawer = useCallback((selectedRecord) => {
    setOpenDrawer(true);
    setSelectedRequest(selectedRecord);
  }, []);

  const checkerTabs = isAdmin
    ? [
        {
          tab: 'Submissions',
          key: MKTab.Infosec,
          children: (
            <MakerCheckerTableView
              onDelete={handleOpenDeleteModal}
              onViewRequest={handleViewRequestDrawer}
              requestPageProps={fetchListRequestForChecker}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              isLoading={isFetching}
              activeTab={activeTab}
            />
          ),
        },
        {
          tab: 'Groups',
          key: MKTab.Infosec_groups,
          children: (
            <GroupTableView
              filterObject={filterInfosecObject}
              isLoading={false}
              setFilterObject={setFilterInfosecObject}
              groups={groups}
            />
          ),
        },
      ]
    : [
        {
          tab: 'My Action Needed',
          key: MKTab.Checker,
          children: (
            <MakerCheckerTableView
              onDelete={handleOpenDeleteModal}
              onViewRequest={handleViewRequestDrawer}
              requestPageProps={fetchListRequestForChecker}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              isLoading={isFetching}
              activeTab={activeTab}
            />
          ),
        },
        {
          tab: 'My Submission',
          key: MKTab.Maker,
          children: (
            <MakerCheckerTableView
              onDelete={handleOpenDeleteModal}
              onViewRequest={handleViewRequestDrawer}
              requestPageProps={fetchListRequestForChecker}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              isLoading={isFetching}
              activeTab={activeTab}
            />
          ),
        },
      ];

  return (
    <div>
      <PageHeaderWithBackButton
        title={'Maker Checker Dashboard'}
        subTitle={
          'Accuracy, reliability, and quality control enhancement center'
        }
      />
      <Form initialValues={initiallForm}>
        <Tabs
          defaultActiveKey={
            isAdmin && locationState ? locationState.activeTab : MKTab.Checker
          }
          className={`${!isChecker && !isAdmin && style.tabPane}`}
          onChange={handleSelectedTab}
          destroyInactiveTabPane
        >
          {checkerTabs.map((item) => (
            <TabPane tab={item.tab} key={item.key}>
              {item.children}
            </TabPane>
          ))}
        </Tabs>
      </Form>
      {selectedRequest && (
        <Drawer
          visible={openDrawer}
          title="Request Detail"
          placement="right"
          size="default"
          className="maker-checker-drawer"
          extra={
            <Button
              type="text"
              onClick={() => {
                setOpenDrawer(false);
                setSelectedRequest(null);
              }}
            >
              Close
            </Button>
          }
          footer={
            activeTab === MKTab.Checker &&
            selectedRequest?.status === 'pending_review' && (
              <div className="flex justify-end">
                <div className={style.buttonSecondary}>
                  <Button
                    type="text"
                    onClick={() => setSelectedAction(MakerCheckerModal.Reject)}
                  >
                    Reject
                  </Button>
                </div>
                <Button
                  type="primary"
                  onClick={() => setSelectedAction(MakerCheckerModal.Approve)}
                >
                  Approve
                </Button>
              </div>
            )
          }
        >
          <MakerCheckerRequest request={selectedRequest} />
        </Drawer>
      )}
      <RejectFormModal
        formRejected={formRejected}
        handleSubmitForm={handleSubmitRejectForm}
        visible={selectedAction === MakerCheckerModal.Reject}
        confirmLoading={isLoadingReject}
        onCancel={handleCloseModal}
      />
      <ApproveDeleteFormModalType
        handleOkAction={
          selectedAction === MakerCheckerModal.Approve
            ? handleApproveModal
            : handleDeleteModal
        }
        visible={
          selectedAction === MakerCheckerModal.Approve ||
          selectedAction === MakerCheckerModal.Delete
        }
        confirmLoading={isLoadingApprove || isLoadingCancel}
        onCancel={handleCloseModal}
        requestId={String(selectedRequest?.id || deleteRequestId)}
        selectedAction={selectedAction}
      />
    </div>
  );
};

export default MakerCheckerView;
