export enum TagColor {
  pending_review = 'Pending',
  rejected = 'Rejected',
  cancelled = 'Cancelled',
  approved = 'Processing',
  completed = 'Completed',
}

export enum MKTab {
  Checker = 'my_action_needed',
  Maker = 'my_submission',
  Infosec = '',
  Infosec_groups = 'groups',
}

export enum FieldName {
  Member = 'members',
  Checker = 'checkers',
}

export enum MakerCheckerModal {
  Approve = 'Approve Modal',
  Reject = 'Reject Modal',
  Delete = 'Delete Modal',
}
