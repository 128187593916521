import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { StatusType } from '../../types/dataTypes';
import http, { createFetchAPI, handleApiError } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';
import { campaignSummaryCacheKey } from './useCampaignSummary';
import { labelAttrCacheKeys } from './useLabelAttributes';

const endpoint = '/v1/product_campaigns';
export const campaignListingCacheKey = 'fetchCampaignListing';

type CampaignListingResponseType = {
  product_campaigns: CampaignListingItem[];
  count: number;
};

export type UpdateOrCreateCampaignListingError = { error: string };

export type CampaignListingItem = {
  id: number;
  campaign_name: string;
  internal_reporting: string;
  deal_ribbon: string;
  status: StatusType;
  start_date: string;
  end_date: string;
};

export type CampaignDataItem = {
  id: number;
  name: string;
  created_at: string;
};

const fetchCampaignListing = createFetchAPI<
  TableConfigType,
  CampaignListingResponseType
>(endpoint);

const fetchCampaignListingData = createFetchAPI<
  TableConfigType,
  CampaignDataItem
>();

export const useFetchCampaignListing = (props: TableConfigType) =>
  useQuery<CampaignListingResponseType>(
    [campaignListingCacheKey, { ...props }],
    fetchCampaignListing,
    { staleTime: 300_000 } // 5min
  );

export const useFetchCampaignListingData = ({ id }: { id?: number | string }) =>
  useQuery<CampaignDataItem>(
    ['fetchCampaignListingData', { url: `${endpoint}/${id}` }],
    fetchCampaignListingData,
    { enabled: !!id }
  );

type AddCampaignPayload = {
  product_campaign: {
    id?: number;
    name: string;
    deal_ribbon: string;
    internal_reporting: string;
    start_at: string;
    end_at: string;
  };
};

export const addNewCampaign = (payload: AddCampaignPayload) =>
  http.post<AddCampaignPayload>(endpoint, payload);

export function useAddCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddCampaignPayload) => addNewCampaign(payload),
    {
      onSuccess: async () => {
        // We need to either invalidate the cache for CampaignSummary and CampaignListing
        // or optimistically update the cached data.
        queryClient.invalidateQueries(campaignSummaryCacheKey);
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(labelAttrCacheKeys.dealRibbon);
        queryClient.invalidateQueries(labelAttrCacheKeys.internalReporting);
      },
      //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
      onError: handleApiError,
    }
  );
}

export const editCampaign = (payload: AddCampaignPayload) =>
  http.put<AddCampaignPayload>(
    `${endpoint}/${payload.product_campaign.id}`,
    payload
  );

export function useEditCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddCampaignPayload) => editCampaign(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignSummaryCacheKey);
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(labelAttrCacheKeys.dealRibbon);
        queryClient.invalidateQueries(labelAttrCacheKeys.internalReporting);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
      //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
      onError: handleApiError,
    }
  );
}

export const deleteCampaign = (id: string | number) =>
  http.delete<CampaignListingItem>(`${endpoint}/${id}`);

export function useDeleteCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(async (id: string | number) => deleteCampaign(id), {
    onSuccess: async () => {
      queryClient.invalidateQueries(campaignSummaryCacheKey);
      queryClient.invalidateQueries(campaignListingCacheKey);
      queryClient.invalidateQueries(labelAttrCacheKeys.dealRibbon);
      queryClient.invalidateQueries(labelAttrCacheKeys.internalReporting);
      queryClient.invalidateQueries(changeHistoryCacheKey, {
        refetchInactive: true,
      });
    },
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    onError: handleApiError,
  });
}
