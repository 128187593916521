import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { QueryFunctionContext, QueryKey } from 'react-query';

import cookieSet from '../helpers/cookieSet';
import { SortOrderEnum } from '../helpers/TypeConstant';
import { isProduction, isLocalhost, localhostEndpoint } from '../helpers/env';
import { Message } from 'fave-ui';
import { createSearchParams } from 'react-router-dom';

const stagingName = cookieSet.currentStagingName.get().toUpperCase();

// for API base URLs
export const baseURL = (
  isProduction
    ? process.env.REACT_APP_API
    : isLocalhost
    ? localhostEndpoint
    : process.env[`REACT_APP_${stagingName}_STAGING_API`]
) as string;

//for redirecting to old tool
const redirectURL = isProduction ? 'https://access.myfave.com/' : baseURL;

export const getToolRedirectURL = (path: string, queryStrings?: string) => {
  const queries = createSearchParams(queryStrings);
  queries.append('tenant_code', cookieSet.currentTenant.get());

  const newURL = `${
    redirectURL.match(/^(https|http)?:\/\/[^#?/]+/)![0]
  }${path}?${queries}`;

  return newURL;
};

export const toolRedirect = (
  path: string,
  queryStrings?: string,
  openNewTab = false
) => {
  const newURL = getToolRedirectURL(path, queryStrings);

  if (openNewTab) window.open(newURL, '_blank');
  else window.location.assign(newURL);
};

// any used here by design. so it is needed
const requestInterceptorHandler = {
  onFulfilled: (cfg: AxiosRequestConfig<any>) => {
    if (!cfg.params) cfg.params = {};

    const currentTenant = cookieSet.currentTenant.get();
    const authCookie = cookieSet.auth.get();

    if (cfg.method === 'get') {
      if (cfg.params.sort_order) {
        const { sort_order } = cfg.params;

        cfg.params.sort_order =
          sort_order === 'ascend' || sort_order === 'descend'
            ? SortOrderEnum[sort_order as keyof typeof SortOrderEnum]
            : undefined;
      }
    } else {
      if (cfg.data instanceof FormData)
        cfg.data.append('tenant_code', currentTenant);
      else
        cfg.data = {
          ...cfg.data,
          tenant_code: currentTenant,
        };
    }

    if (authCookie)
      cfg.headers = {
        Authorization: `Bearer ${authCookie.jwt}`,
      };

    cfg.baseURL = baseURL;

    return cfg;
  },
  onRejected: (error: any) => Promise.reject(error),
};

const responseInterceptorHandler = {
  onFulfilled: (response: AxiosResponse<any, any>) => Promise.resolve(response),
  onRejected: (error: any) =>
    new Promise((_, reject) => {
      reject(error);
      if (cookieSet.auth.get() && error.response.data.code === 401) {
        cookieSet.auth.remove();
        window.location.reload();
      }
    }),
};
//

axios.interceptors.request.use(
  requestInterceptorHandler.onFulfilled,
  responseInterceptorHandler.onRejected
);

axios.interceptors.response.use(
  responseInterceptorHandler.onFulfilled,
  responseInterceptorHandler.onRejected
);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export const defaultFetchSettings = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
};

type FetchOptions<ParamsType, ReturnType> = {
  onParseResponse?: (
    response: ReturnType,
    params: ParamsType,
    pageParam: number
  ) => ReturnType;
};

export const createFetchAPI =
  <ParamsType, ReturnType>(
    url?: string,
    options?: FetchOptions<ParamsType, ReturnType>
  ) =>
  ({ pageParam = 1, queryKey }: QueryFunctionContext<QueryKey, number>) => {
    const [, params] = queryKey as [string, ParamsType & { url?: string }];

    const { url: paramsURL, ...restParams } = params || {};

    const currentTenant = cookieSet.currentTenant.get();

    return new Promise<ReturnType>((resolve, reject) => {
      http
        .get<ReturnType>(url || paramsURL || '', {
          params: {
            page: pageParam,
            ...restParams,
            tenant_code: currentTenant,
          },
        })
        .then(({ data }) => {
          if (options?.onParseResponse)
            resolve(options.onParseResponse(data, params, pageParam));
          else if (Array.isArray(data)) resolve(data);
          else
            resolve({
              ...data,
              page: pageParam,
            });
        })
        .catch((error) => reject(error));
    });
  };

export default http;

export const handleApiError = (err: AxiosError<{ error: string, message?: string }>) =>
  Message.error({
    content: (err.response?.data?.message || err.response?.data?.error) ?? 'Please try again later',
  }) as unknown as void;
