import style from './style.module.css';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useParams } from 'react-router';
import { Card, Col, Statistic } from 'antd';
import { Row } from 'fave-ui';
import ChangeHistoryTable from '../../../../components/ChangeHistoryTable';
import { useFetchCampaignListingData } from '../../../../services/FaveArcade/useFetchCampaignListing';
import { capitalizeFirstLetterEachWord, arrayToString } from '../../../../utils/utilFunctions';


const TicketChangelogView = () => {
    const { id } = useParams();
    const parsedID = id ? parseInt(id) : undefined;
    const { data: campaignData, isLoading: isCampaignLoading } =
        useFetchCampaignListingData({ id: id });
    const summaryData = campaignData && {
        ...campaignData,
        campaign_type: capitalizeFirstLetterEachWord(campaignData.campaign_type),
        payment_methods: arrayToString(campaignData.payment_method_types, ' ').join('/'),
        product_types: arrayToString(campaignData.product_types, '_').join('/'),
        status: capitalizeFirstLetterEachWord(campaignData.status),
        rules: `Amount ${campaignData.condition_type} ${campaignData.currency ?? '$'} ${campaignData.amount_cents / 100}: Issued Tickets(${campaignData.no_of_ticket})`
    }

    return (
        <>
            <PageHeaderWithBackButton
                title={`Ticket Campaign Changelog`}
            />

            <Card className={style.card}>
                <Row>
                    <h2 className={style.h2}>{'Details Summary'}</h2>
                </Row>
                <Row className={style.summaryWrapper}>
                    <Col>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="ID"
                                value={summaryData?.id}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Campaign Type"
                                value={summaryData?.campaign_type}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Product Type"
                                value={summaryData?.product_types}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Payment Methods"
                                value={summaryData?.payment_methods}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Rules"
                                value={summaryData?.rules}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Row className={style.table}>
                {parsedID && (
                    <ChangeHistoryTable
                        item_type={'Arcade::TicketCampaign'}
                        item_id={parsedID}
                    />
                )}
            </Row>
        </>
    );
};

export default TicketChangelogView;
