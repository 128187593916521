import { useCallback, useEffect, useState } from 'react';

const useCountDown = (seconds: number) => {
  const [secondsLeft, setSecondsLeft] = useState<number>();

  useEffect(() => {
    let timeOut: NodeJS.Timeout | undefined = undefined;

    if (secondsLeft && secondsLeft > 0)
      timeOut = setTimeout(() => setSecondsLeft((s) => (s || 0) - 1), 1000);

    return () => {
      if (timeOut !== undefined) clearTimeout(timeOut);
    };
  }, [secondsLeft]);

  const trigger = useCallback(
    () => setSecondsLeft(seconds),
    [seconds, setSecondsLeft]
  );

  const isRunning = secondsLeft !== undefined && secondsLeft > 0;

  return { secondsLeft: secondsLeft || 0, isRunning, trigger };
};

export default useCountDown;
