import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const PaymentModeTab: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // TODO: Search can be handled here by the individual tabs, 
    // since the search bar is not inside the tabs, 
    // but the searchbar will update the query param query value
    console.log(searchParams.get('query'))
  }, [searchParams]);

  return (
    <>Coming Soon...</>
  );
};
