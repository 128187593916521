import style from './style.module.css';
import { PageHeaderWithSpace } from '../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import {Tabs, TabPane } from 'fave-ui';
import FaveFundedCashback from './FaveCashbackView';
import FLPFundedCashback from './FLPCashbackView';

const cashbackCampaignTabs = [
  {
    key: '1',
    label: `FLP Funded Cashback`,
    children: <FLPFundedCashback/>,
  },
  {
    key: '2',
    label: `Fave Funded Cashback`,
    children: <FaveFundedCashback/>,
  },
]

const CashbackCampaignsView = () => (
    <>
      <PageHeaderWithSpace
        title={'Cashback campaign'}
        subTitle={''}
      />
      <Tabs className={style.tabs}>
        {cashbackCampaignTabs.map((item) => (
          <TabPane {...item} tab={<span >{item.label}</span>}
            key={item.key} />
        ))}
      </Tabs>
    </>
  );

export default CashbackCampaignsView;
