import { setupWorker, RestHandler, MockedRequest, DefaultBodyType } from 'msw';
import { isProduction } from '../helpers/env';

// configure mock response here in the ctx.json
// can mock as many APIs as needed
const handlers: RestHandler<MockedRequest<DefaultBodyType>>[] = [
  // linkOutletsListHandler,
  // availableOutletsHandler,
  //dealInfoHandler,
  //outletSummaryHandler,
  //linkedOutletsListHandler,
  //unlinkOutletsHandler,
  // ippIssuerHandler,
  // ippCompaniesHandler,
  // favepaySubmissionsListingHandler,
  // favepayDetailsHandler,
  // dealsSubmissionsListingHandler,
  // ecardsSubmissionsListingHandler,
  // ippSubmissionsListingHandler,
  // eCardsDetailsHandler,
  // ippDetailsHandler,
  // contractMigrationListingHandler,
];

const setup = () => {
  const worker = setupWorker(...handlers);

  worker.start({
    onUnhandledRequest: 'bypass',
  });
};

if (!isProduction) setup();
