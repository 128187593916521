import React, { useCallback } from 'react';
import {
  ColumnsType,
  FilterValue,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { useNavigate } from 'react-router';
import { Plus } from 'phosphor-react';
import { SearchBarExpandable } from '../../../components/common';
import { Button, Empty, Table } from 'fave-ui';
import {
  calcTableHeight,
  calcTableWidth,
  formatDate,
} from '../../../utils/utilFunctions';
import {
  GroupInfo,
  GroupQueryParams,
  PaginationGroupResponse,
} from '../../../services/MakerChecker/useMakerCheckerGroup';

type GroupTableView = {
  filterObject: GroupQueryParams;
  setFilterObject: React.Dispatch<React.SetStateAction<GroupQueryParams>>;
  isLoading: boolean;
  groups?: PaginationGroupResponse;
};

const tableWidth = calcTableWidth();
const tableHeight = calcTableHeight();

const GroupTableView: React.FC<GroupTableView> = ({
  isLoading,
  filterObject,
  setFilterObject,
  groups
}) => {
  const navigate = useNavigate();

  const handleEditGroup = useCallback((id: string) => {
    navigate(`/admin/maker_checker_dashboard/group/${id}`);
  }, []);

  const dataColumns: ColumnsType<GroupInfo> = [
    {
      title: 'Group ID',
      dataIndex: 'id',
      width: 100,
      render: (group_id: number) => <>{group_id}</>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at: string) => (
        <>{formatDate(created_at, 'dd MMMM yyyy')}</>
      ),
    },
    {
      title: 'Groups',
      dataIndex: 'name',
      width: 160,
      ellipsis: true,
      render: (name: string) => <>{name}</>,
    },
    {
      title: 'Members',
      dataIndex: 'members',
      width: 170,
      ellipsis: true,
      render: (members: string) => <>{members}</>,
    },
    {
      title: 'Checker',
      dataIndex: 'checkers',
      width: 170,
      ellipsis: true,
      render: (checker: string) => <>{checker}</>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id) => (
        <div
          className="text-favepink font-semibold cursor-pointer"
          onClick={() => handleEditGroup(id)}
        >
          Edit
        </div>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    if (value)
      setFilterObject((filterObj) => ({
        ...filterObj,
        name: value,
      }));
  };

  const handleChangeTable = useCallback(
    (
      pagination: TablePaginationConfig,
      filter: Record<string, FilterValue | null>
    ) => {
      filter;
      setFilterObject((oldFilter) => ({
        ...oldFilter,
        page: pagination.current || 1,
      }));
    },
    []
  );

  return (
    <>
      <div className="flex justify-end mt-10">
        <SearchBarExpandable onSearch={handleSearch} />
        <Button
          size={'large'}
          type={'primary'}
          shape={'circle'}
          icon={<Plus />}
          onClick={() => handleEditGroup('')}
        />
      </div>
      <Table
        size="large"
        loading={isLoading}
        dataSource={groups?.groups || []}
        columns={dataColumns}
        className="w-full mt-3 mb-4"
        scroll={{
          x: tableWidth,
        }}
        pagination={{
          total: groups?.total_count,
          position: ['bottomCenter'],
          pageSize: filterObject.limit,
          current: filterObject.page,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: !isLoading && (
            <Empty
              emptyType="cant-find-anything"
              style={{ height: tableHeight }}
            />
          ),
        }}
      />
    </>
  );
};

export default GroupTableView;
