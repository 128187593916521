import { ModalFunctions, UploadFile } from 'fave-ui';
import { Paperclip } from 'phosphor-react';
import style from './style.module.css';

export const showRemoveFileModal = <T extends {}>({
  file,
  modalTitle = 'Remove Item?',
  modalContent = 'Are you sure you want to remove item?',
  onOk,
}: {
  file: UploadFile<T>;
  modalTitle?: string;
  modalContent?: string;
  onOk?: (
    resolve: (value: void | PromiseLike<void>) => void,
    reject: () => void
  ) => void;
}): Promise<void> =>
  new Promise((resolve, reject) =>
    ModalFunctions.confirm({
      centered: true,
      title: modalTitle,
      content: (
        <div className={style.onRemoveFileModal}>
          <p>{modalContent}</p>
          <div className={style.fileName}>
            <Paperclip size={16} />
            <span>{file.name}</span>
          </div>
        </div>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: onOk ? () => onOk(resolve, reject) : () => resolve(),
      onCancel: () => reject(),
    })
  );

export const showUnselectPaymentModeModal = ({
  modalTitle = 'Disable payment option?',
  modalContent = 'Are you sure you want to disable? It will be disabled on FastPay website as well.',
  onOk,
}: {
  modalTitle?: string;
  modalContent?: string;
  onOk?: () => void;
}): Promise<void> =>
  new Promise((resolve, reject) =>
    ModalFunctions.confirm({
      centered: true,
      title: modalTitle,
      content: (
        <div className={style.onRemoveFileModal}>
          <p>{modalContent}</p>
        </div>
      ),
      okText: 'Disable',
      cancelText: 'Cancel',
      onOk: onOk ? () => resolve(onOk()) : () => resolve(),
      onCancel: () => reject(),
    })
  );
