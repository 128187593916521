import { Descriptions, DescriptionsItem, DescriptionsProps } from 'fave-ui';
import React from 'react';
import { StatusType } from '../../types/dataTypes';
import { StatusTag } from '../common/StatusTag';
import style from './style.module.css';

type ProductSummaryCardProps = {
  status?: StatusType;
  descriptionItems: any[];
} & DescriptionsProps;

export const ProductSummaryCard: React.FC<ProductSummaryCardProps> = ({
  status,
  title,
  descriptionItems,
  column = 4,
}) => {
  return (
    <Descriptions
      layout="vertical"
      colon={false}
      column={column}
      className={style.commonDescription}
      title={
        <div className={style.descriptionsTitle}>
          <span className={style.title}>{title}</span>
          {status && (
            <div className={style.statusTag}>
              <StatusTag status={status} />
            </div>
          )}
        </div>
      }
    >
      {descriptionItems.map((item) => (
        <DescriptionsItem {...item} />
      ))}
    </Descriptions>
  );
};
