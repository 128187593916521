import { useState } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col, } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Empty, FilterIcon, Row, Table, Button, Modal, Menu, Dropdown, Notification } from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import { createOnTableChangeHandler, formatFilter } from '../../../utils/utilFunctions';
import { FLPCashbackListingItem, UpdateFLPCashbackStatus, useFetchFLPCashbackListing, useUpdateFLPCashbackMutation } from '../../../services/CashbackCampaign/useFetchFLPCashbackListing';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { Plus, DotsThree } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../../components/common/SearchBarExpandable/SearchBarExpandable';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import { CampaignStatusType, UpdateStatusType } from '../../../types/dataTypes';
import type { AxiosResponse } from 'axios';

const successNotification = (message: string) => {
  Notification.success({
    message: message
  });
};

const FLPFundedCashback = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tableConfig, setTableConfig] = useState<TableSettingsType>(defaultTableConfig);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const [status, setStatus] = useState('');
  const [campaign, setCampaign] = useState<FLPCashbackListingItem>();
  const navigate = useNavigate();

  const { mutateAsync: updateFLPCashbackStatus } = useUpdateFLPCashbackMutation();
  const { data: campaignData, isFetching: campaignFetching } = useFetchFLPCashbackListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
    funder_type: 'Flp::Client'
  });

  const campaignListingData = campaignData?.cashback_campaigns?.map((campaign: FLPCashbackListingItem) => {
    return {
      ...campaign,
      status: capitalizeFirstLetterEachWord(campaign.status) as CampaignStatusType,
      available_budget_amount_cents: campaign.available_budget_amount_cents / 100
    }
  });

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) => formatFilter([{ key: 'status', value: filters.status }])
  );

  const handleUpdateStatus = async (campaign: UpdateFLPCashbackStatus) =>
    await updateFLPCashbackStatus(campaign,
      {
        onSuccess: (res: AxiosResponse) => {
          successNotification(res?.data?.message ?? 'Your action is success!');
          setShowModal(false);
        }
      }
    );

  const campaignListingColumns: ColumnsType<FLPCashbackListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      render: RenderColumn.rowText,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      width: 250,
      render: RenderColumn.rowText,
    },
    {
      title: 'FLP Partner',
      dataIndex: 'funder_name',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Budget remainder',
      dataIndex: 'available_budget_amount_cents',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
        { text: `Archived`, value: 'archived' },
      ],
      width: 120,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'Change Log',
      width: 120,
      render: (row: FLPCashbackListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (row: FLPCashbackListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'edit',
            label: row.status !== 'Archived' ? 'View/Edit' : 'View',
            onClick: () => navigate(`/admin/merchandise_and_campaign/cashback_campaigns/edit_FLP_campaign/${row.id}`),
          }
        ];
        if (row.status !== 'Archived') {
          actionItems.push(
            {
              key: 'editStatus',
              label: row.status === 'Active' ? 'Deactivate' : 'Activate',
              onClick: () => {
                if (row.status === 'Active') {
                  setStatus(row.status_event[0])
                  setShowModal(true);
                  setCampaign(row);
                  setModalContent({ title: 'Deactivate campaign', content: `You are about to deactivate ${row.campaign_name}, proceed?` })
                } else {
                  handleUpdateStatus({ id: row.id, status_event: row.status_event[0] })
                }
              },
            },
          )
        }
        if (row.status === 'Inactive') {
          actionItems.push({
            key: 'archiveStatus',
            label: 'Archive',
            onClick: () => {
              setStatus(row.status_event[1])
              setModalContent({ title: 'Archive campaign', content: 'This action is irreversible. To re-activate this campaign, you will need to create a new campaign.' })
              setShowModal(true);
              setCampaign(row);
            },
          },)
        }
        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={(e) => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Campaign Summary'}</h2>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={(keyword) => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => navigate('/admin/merchandise_and_campaign/cashback_campaigns/new_FLP_campaign')}
                type="primary"
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Table
          size="large"
          rowKey={(row) => row.id}
          loading={campaignFetching}
          columns={campaignListingColumns}
          onChange={tableChangeHandler}
          dataSource={campaignListingData}
          className={style.table}
          pagination={{
            total: campaignData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !campaignFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Modal title={modalContent.title}
        visible={showModal}
        okText="Yes, proceed"
        cancelText="Cancel"
        onOk={() => campaign && handleUpdateStatus({ id: campaign.id, status_event: status as UpdateStatusType })}
        onCancel={() => setShowModal(false)}
      >
        {modalContent.content}
      </Modal>
    </>
  );
};

export default FLPFundedCashback;
