import { useParams } from 'react-router';
import useSetBreadCrumbParams from '../../../../hooks/useSetBreadCrumbParams';
import { useFetchECardsDetails } from '../../../../services/MySubmissions';
import {
  customBooleanValue,
  dateOrDash,
  valueOrDash,
} from '../../../../utils/utilFunctions';
import { PageHeaderWithBackButton } from '../../../../components/common';
import { Spin } from 'antd';
import CardSection from '../../../../components/CardSection';

const ECardSubmissionsDetailsView = () => {
  const { id } = useParams();
  const ecardID = String(id);

  const { data: ecardDetailsData, isFetching } = useFetchECardsDetails({
    id: ecardID,
    dataset: 'e_card_action,account,company',
  });

  const isNotEmpty = ecardDetailsData !== undefined;

  useSetBreadCrumbParams(
    [
      { label: '', key: 'ecards' },
      {
        label: `${ecardDetailsData?.company.name} (eCards Detail)`,
        key: String(id),
      },
      { label: '', key: 'details' },
    ],
    isNotEmpty
  );

  const hasEcardAccountData = !!(
    ecardDetailsData?.account.bank_info.name &&
    ecardDetailsData.account.bank_account_name &&
    ecardDetailsData.account.bank_account_number
  );

  const eCardDescriptionDetails = [
    {
      key: '1',
      label: 'Start Date',
      children: dateOrDash(ecardDetailsData?.start_date),
    },
    {
      key: '2',
      label: 'End Date',
      children: dateOrDash(ecardDetailsData?.end_date),
    },
    {
      key: '3',
      label: 'Validity',
      children: valueOrDash(ecardDetailsData?.validity),
    },
    {
      key: '4',
      label: 'eCard Auto Extension',
      children: customBooleanValue(ecardDetailsData?.auto_extend),
    },
    {
      key: '5',
      label: 'Eligible for New Users?',
      children: customBooleanValue(ecardDetailsData?.bke_locked),
    },
    {
      key: '6',
      label: 'Locked Period (Days)',
      children: valueOrDash(ecardDetailsData?.bke_locked_period),
    },
    {
      key: '7',
      label: 'User Lifetime Purchase Limit',
      children: valueOrDash(ecardDetailsData?.user_purchase_limit),
    },
    {
      key: '8',
      label: 'Sales Cap',
      children: valueOrDash(ecardDetailsData?.max_cap),
    },
  ];

  const pricingAndBankDetails = [
    {
      key: '1',
      label: 'Payable Amount',
      children: valueOrDash(ecardDetailsData?.payable_amount),
    },
    {
      key: '2',
      label: 'Bonus Credit',
      children: valueOrDash(ecardDetailsData?.bonus_credit_amount),
    },
    {
      key: '3',
      label: 'Merchant Takeback (Support Fee)',
      children: valueOrDash(ecardDetailsData?.e_card_action?.support_fee),
    },
    {
      key: '4',
      label: 'Fave Commission %',
      children: valueOrDash(ecardDetailsData?.commission_rate_percent, '%'),
    },
    {
      key: '5',
      label: 'Applicable Tax on Fave Fee %',
      children: valueOrDash(ecardDetailsData?.e_card_action?.fave_fee_tax),
    },
    {
      key: '6',
      label: 'Payout by',
      children: valueOrDash(ecardDetailsData?.payout_by),
    },
    {
      key: '7',
      label: 'Payout to Bank',
      // we dont have utility function to check multiple values before
      // showing '-' if all values are null
      children: hasEcardAccountData
        ? valueOrDash(
            `${ecardDetailsData?.account?.bank_info?.name}, ${
              ecardDetailsData?.account?.bank_account_number
            } ${
              ecardDetailsData?.account?.bank_account_name
                ? `(${ecardDetailsData?.account?.bank_account_name})`
                : ''
            }`
          )
        : '-',
    },
  ];

  const tncAndOthers = [
    {
      key: '1',
      label: 'Fine Print',
      className: 'whitespace-pre-wrap',
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: valueOrDash(ecardDetailsData?.fine_print),
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Other Remarks',
      children: valueOrDash(ecardDetailsData?.e_card_action?.remarks),
    }
  ];

  const eCardDetailSections = [
    {
      key: '1',
      title: 'eCard Description',
      data: eCardDescriptionDetails,
    },
    {
      key: '2',
      title: 'Pricing and Bank Details',
      data: pricingAndBankDetails,
    },
    {
      key: '3',
      title: 'T&C and Others',
      data: tncAndOthers,
    },
  ];

  return (
    <div>
      <PageHeaderWithBackButton
        title={valueOrDash(ecardDetailsData?.company?.name)}
        subTitle={'eCard Detail'}
      />
      <Spin spinning={isFetching} style={{ width: '100%' }}>
        {eCardDetailSections.map(({ key, ...restItems }) => (
          <CardSection key={key} sectionNumber={key} {...restItems} />
        ))}
      </Spin>
    </div>
  );
};

export default ECardSubmissionsDetailsView;
