import React, { useState, useMemo } from 'react';
import Form from '../../components/common/form/Form';
import { PageHeaderWithBackButton } from '../../components/common/PageHeaderWithBackButton';
import style from './style.module.css';
import * as Yup from 'yup';
import {
  useCreateEditPaymentMode,
  PaymentModeFormType,
  PaymentModeType,
  useFetchPaymentModeList,
  useFetchCompanyPaymentModeList,
  CreateEditPaymentModePayload,
  useFetchOutletsPaymentMode,
  useFetchFavePaymentModeList,
} from '../../services/PaymentMode/usePaymentMode';
import { calcTableHeight } from '../../utils/utilFunctions';
import { Message } from 'fave-ui';
import { Spin } from 'antd';
import { useLocation, useParams } from 'react-router';
import { toolRedirect } from '../../services/http';
import PaymentModeCreateForm from './PaymentModeCreateForm';
import {
  approvalDocumentsFieldKey,
  companyPaymentModesFieldKey,
  groupByPaymentMode,
  groupByPaymentModeSchema,
  favePaymentModesFieldKey,
} from './helpers/paymentModeHelpers';
import { FormikHelpers } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { ActionForm, OutletStatusPaymentMode } from './helpers/enums';

const tableHeight = calcTableHeight(225);

const onSuccessSubmitForm = (merchantID: number, isEditMode: boolean) =>
  Message.success({
    content: `Successfully ${isEditMode ? 'edited' : 'created'} Payment Mode.`,
  }).then(() =>
    toolRedirect(`/admin/merchant_connect/merchants/${merchantID}/favepay`)
  );

const onSubmitForm = (
  values: PaymentModeFormType,
  merchantID: number,
  isFastpay: boolean,
  createEditPaymentMode: (
    data: CreateEditPaymentModePayload,
    response: { onSuccess: () => void; onError: () => void }
  ) => void,
  isEditMode: boolean,
  { setSubmitting }: FormikHelpers<PaymentModeFormType>,
  listOutletsDefault: string[],
) => {
  const formData = new FormData();
  if (isFastpay) {
    delete values.outlets;
  }

  Object.keys(values).forEach((value) => {
    if (value === approvalDocumentsFieldKey) {
      values.approval_documents?.forEach(
        (file) =>
          file.originFileObj &&
          formData.append(
            `${approvalDocumentsFieldKey}[]`,
            file.originFileObj as Blob
          )
      );
    } else
      values[value]
        .filter((paymentMode) => paymentMode[`rate${paymentMode.group?.replace(' ', '')}${paymentMode.variant}`]
          && paymentMode[`rate${paymentMode.group?.replace(' ', '')}${paymentMode.variant}`] as number > 0)
        .forEach((item) => {
          if (isFastpay) {
            formData.append(
              `${companyPaymentModesFieldKey}[][status]`,
              item.status ? 'enabled' : 'disabled'
            );
            formData.append(
              `${companyPaymentModesFieldKey}[][rate]`,
              item[`rate${item.group.replace(' ', '')}${item.variant}`] as unknown as string
            );

            if (item.is_fpx) {
              formData.append(
                `${companyPaymentModesFieldKey}[][is_fpx]`,
                item.is_fpx as unknown as string
              );
              formData.append(
                `${companyPaymentModesFieldKey}[][fixed_amount]`,
                item[`fixed_amount${item.group.replace(' ', '')}${item.variant}`] as unknown as string
              );
            } else {
              if (isEditMode) {
                formData.append(
                  `${companyPaymentModesFieldKey}[][id]`,
                  item.id as unknown as string
                );
                formData.append(
                  `${companyPaymentModesFieldKey}[][payment_mode_id]`,
                  item.payment_mode.id as unknown as string
                );
              } else {
                formData.append(
                  `${companyPaymentModesFieldKey}[][payment_mode_id]`,
                  item.id as unknown as string
                );
              }
            }
          } else {
            if (item.status) {
              if (isEditMode) {
                formData.append(
                  `${favePaymentModesFieldKey}[][id]`,
                  item.id as unknown as string
                );
              }
              formData.append(
                `${favePaymentModesFieldKey}[][rate]`,
                item[`rate${item.group.replace(' ', '')}${item.variant}`] as unknown as string
              );
              if (item.is_fpx && item[`fixed_amount${item.group.replace(' ', '')}${item.variant}`]) {
                formData.append(
                  `${favePaymentModesFieldKey}[][is_fpx]`,
                  item.is_fpx as unknown as string
                );
                formData.append(
                  `${favePaymentModesFieldKey}[][fixed_amount]`,
                  item[`fixed_amount${item.group.replace(' ', '')}${item.variant}`] as unknown as string
                );
              } else {
                const paymentModeId = isEditMode
                  ? item.payment_mode?.id
                  : item.id || item.payment_mode?.id;
                formData.append(
                  `${favePaymentModesFieldKey}[][payment_mode_id]`,
                  paymentModeId as unknown as string
                );
              }
            }
          }
        });
  });

  if (!isFastpay && values.outlets?.length) {
    values.outlets?.forEach((outlet) => {
      formData.append('outlet_ids[]', outlet as unknown as string);
    });
    listOutletsDefault?.filter((outlet) => !values.outlets?.includes(outlet)).forEach((outlet) => {
      formData.append('removed_outlet_ids[]', outlet as unknown as string);
    })
  }

  createEditPaymentMode(
    { company_id: merchantID, params: formData },
    {
      onSuccess: () => {
        onSuccessSubmitForm(merchantID, isEditMode);
        setSubmitting(false);
      },
      onError: () => setSubmitting(false),
    }
  );
};

const PaymentModeConfigurationFormView: React.FC = () => {
  const [statusOutletPaymentMode, setStatusOutletPaymentMode] = useState(OutletStatusPaymentMode.New);

  const { data: paymentModeListData, isFetching: isPaymentModeListFetching } =
    useFetchPaymentModeList();
  const { id, mode } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useLocation();
  const params = new URLSearchParams(navigate.search);
  const merchantID = parseInt(id || '');

  const isEditMode = mode === ActionForm.Edit;
  const isCreateMode = mode === ActionForm.Create;
  const isResubmitMode = mode === ActionForm.Resubmit;
  const isResubmit = searchParams.get('resubmit');
  const onboardingStatus = isResubmit ? 'unsigned,cancelled' : 'draft';
  const isFastpay = params.get('merchant_type') === 'fastpay';

  const listOutletsIdEdited = params.get('outlet_ids')?.split(',') || [];

  const {
    data: fetchCompanyPaymentModeListData,
    isFetching: isFetchCompanyModeListDataFetching,
  } = useFetchCompanyPaymentModeList({
    company_id: merchantID,
    filter: `onboarding_status=${onboardingStatus}`,
    enable: isFastpay,
  });

  const {
    data: fetchPaymentModeListDataForFave,
    isFetching: isFetchModeListFaveDataFetching,
  } = useFetchFavePaymentModeList({
    company_id: merchantID,
    enable: !isFastpay && !isResubmitMode,
    filter: 'onboarding_status=draft',
  });

  const { data: fetchListOutletPaymentModeData } = useFetchOutletsPaymentMode({
    company_id: merchantID,
    isFastpay,
    filter: isEditMode ? '&without_payment_mode=true' : '',
  });
  const listOutlets = useMemo(
    () =>
      fetchListOutletPaymentModeData?.map((outlet) => ({
        key: `${outlet.id}`,
        label: outlet.name,
        value: `${outlet.id}`,
        ...outlet,
      })) ?? [],
    [fetchListOutletPaymentModeData]
  );

  const { mutate: createEditPaymentMode } = useCreateEditPaymentMode({
    isUpdate: isEditMode,
    isFastpay: isFastpay,
  });

  const newCompanyPaymentModeListData = isFastpay
    ? fetchCompanyPaymentModeListData?.mc_company_payment_modes.map((item) => {
        return {
          ...item,
          group: item.payment_mode.group,
          variant: item.payment_mode.variant,
        };
      })
    : fetchPaymentModeListDataForFave?.mc_outlet_payment_modes
        .filter(
          (paymentMode, index, listData) =>
            listData.findIndex(
              (data) => data.payment_mode.id === paymentMode.payment_mode.id
            ) === index
        )
        .map((item) => {
          return {
            ...item,
            group: item.payment_mode.group,
            variant: item.payment_mode.variant,
          };
        });

  const hasPaymentMode =
    isEditMode && isFastpay
      ? fetchCompanyPaymentModeListData?.mc_company_payment_modes &&
        fetchCompanyPaymentModeListData?.mc_company_payment_modes.length > 0
      : isCreateMode ? false :
        // For fave mode, list payment mode could be existed while in create mode 
        fetchPaymentModeListDataForFave?.mc_outlet_payment_modes &&
        fetchPaymentModeListDataForFave?.mc_outlet_payment_modes.length > 0;

  const groupedPaymentModes = groupByPaymentMode(
    hasPaymentMode
      ? newCompanyPaymentModeListData
      : (paymentModeListData?.payment_modes as PaymentModeType[]),
    isFastpay
  );

  const initialOutlets = listOutlets.filter((outlet) => listOutletsIdEdited.includes(`${outlet.id}`)).map((outlet) => outlet.value);

  const initialValues = {
    ...groupedPaymentModes,
    approval_documents:
      isEditMode || isResubmitMode
        ? isFastpay
          ? fetchCompanyPaymentModeListData?.approval_documents
          : fetchPaymentModeListDataForFave?.approval_documents
        : undefined,
    outlets: isCreateMode ? [] : initialOutlets,
  } as unknown as PaymentModeFormType;

  const dynamicSchema =
    groupedPaymentModes &&
    groupByPaymentModeSchema(groupedPaymentModes, statusOutletPaymentMode);

  const schema = {
    ...dynamicSchema,
    approval_documents: Yup.mixed().required('Please upload at least 1 file.'),
  };

  const formSchema = useMemo(() => {
    return isFastpay
      ? Yup.object().shape(schema)
      : Yup.object().shape({
          ...schema,
          approval_documents:
          isCreateMode || isResubmitMode
            ? Yup.mixed().optional()
            : Yup.mixed().required('Please upload at least 1 file.'),
          outlets: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select at least 1 outlet')
            .required('Please select at least 1 outlet'),
        });
  }, [schema, isCreateMode, isResubmitMode]);

  return (
    <div className={style.paymentModeView}>
      <PageHeaderWithBackButton
        title={'Payment Mode'}
        subTitle={'Please select at least one payment mode'}
      />
      <div className={style.content}>
        <div className={style.form} style={{ height: tableHeight }}>
          <p className={style.formTitle}>Select Mode</p>

          <Spin
            spinning={
              isEditMode || isResubmitMode
                ? isFetchCompanyModeListDataFetching ||
                  isFetchModeListFaveDataFetching
                : isPaymentModeListFetching
            }
          >
            <Form
              initialValues={initialValues}
              onSubmit={(
                values: PaymentModeFormType,
                helpers: FormikHelpers<PaymentModeFormType>
              ) =>
                onSubmitForm(
                  values,
                  merchantID,
                  isFastpay,
                  createEditPaymentMode,
                  isEditMode,
                  helpers,
                  initialOutlets
                )
              }
              validationSchema={formSchema}
              validateOnMount
              validateOnChange
              validateOnSchemaChange
            >
              <PaymentModeCreateForm
                listOutlets={listOutlets}
                setStatusOutletPaymentMode={setStatusOutletPaymentMode}
              />
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default PaymentModeConfigurationFormView;
