import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { OutletStatusType, CampaignStatusType, UpdateStatusType, ArcadePrizeType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';

const endpoint = '/v1/arcade/prizes';
export const prizesListingCacheKey = 'fetchArcadePrizesListing';

export type PrizesListingResponseType = {
  prizes: PrizeListingItem[];
  count: number;
};

export type PrizeListingItem = {
  id: number;
  campaign_name: string;
  prize_name: string;
  prize_type: ArcadePrizeType;
  cost_of_token: number;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType;
  max_user_limit: number;
  max_campaign_limit: number;
  start_date?: string;
  end_date?: string;
};

export type PrizeOptionsItem = {
  prize_types: ArcadePrizeType[];
  promo_code: PromoCodeValues;
  currency: string;
}

export type PromoCodeValues = {
  promo_code_types: string[];
  promo_code_value_types: string[];
  promo_code_cashback_types: string[];
  product_types: string[];
}

export type PrizeDataItem = {
  id: number;
  campaign_name: string;
  prize_name: string;
  prize_type: string;
  prize_description: string;
  status: OutletStatusType | CampaignStatusType;
  is_highlighted: boolean;
  prize_subtitle?: string;
  cost_of_token?: number;
  max_user_limit?: number;
  max_campaign_limit?: number;
  start_date?: string;
  end_date?: string;
  asset_url?: string;
  product_types?: string[];
  promo_code_type?: string;
  promo_code_value_type?: string;
  amount_value_cents?: number;
  percentage_value?: number;
  max_cap_amount_cents?: number;
  cashback_type?: string;
  min_purchase_amount_cents?: number;
  promo_code_prefix?: string;
  validity_day?: number;
  whitelisted_outlet_ids?: (string | number)[];
  blacklisted_outlet_ids?: (string | number)[];
  whitelisted_deal_ids?: (string | number)[];
  blacklisted_deal_ids?: (string | number)[];
  whitelisted_e_card_ids?: (string | number)[];
  blacklisted_e_card_ids?: (string | number)[];
}

const fetchPrizesListing = createFetchAPI<
  TableConfigType,
  PrizesListingResponseType
>(endpoint);

export const useFetchPrizesListing = (props: TableConfigType) =>
  useQuery<PrizesListingResponseType>(
    [prizesListingCacheKey, { ...props }],
    fetchPrizesListing,
    { staleTime: 300_000 }
  );

const fetchPrizeOptionsData = createFetchAPI<
  TableConfigType, PrizeOptionsItem
>();

export const useFetchPrizeOptions = () =>
  useQuery<PrizeOptionsItem>(
    ['fetchArcadePrizeOptions', { url: `${endpoint}/options` }],
    fetchPrizeOptionsData,
    { staleTime: 300_000 }
  )

const fetchPrizeListingData = createFetchAPI<
  TableConfigType,
  PrizeDataItem
>();

export const useFetchPrizeListingData = ({ id }: { id?: number | string }) =>
  useQuery<PrizeDataItem>(
    ['fetchArcadePrizeListingData', { url: `${endpoint}/${id}` }],
    fetchPrizeListingData,
    { enabled: !!id }
  );

type MutateAddPrizePayload = { formData: FormData };

export const addNewPrize = (formData: FormData, type: string) =>
  http.post<MutateAddPrizePayload>(`${endpoint}/${type}`, formData);

export function useAddPrizeMutation(type: string) {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: MutateAddPrizePayload) => addNewPrize(payload.formData, type),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(prizesListingCacheKey);
      }
    }
  );
}

export type UpdatePrizePayload = {
  status_event: string;
  id: number | string;
}

export const updateCampaignStatus = (payload: UpdatePrizePayload) =>
  http.put<UpdatePrizePayload>(
    `${endpoint}/${payload.id}/update_status`,
    payload
  );

export function useUpdatePrizeMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdatePrizePayload) => updateCampaignStatus(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(prizesListingCacheKey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  );
}
