import clsx from 'clsx';
import { Dragger, DraggerProps, FormItem, UploadProps } from 'fave-ui';
import { useFormikContext } from 'formik';
import { Paperclip } from 'phosphor-react';
import { memo } from 'react';
import { showRemoveFileModal } from '../../../helpers/formHelpers';
import style from './style.module.css';

type FileUploadProps<T> = UploadProps<T> &
  DraggerProps & {
    name: keyof T;
    uploadURL?: string;
  };

const FileUpload = <T extends {}>({
  name,
  uploadURL,
  onRemove = (file) => showRemoveFileModal({ file }),
  Text = (
    <>
      <span>Click or drag file(s) to this area to upload</span>
      <br />
      <span className={style.fontBold}>Approval Document</span>
    </>
  ),
  Hint = <>Each file size is limited to 10MB</>,
  ...restProps
}: FileUploadProps<T>) => {
  const { errors, setFieldValue } = useFormikContext<T>();

  const error = errors[name];

  const removeIconMargin = !(Text && Hint);

  const MemoisedFileUpload = memo(() => (
    <Dragger
      name={name}
      beforeUpload={() => false}
      action={uploadURL}
      onChange={(info) =>
        setFieldValue(
          name,
          info.fileList.length === 0 ? undefined : info.fileList
        )
      }
      onRemove={onRemove}
      iconRender={() => <Paperclip size={16} />}
      Text={Text}
      Hint={Hint}
      className={clsx(removeIconMargin && style.uploadFileIcon)}
      {...restProps}
    />
  ));

  MemoisedFileUpload.displayName = 'MemoisedFileUpload';

  return (
    <FormItem
      validateStatus={error !== undefined ? 'error' : 'success'}
      help={error}
    >
      <MemoisedFileUpload />
    </FormItem>
  );
};

export default FileUpload;
