import { Button, DescriptionsItem } from 'fave-ui';
import SummaryCard from '../../components/SummaryCard';
import { useFetchOutletSummary } from '../../services/OutletSettings';
import { useElementIdContext } from '.';

type OutletSummaryprops = {
  dealID: number;
  onLinkedOutletsClicked: () => void;
};

const OutletSummary = ({
  dealID,
  onLinkedOutletsClicked,
}: OutletSummaryprops) => {
  const { data: outletSummaryData } = useFetchOutletSummary({
    dealID,
  });

  const { btnViewLinked } = useElementIdContext();

  const descriptionItems = [
    {
      key: '1',
      label: 'Linked',
      children: outletSummaryData?.linked_outlets,
    },
    {
      key: '2',
      label: 'Active',
      children: outletSummaryData?.active_linked_outlets,
    },
    {
      key: '3',
      label: 'Inactive',
      children: outletSummaryData?.inactive_linked_outlets,
    },
  ];

  return (
    <>
      <SummaryCard
        title={'Deal Outlet Summary'}
        colon={false}
        column={3}
        endSideContent={
          <Button
            data-id={btnViewLinked}
            type="link"
            onClick={onLinkedOutletsClicked}
          >
            Linked Outlets
          </Button>
        }
      >
        {descriptionItems.map((item) => (
          <DescriptionsItem {...item} key={item.key} />
        ))}
      </SummaryCard>
    </>
  );
};

export default OutletSummary;
