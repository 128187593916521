import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { PopConfirm, Button, Notification, UploadFile } from 'fave-ui';
import { Row, Col } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { useAddPrizeMutation } from '../../../../services/FaveArcade/useFetchPrizesListing';
import { appendFormData, formatDateTime } from '../../../../utils/utilFunctions';
import { useState } from 'react';
import { DatePicker } from '../../../../components/common/form';
import ImageUpload from '../../../../components/common/form/ImageUpload';

type LuckyDrawFormFields = {
  id?: number;
  campaign_name: string;
  prize_name: string;
  prize_subtitle?: string;
  prize_description: string;
  cost_of_token: number;
  max_user_limit: number;
  max_campaign_limit?: number;
  start_date: string;
  end_date: string;
  asset: UploadFile[];
  is_highlighted: boolean;
};

const initialValues: LuckyDrawFormFields = {
  campaign_name: '',
  prize_name: '',
  prize_description: '',
  cost_of_token: 0,
  max_user_limit: 0,
  start_date: '',
  end_date: '',
  asset: [],
  is_highlighted: true,
};

const CampaignFormSchema = Yup.object().shape({
  campaign_name: Yup.string().min(1).required('Please give your campaign a name'),
  prize_name: Yup.string().required('Please give your prize a name'),
  prize_description: Yup.string().required('Please give your prize a description'),
  start_date: Yup.date()
    .typeError('Please select a start date')
    .required('Please select a start date'),
  end_date: Yup.date()
    .typeError('Please select an end date')
    .min(Yup.ref('start_date'), "End date can't be before start date")
    .required('Please select an end date'),
  cost_of_token: Yup.number().min(1).required('Please enter the amount of tokens'),
  max_user_limit: Yup.number().min(1).required('Please enter enter the max entries per user'),
  max_campaign_limit: Yup.number().min(0),
  asset: Yup.array().of(Yup.mixed().nullable().required("An image is required")).min(1, 'Please upload an image'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Prize Campaign Submitted!',
    description:
      'Your new submission has been added too Fave Arcade prize center succesfully',
  });
};

const LuckyDrawForm = ({ prizeType }: { prizeType: string }) => {
  const [isChecked, setIsChecked] = useState(true);
  const { mutateAsync: addNewPrize } = useAddPrizeMutation('lucky_draw');
  const navigate = useNavigate();

  const handleSubmit = async (
    values: LuckyDrawFormFields,
    { setSubmitting }: FormikHelpers<LuckyDrawFormFields>
  ) => {
    const payload = {
      ...values,
      prize_type: prizeType,
      asset: values.asset[0].originFileObj,
      start_date: formatDateTime(values.start_date, "yyyy-MM-dd"),
      end_date: formatDateTime(values.end_date, "yyyy-MM-dd"),
    };
    const formData = appendFormData(payload);
    try {
      await addNewPrize(
        { formData },
        {
          onSuccess: () => {
            successNotification();
          },
        }
      );
    }
    finally {
      setSubmitting(false);
      navigate('/fave_arcade/prize_catalog');
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={CampaignFormSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="campaign_name"
            label="Campaign Name"
            placeholder="Enter campaign name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_name"
            label="Prize Name"
            placeholder="Enter prize name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_subtitle"
            label="Prize Subtitle (Optional)"
            placeholder="Enter prize subtitle"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='textArea'
            name="prize_description"
            label="Prize Description"
            placeholder="Enter prize description"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <DatePicker
            name="start_date"
            label="Start Date"
            allowClear
            bordered
          />
        </Col>
        <Col span={12}>
          <DatePicker
            name="end_date"
            label="End Date"
            allowClear
            bordered
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="max_user_limit"
            label="Max Entries per User"
            placeholder="Enter number of max entries"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <ImageUpload
            name="asset"
            label="Asset (image)"
            types={["image/jpeg", "image/png"]}
            imgSize={2}
          />
        </Col>
        <Col span={12}>
          <Input
            name="is_highlighted"
            label="Highlight the Prize"
            type="checkbox"
            checked={isChecked}
            onChangeCheckbox={(e, handleChange) => {
              setIsChecked(e)
              handleChange()
            }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="cost_of_token"
            label="Price per entry (tokens)"
            placeholder="Enter number of tokens"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="max_campaign_limit"
            label="Max entries (campaign total)"
            placeholder="Enter number of campaign max entries"
            min={0}
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <SubmitButton disableIfNotValid disableIfNotDirty>
            {(_, isSubmitting) =>
              isSubmitting ? 'Submiting...' : 'Save'
            }
          </SubmitButton>
          <PopConfirm
            title="Are you sure you want to cancel? All progress will be lost for the current campaign."
            onConfirm={() => navigate('/fave_arcade/prize_catalog')}
            okText="Yes, I am sure"
            cancelText="Go Back"
          >
            <Button type='primary' size='large'>
              Cancel
            </Button>
          </PopConfirm>
        </Col>
      </Row>
    </Form >
  )
}

export default LuckyDrawForm;
