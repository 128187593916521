import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Info } from 'phosphor-react';
import style from '../style.module.css';
import { MakerCheckerStatusTag } from '../../../components/common/StatusTag';
import { TagColor } from '../helper/enum';
import GreyDivider from '../../../components/GreyDivider';
import { Table, Tooltip } from 'fave-ui';
import {
  Request,
  RequestChangesContent,
} from '../../../services/MakerChecker/useMakerChecker';
import { formatDate } from '../../../utils/utilFunctions';
import { formatRequestChangesToData } from '../helper';

export type AmendmentType = {
  input: string;
  original_input: string | number;
  amended_input: string | number;
  value?: RequestChangesContent;
};

type MakerCheckerRequestType = {
  request: Request;
};

const dataColumns: ColumnsType<AmendmentType> = [
  {
    title: 'Inputs',
    dataIndex: 'input',
    width: 120,
    render: (input: string) => <>{input}</>,
  },
  {
    title: 'Original Input',
    dataIndex: 'original_input',
    width: 160,
    render: (original_input: string) => (
      <div className="w-40 whitespace-pre-line">{original_input}</div>
    ),
  },
  {
    title: 'Amended Input',
    dataIndex: 'amended_input',
    width: 160,
    render: (amended_input: string) => (
      <div className="w-40 whitespace-pre-line">{amended_input}</div>
    ),
  },
];

const ExpandTable: React.FC<AmendmentType> = (props: AmendmentType) => {
  const data = () => {
    if (props.value && !Array.isArray(props.value)) {
      return formatRequestChangesToData(props.value);
    }
    return (props.value as RequestChangesContent[]).map(model =>
      Object.keys(model).map(key => {
        if (!Array.isArray(model[key]))
          return {
            input: key.replaceAll('_', ' '),
            original_input: '',
            amended_input: '',
            value: model[key],
          };
        return {
          input: key.replaceAll('_', ' '),
          original_input: model[key][0],
          amended_input: model[key][1],
        };
      }),
    );
  };
  const dataIsArray = !data().findIndex(
    (item: unknown) => Array.isArray(item) && item[0]?.value,
  );

  const dataSource = data().flat(1) as AmendmentType[];

  return (
    <Table
      showHeader={false}
      size="small"
      rowKey={row => row.input}
      dataSource={dataSource}
      columns={dataColumns}
      className={style.table}
      pagination={{
        hideOnSinglePage: true,
        pageSize: dataSource.length || 0,
      }}
      expandable={
        dataIsArray
          ? {
              expandedRowRender: prop => ExpandTable(prop),
            }
          : undefined
      }
    />
  );
};

const MakerCheckerRequest: React.FC<MakerCheckerRequestType> = props => {
  const { request } = props;

  const dataChanges = useMemo<AmendmentType[]>(
    () =>
      Object.keys(request.changes).map(key => ({
        input: key.replaceAll('_', ' '),
        original_input: '',
        amended_input: '',
        value: request.changes[key],
      })) as AmendmentType[],
    [request],
  );

  return (
    <div className={style.drawerInfo}>
      <div className={style.requestHeader}>
        Request ID
        <div className="font-bold text-base">R-{request.id}</div>
      </div>
      <div className={style.information}>
        <div className={style.row}>
          <div className={style.col}>
            Date Requested
            <div className={style.content}>
              {formatDate(request.creator.created_at, 'dd-MM-yyyy')}
            </div>
          </div>
          <div className={style.col}>
            Status
            <div className={style.content}>
              <MakerCheckerStatusTag
                status={TagColor[request.status as keyof typeof TagColor]}
              />
              {request.reviewer.name && <span>by {request.reviewer.name}</span>}
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            Merchant ID
            <div className={style.content}>{request.merchant_id}</div>
          </div>
          <div className={style.col}>
            Merchant name
            <div className={style.content}>{request.merchant_name}</div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            About
            <div className={style.content}>{request.about}</div>
          </div>
          <div className={style.col}>
            Date of Actions
            <div className={style.content}>
              {request.reviewer.reviewed_at
                ? formatDate(request.reviewer.reviewed_at, 'dd-MM-yyyy')
                : ''}
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            Page Source
            <div className={style.content}>
              <a
                className="underline"
                href={request.url}
                target="_blank"
                rel="noreferrer"
              >
                Go to original page
              </a>
            </div>
          </div>
          <div className={style.col}>
            Checker Remarks
            <div className={style.content}>
              {request.rejected_reason || '-'}
            </div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            Maker
            <div className={style.content}>{request.creator.name}</div>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col}>
            <span className="flex items-center">
              Checker
              <Tooltip title="List of checkers who will be approving your change request">
                <Info size={12} className="ml-3" />
              </Tooltip>
            </span>
            {request.checkers.map((checker, index) => (
              <div key={`${checker.name}${index}`} className={style.content}>
                {checker.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <GreyDivider />
      <div className={style.amendmentDetail}>
        <div className={style.amendmentHeader}>Amendment Detail</div>
        <Table
          size="small"
          rowKey={row => row.input}
          dataSource={dataChanges}
          columns={dataColumns}
          className={style.table}
          pagination={{
            hideOnSinglePage: true,
            pageSize: dataChanges.length || 0,
          }}
          expandable={{
            expandedRowRender: prop => ExpandTable(prop),
          }}
        />
      </div>
    </div>
  );
};

export default MakerCheckerRequest;
