import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SideNavRoutesType } from '../types/configTypes';
import { capitalizeFirstLetterEachWord } from '../utils/utilFunctions';

type BreadCrumbsType = Pick<SideNavRoutesType, 'breadcrumb'>;

export type BreadcrumbParamsType = {
  label: string;
  key: string;
};

type ContextType = {
  setBreadcrumbParams: (data: BreadcrumbParamsType[]) => void;
  breadcrumbs: BreadCrumbsType[];
};

export const BreadCrumbsContext = createContext<ContextType>({
  setBreadcrumbParams: () => ({}),
  breadcrumbs: [],
});

// use useSetBreadCrumbParams hook to update breadcrumbs
export const BreadCrumbsContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [breadcrumbParams, setBreadcrumbParams] = useState<
    BreadcrumbParamsType[]
  >([]);
  const location = useLocation();

  const returnedBreadcrumbs = useMemo(() => {
    const currentPageURL = location.pathname.split('/');
    const adminIndex = currentPageURL.indexOf('admin');
    const partnerIndex = currentPageURL.indexOf('partners');

    if (adminIndex !== -1) currentPageURL[adminIndex] = '';
    if (partnerIndex !== -1) currentPageURL[partnerIndex] = '';

    breadcrumbParams.forEach((params) => {
      const indexOfID = currentPageURL.indexOf(params.key);
      if (indexOfID !== -1) currentPageURL[indexOfID] = params.label;
    });

    const newBreadCrumbs: BreadCrumbsType[] = [];

    currentPageURL.forEach((page) => {
      newBreadCrumbs.push({
        breadcrumb: capitalizeFirstLetterEachWord(
          page.includes('_and_')
            ? // handles routes that have _and_ to be &
              page.replace('_and_', ' & ')
            : page.includes('_')
            ? // handles routes to add spaces
              page.split('_').join(' ')
            : page
        ),
      });
    });

    return newBreadCrumbs;
  }, [location, breadcrumbParams]);

  return (
    <BreadCrumbsContext.Provider
      value={{ setBreadcrumbParams, breadcrumbs: returnedBreadcrumbs }}
    >
      {children}
    </BreadCrumbsContext.Provider>
  );
};

export const useBreadCrumbsContext = () => useContext(BreadCrumbsContext);
