import { ColumnsType } from 'antd/lib/table/interface';
import {
  Descriptions,
  DescriptionsItem,
  Empty,
  FilterIcon,
  Table,
} from 'fave-ui';
import React, { useMemo, useState } from 'react';
import { StatusTag } from '../../../components/common/StatusTag';
import { TableHeaderSort } from '../../../components/common/TableHeaderSort';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import { FixedFeeType } from '../../../services/merchants';
import { useFetchBDUsersList } from '../../../services/ProductDashboard/bdUsersList';
import {
  FavepayType,
  useFetchFavePaySummary,
  useFetchFavePayData,
} from '../../../services/ProductDashboard/favepaySummary';
import { TableSettingsType } from '../../../types/configTypes';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  dateOrDash,
  formatFilter,
} from '../../../utils/utilFunctions';
import { ProductTabsProps } from '../ProductDashboardView';
import style from './style.module.css';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

const tableConfigDefault = {
  page: 1,
  limit: 10,
  sort_order: undefined,
  sort_by: undefined,
  filter: undefined,
};

type FavePayTabProps = ProductTabsProps & { fixedFee?: FixedFeeType };

export const FavepayTab: React.FC<FavePayTabProps> = ({
  company_id,
  fixedFee,
}) => {
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(tableConfigDefault);

  const { data: favepaySummaryData } = useFetchFavePaySummary({
    company_id,
  });
  const {
    data: favepayOutletsData,
    isFetching,
    isError,
  } = useFetchFavePayData({
    company_id,
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: Array.isArray(tableConfig.sort_by)
      ? tableConfig.sort_by.join('.')
      : tableConfig.sort_by,
    filter: `bd_payment_details.status=completed|${tableConfig.filter || ''}`,
    dataset: 'outlet',
  });

  const { data: bdUsersListData } = useFetchBDUsersList({
    company_id,
  });

  const favepayTabColumns: ColumnsType<FavepayType> = [
    {
      key: 'signature_date',
      title: 'Date Signed',
      width: 135,
      render: (row: FavepayType) => {
        const noBDNameAndSignedDate = !(row?.bd_name && row?.signature_date);
        const favePayLive = row?.outlet.favepay_status === 'Live';
        const favePayInactive = row?.outlet.favepay_status === 'Inactive';

        const favePayFromAdminToolOrMerchantConnect =
          noBDNameAndSignedDate && favePayLive;

        const noFavePaySubmission = noBDNameAndSignedDate && favePayInactive;

        return favePayFromAdminToolOrMerchantConnect
          ? RenderColumn.infoPopover({
              content:
                'This Favepay was either created through admin tool or old merchant connect , hence “Date Signed” & “Signed By” column information could not be retrieved',
              text: 'N/A',
            })
          : noFavePaySubmission
          ? RenderColumn.infoPopover({
              content: `There's no FavePay submission found for this outlet`,
              text: 'N/A',
            })
          : dateOrDash(row.signature_date);
      },
    },
    {
      dataIndex: 'bd_name',
      title: 'Signed by',
      filters: bdUsersListData
        ? bdUsersListData.map((item) => {
            return { text: item.bd_name, value: item.bd_email };
          })
        : [],
      filterMode: 'tree',
      filterSearch: true,
      filterIcon: FilterIcon,
      width: 130,
      render: (data) => <div>{data || 'N/A'}</div>,
    },
    {
      dataIndex: 'outlet',
      title: 'FavePay Status',
      filters: [
        { text: `Live (${favepaySummaryData?.live_outlets})`, value: 'Live' },
        {
          text: `Inactive (${favepaySummaryData?.inactive_outlets})`,
          value: 'Inactive',
        },
      ],
      filterMode: 'tree',
      filterIcon: FilterIcon,
      width: 160,
      render: (data) => <StatusTag status={data.favepay_status} />,
    },
    {
      dataIndex: ['outlet', 'name'],
      title: TableHeaderSort('Outlet Name'),
      sorter: true,
      width: 216,
      render: (data) => <div>{data}</div>,
    },
    {
      dataIndex: 'cashback_rate',
      title: TableHeaderSort('Cashback'),
      sorter: true,
      width: 120,
      render: (data) => <div>{`${data}%`}</div>,
    },
    {
      dataIndex: 'outlet',
      title: 'Cashback Validity',
      width: 155,
      render: (data) => (
        <div>{`${data.company.company_credits_expiration_time} months`}</div>
      ),
    },
    {
      dataIndex: 'fave_fees',
      title: 'Fave Fees',
      width: 100,
      render: (data) => <div>{`${data}%`}</div>,
    },
    {
      dataIndex: 'alipay_fees',
      title: 'Alipay fees',
      width: 110,
      render: (data) => <div>{data || 'N/A'}</div>,
    },
    {
      dataIndex: 'outlet',
      title: 'Bank Account',
      width: 271,
      render: (data) => (
        <div>{`${data.bank_account.bank_name} - ${data.bank_account.account_number}`}</div>
      ),
    },
  ];

  const tableChangeHandler = useMemo(
    () =>
      createOnTableChangeHandler(
        setTableConfig,
        (filters) =>
          formatFilter([
            { key: 'bd_email', value: filters.bd_name },
            { key: 'outlet.favepay_status', value: filters.outlet },
          ]),
        (sorter) => {
          if (sorter.columnKey === 'signature_date')
            sorter.field = 'signature_date';
          return sorter;
        }
      ),
    [setTableConfig]
  );

  const descriptionItems = [
    {
      label: 'Total outlets',
      key: 1,
      children: favepaySummaryData?.total_outlets,
    },
    {
      label: 'Live outlets',
      key: 2,
      children: favepaySummaryData?.live_outlets,
    },
    {
      label: 'Inactive outlets',
      key: 3,
      children: favepaySummaryData?.inactive_outlets,
    },
  ];

  const descriptionFixedFeeItems = [
    {
      label: 'Fee amount',
      key: 1,
      children: `${fixedFee?.percentage}%`,
    },
    {
      label: 'Effective date',
      key: 2,
      children: dateOrDash(fixedFee?.effective_date),
    },
    {
      label: `${fixedFee?.signature_date ? 'Date signed' : 'Updated on'}`,
      key: 3,
      children: dateOrDash(fixedFee?.signature_date || fixedFee?.updated_at),
    },
    {
      label: `${fixedFee?.signed_by ? 'Signed by' : 'Updated by'}`,
      key: 4,
      children: fixedFee?.signed_by || fixedFee?.updated_by,
    },
  ];

  return (
    <div className={`${style.favePayTab} ${isError && style.isError}`}>
      {isError ? (
        <Empty emptyType={'cant-find-anything'} />
      ) : (
        <>
          <Descriptions
            title={'FavePay Summary'}
            layout="vertical"
            colon={false}
            column={4}
            className={style.descriptions}
          >
            {descriptionItems.map(({ key, ...restItems }) => (
              <DescriptionsItem key={key} {...restItems} />
            ))}
          </Descriptions>
          {fixedFee && (
            <Descriptions
              title={
                <div className={style.fixedFee}>
                  <span className={style.title}>Fixed Fee</span>
                  <div className={style.statusTag}>
                    <StatusTag status={fixedFee.status} />
                  </div>
                </div>
              }
              layout="vertical"
              colon={false}
              column={4}
              className={style.descriptions}
            >
              {descriptionFixedFeeItems.map(({ key, ...restItems }) => (
                <DescriptionsItem key={key} {...restItems} />
              ))}
            </Descriptions>
          )}
          <Table
            size={'large'}
            rowKey={(row) => row.id}
            dataSource={favepayOutletsData?.favepays}
            columns={favepayTabColumns}
            loading={isFetching}
            pagination={{
              hideOnSinglePage: true,
              total: favepayOutletsData?.count,
              position: ['bottomCenter'],
              pageSize: tableConfig.pageSize,
              current: tableConfig.page,
              showSizeChanger: false,
            }}
            scroll={{
              x: tableWidth,
              y: tableHeight,
            }}
            onChange={tableChangeHandler}
          />
        </>
      )}
    </div>
  );
};
