import { useMemo } from 'react';
import { Table } from 'fave-ui';
import { ColumnsType } from 'antd/lib/table';
import { useVT } from 'virtualizedtableforantd4';
import {
  ChangeHistoryParams,
  ChangeHistoryItem,
  useFetchChangeHistory,
} from '../services/useFetchChangeHistory';
import {
  calcTableHeight,
  calcTableWidth,
  dateTimeOrDash,
} from '../utils/utilFunctions';
import { RenderColumn } from '../helpers/tableHelpers/RenderColumn';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

const scrollConfig = { x: tableWidth, y: tableHeight };

const ChangeHistoryTable = ({ item_type, item_id }: ChangeHistoryParams) => {
  const {
    data: changeHistory,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useFetchChangeHistory({
    item_type,
    item_id,
  });

  const changeHistoryColumns: ColumnsType<ChangeHistoryItem> = [
    {
      title: 'When',
      dataIndex: 'created_at',
      width: 200,
      render: (date) => dateTimeOrDash(date),
    },
    {
      title: 'Who',
      dataIndex: 'whodunnit',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Changed Field',
      dataIndex: 'changed_field',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Old Value',
      dataIndex: 'old_value',
      width: 300,
      render: RenderColumn.rowText,
    },
    {
      title: 'New value',
      dataIndex: 'new_value',
      width: 300,
      render: RenderColumn.rowText,
    },
  ];

  const historyItems = useMemo(
    () => changeHistory?.pages.flatMap((page) => page.list) || [],
    [changeHistory]
  );

  const [virtualTable] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd && hasNextPage) fetchNextPage();
      },
      scroll: scrollConfig,
    }),
    [scrollConfig, hasNextPage, fetchNextPage]
  );

  return (
    <Table
      columns={changeHistoryColumns}
      dataSource={historyItems}
      loading={isFetching}
      scroll={scrollConfig}
      components={virtualTable}
      pagination={{ pageSize: historyItems.length, hideOnSinglePage: true }}
      // TODO BE should include `id` in  'v1/versions' response to avoid `key` related errors
      rowKey="id"
    />
  );
};

export default ChangeHistoryTable;
