import { UploadFile } from 'fave-ui';
import { useMutation, useQuery } from 'react-query';
import {
  CompanyIDPayloadType,
  FilterPayloadType,
  IsEnabledPayloadType,
  IsUpdatePayloadType,
} from '../../types/configTypes';
import { EnabledStatusType } from '../../types/dataTypes';
import http, { createFetchAPI, handleApiError } from '../http';
import * as Yup from 'yup';

export const ippMerchantIDValidation = {
  ipp_merchant_id: Yup.number()
    .typeError('IPP Merchant ID must be in numbers')
    .required('Please enter IPP Merchant ID'),
};

export const companyIPPIssuerValidation = Yup.array()
  .of(
    Yup.object().shape({
      status: Yup.boolean(),
    })
  )
  .test({
    test: (value) =>
      (value as unknown as IPPIssuersType[])?.filter((item) => item.status)
        .length > 0,
    message: 'At least one bank is required.',
  })
  .required();

export const companyIPPTenureValidation = Yup.array()
  .of(
    Yup.object().shape({
      status: Yup.boolean(),
      rate: Yup.number().when('status', {
        is: true,
        then: (schema) =>
          schema
            .required('Please key in rate')
            .moreThan(0, 'Value must be more than 0'),
      }),
    })
  )
  .test({
    test: (value) =>
      (value as unknown as IPPTenuresType[])?.filter((item) => item.status)
        .length > 0,
    message: 'At least one tenure is required.',
  })
  .required();

export const approvalDocumentsValidation = {
  approval_documents: Yup.array().required('Please upload at least 1 file.'),
};

const ippSubmissionEndpoint = ({ company_id }: CompanyIDPayloadType) =>
  `/v1/fave_save_merchants/${company_id}/mc_company_ipp_settings`;

const ippConfigurationEndpoint = ({ company_id }: CompanyIDPayloadType) =>
  `/v1/companies/${company_id}/ipps`;

const fetchMCIPPQueryKey = 'fetchMCIPP';

export type IPPTenuresType = {
  id: number;
  status: EnabledStatusType | boolean;
  rate: number;
  tenure: number;
};

export type IPPIssuersType = {
  id: number;
  ipp_issuer: {
    id: number;
    name: string;
    status: EnabledStatusType | boolean;
    tenures: number[];
  };
  ipp_issuer_id: number;
  name: string;
  status: EnabledStatusType | boolean;
  tenures: number[];
};

export type CreateUpdateIPPFormType = {
  mc_company_ipp_tenures: IPPTenuresType[];
  mc_company_ipp_issuers: IPPIssuersType[];
  approval_documents: UploadFile[] | undefined;
};

type FetchIPPIssuersPayload = IsEnabledPayloadType & FilterPayloadType;

export type FetchIPPIssuersResponse = {
  ipp_issuers: IPPIssuersType[];
  ipp_tenures: number[];
};

type CreateIPPSubmissionPayload = CompanyIDPayloadType &
  IsUpdatePayloadType & { formData: FormData };

type FetchMCIPPPayload = CompanyIDPayloadType &
  IsEnabledPayloadType &
  FilterPayloadType;

type IPPMerchantIDType = { ipp_merchant_id: number };

type CompanyIPPTenuresType = {
  company_ipp_tenures: IPPTenuresType[];
};

type FetchCompanyIPPTenuresAndIssuersType = CompanyIPPTenuresType & {
  company_ipp_issuers: IPPIssuersType[];
};

export type MutateMIDFormType = CompanyIDPayloadType & IPPMerchantIDType;

type MutateCompanyIPPIssuerFormType = {
  id: number;
  status: EnabledStatusType | boolean;
  ipp_issuer_id: number;
};

type MutateIPPConfigurationFormType = CompanyIDPayloadType &
  CompanyIPPTenuresType & {
    company_ipp_issuers: MutateCompanyIPPIssuerFormType[];
  };

export type FetchIPPResponse = FetchCompanyIPPTenuresAndIssuersType &
  IPPMerchantIDType;

const fetchIPPIssuers = createFetchAPI<
  FetchIPPIssuersPayload,
  FetchIPPIssuersResponse
>('/v1/ipp_issuers');

export const useFetchIPPIssuers = ({
  enabled,
  filter,
}: FetchIPPIssuersPayload) =>
  useQuery<FetchIPPIssuersResponse>(
    ['fetchIPPIssuers', { filter }],
    fetchIPPIssuers,
    {
      enabled,
    }
  );

const createIPP = ({
  company_id,
  formData,
  isUpdate,
}: CreateIPPSubmissionPayload) =>
  isUpdate
    ? http.put(ippSubmissionEndpoint({ company_id }), formData)
    : http.post(ippSubmissionEndpoint({ company_id }), formData);

export const useCreateIPP = () =>
  useMutation(createIPP, {
    //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
    onError: handleApiError,
  });

const fetchMCIPP = createFetchAPI<FetchMCIPPPayload, CreateUpdateIPPFormType>();

export const useFetchMCIPP = ({
  company_id,
  enabled,
  filter,
}: FetchMCIPPPayload) =>
  useQuery<CreateUpdateIPPFormType>(
    [
      fetchMCIPPQueryKey,
      { url: ippSubmissionEndpoint({ company_id }), filter },
    ],
    fetchMCIPP,
    { enabled }
  );

const mutateMID = ({ company_id, ...restPayload }: MutateMIDFormType) =>
  http.put(`/v1/companies/${company_id}/ipps/update_mid`, restPayload);

export const useMutateMID = () =>
  //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
  useMutation(mutateMID, { onError: handleApiError });

const handleParseResponseFetchIPP = (response: FetchIPPResponse) => {
  const companyIPPIssuers = response.company_ipp_issuers.map((item) => ({
    ...item,
    status: item.status === 'enabled',
    name: item.ipp_issuer.name,
    tenures: item.ipp_issuer.tenures,
  }));

  const companyIPPTenures = response.company_ipp_tenures.map((item) => ({
    ...item,
    status: item.status === 'enabled',
  }));

  const data = {
    ...response,
    company_ipp_issuers: companyIPPIssuers,
    company_ipp_tenures: companyIPPTenures,
  };

  return data;
};

const fetchIPP = createFetchAPI<CompanyIDPayloadType, FetchIPPResponse>('', {
  onParseResponse: handleParseResponseFetchIPP,
});

export const useFetchIPP = ({ company_id }: CompanyIDPayloadType) =>
  useQuery<FetchIPPResponse>(
    ['fetchIPP', { url: ippConfigurationEndpoint({ company_id }) }],
    fetchIPP
  );

const mutateIPPConfiguration = ({
  company_id,
  ...restPayload
}: MutateIPPConfigurationFormType) =>
  http.put(ippConfigurationEndpoint({ company_id }), restPayload);

export const useMutateIPPConfiguration = () =>
  //todo: remove onError. handleApiError is called globally in mutationCache. See App.tsx
  useMutation(mutateIPPConfiguration, { onError: handleApiError });
