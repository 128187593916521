import { useQuery, UseQueryOptions } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { createFetchAPI } from '../http';

const endpoint = '/v1/product_campaigns/product_summary';
export const campaignSummaryCacheKey = 'fetchCampaignSummary';

export type CampaignSummaryResponseType = {
  total_campaigns: number;
  live_campaigns: number;
  scheduled_campaigns: number;
};

const fetchCampaignSummary = createFetchAPI<
  TableConfigType,
  CampaignSummaryResponseType
>(endpoint);

export const useCampaignSummary = (
  options?: UseQueryOptions<CampaignSummaryResponseType, unknown>
) =>
  useQuery<CampaignSummaryResponseType>(
    [campaignSummaryCacheKey],
    fetchCampaignSummary,
    { ...options }
  );
