import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { PopConfirm, Button, Col, Row } from 'fave-ui';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { StatusType } from '../../../../types/dataTypes';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useFetchGameOptions } from '../../../../services/FaveArcade/useFetchGamesListing';
import { arrayToOptions } from '../../../../utils/utilFunctions';

type CampaignFormFields = {
  id?: number;
  campaign_type: string;
  whitelisted_outlet_ids: string[];
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

const initialValues: CampaignFormFields = {
  campaign_type: '',
  whitelisted_outlet_ids: [],
};

const CampaignFormSchema = Yup.object().shape({
  campaign_type: Yup.string().required('Please select a campaign')
});

export default function NewFaveCashbackCampaign() {
  const { data: optionsData } = useFetchGameOptions();
  const navigate = useNavigate();
  const gameTypes = optionsData && arrayToOptions(optionsData?.game_types, '_');

  return (
    <>
      <PageHeaderWithBackButton
        title={`Create New Cashback Campaign`}
      />
      <Form
        initialValues={initialValues}
        validationSchema={CampaignFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Select
              name="campaign_type"
              label="* Campaign Type"
              placeholder="Please Select one option"
              options={gameTypes}
            />
          </Col>
          <Col span={24}>
            <Input
              type='textArea'
              name="whitelisted_merchant_ids"
              label="Whitelisted Merchants"
              placeholder="Enter the ID, seperate by comma"
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) =>
                isSubmitting ? 'Submiting...' : 'Save'
              }
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/admin/merchandise_and_campaign/cashback_campaigns')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type='primary' size='large'>
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}

