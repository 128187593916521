import { Breadcrumb, BreadcrumbItem } from 'fave-ui';
import { Link } from 'react-router-dom';
import { getToolRedirectURL } from '../../services/http';
import { useBreadCrumbsContext } from '../../contexts/BreadCrumbsContext';

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadCrumbsContext();

  return (
    <Breadcrumb>
      {breadcrumbs.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === breadcrumbs.length - 1;

        return isFirst ? (
          <BreadcrumbItem
            key={item.breadcrumb}
            href={getToolRedirectURL('/admin/partners')}
          >
            Home
          </BreadcrumbItem>
        ) : isLast && item.breadcrumb ? (
          <BreadcrumbItem key={item.breadcrumb}>
            <Link className='last-breadcrumb' to={location.pathname}>{item.breadcrumb}</Link>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={item.breadcrumb}>
            {item.breadcrumb}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
