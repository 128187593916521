import { useState } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col, } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Empty, FilterIcon, Row, Table, Button, Modal, Menu, Dropdown, Notification } from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import { createOnTableChangeHandler, formatFilter } from '../../../utils/utilFunctions';
import { useFetchCashbackCampaignListing, CashbackCampaignListingItem, useUpdateCashbackCampaignMutation, UpdateCashbackCampaignStatus } from '../../../services/CashbackCampaign/useFetchCashbackCampaignListing';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { Plus, DotsThree } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../../components/common/SearchBarExpandable/SearchBarExpandable';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import { CampaignStatusType, UpdateStatusType } from '../../../types/dataTypes';
import type { AxiosResponse } from 'axios';

const successNotification = (message: string) => {
  Notification.success({
    message: message
  });
};

const FaveFundedCashback = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tableConfig, setTableConfig] = useState<TableSettingsType>(defaultTableConfig);
  const [showModal, setShowModal] = useState(false);
  const [campaign, setCampaign] = useState<CashbackCampaignListingItem>();
  const navigate = useNavigate();

  const { mutateAsync: updateCashbackCampaignStatus } = useUpdateCashbackCampaignMutation();
  const { data: campaignData, isFetching: campaignFetching } = useFetchCashbackCampaignListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const campaignListingData = campaignData?.cashback_campaigns?.map((campaign: CashbackCampaignListingItem) => {
    return {
      ...campaign,
      status: capitalizeFirstLetterEachWord(campaign.status) as CampaignStatusType,
    }
  });

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) => formatFilter([{ key: 'status', value: filters.status }])
  );

  const handleUpdateStatus = async (campaign: UpdateCashbackCampaignStatus) => 
    await updateCashbackCampaignStatus(campaign,
      {
        onSuccess: (res: AxiosResponse) => {
          successNotification(res?.data?.message ?? 'Your action is success!');
          setShowModal(false);
        }
      }
    );

  const campaignListingColumns: ColumnsType<CashbackCampaignListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      render: RenderColumn.rowText,
    },
    {
      title: 'Campaign Type',
      dataIndex: 'campaign_type',
      width: 250,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
      ],
      width: 120,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'Change Log',
      width: 100,
      render: (row: CashbackCampaignListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      render: (row: CashbackCampaignListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'edit',
            label: 'View/Edit',
            onClick: () => navigate(`/admin/merchandise_and_campaign/cashback_campaigns/edit_campaign/${row.id}`),
          },
          {
            key: 'editStatus',
            label: row.status === 'Active' ? 'Deactivate' : 'Activate',
            onClick: () => {
              if (row.status === 'Active') {
                setShowModal(true);
                setCampaign(row);
              } else {
                handleUpdateStatus({ id: row.id, status_event: row.status_event[0] as UpdateStatusType})
              }
            },
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={(e) => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Campaign Summary'}</h2>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={(keyword) => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => navigate('/admin/merchandise_and_campaign/cashback_campaigns/new_campaign')}
                type="primary"
                disabled
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Table
          size="large"
          rowKey={(row) => row.id}
          loading={campaignFetching}
          columns={campaignListingColumns}
          onChange={tableChangeHandler}
          dataSource={campaignListingData}
          className={style.table}
          pagination={{
            total: campaignData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !campaignFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Modal title={`Warning!`}
        visible={showModal}
        okText="Yes, proceed"
        cancelText="Cancel"
        onOk={() => campaign && handleUpdateStatus({ id: campaign.id, status_event: campaign.status_event[0] as UpdateStatusType })}
        onCancel={() => setShowModal(false)}
      >
        You are about to deactivate {campaign?.campaign_type}, proceed?
      </Modal>
    </>
  );
};

export default FaveFundedCashback;
