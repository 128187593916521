import { ColumnsType } from 'antd/lib/table';
import { Collapse, CollapsePanel, Empty, Table } from 'fave-ui';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { PageHeaderWithBackButton } from '../../../components/common/PageHeaderWithBackButton';
import { defaultTableConfig } from '../../../helpers/defaults';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  useFetchApplicationFeeData,
  useFetchMasterDashboardCompanyListing,
} from '../../../services/MasterDashboard/masterDashboardListing';
import {
  capitalizeFirstLetterEachWord,
  dateOrDash,
  valueOrDash,
} from '../../../utils/utilFunctions';
import {
  FinanceAccountType,
  useFetchMerchantFinanceAccounts,
} from '../../../services/merchants';
import { TableSettingsType } from '../../../types/configTypes';
import {
  createOnTableChangeHandler,
  scrollTableConfig,
} from '../../../utils/utilFunctions';
import style from './style.module.css';
import { Spin } from 'antd';
import { useFetchFavePayData } from '../../../services/ProductDashboard/favepaySummary';
import {
  McCompanyPaymentModesType,
  useFetchCompanyPaymentModeList,
} from '../../../services/PaymentMode/usePaymentMode';
import { EnabledStatusType } from '../../../types/dataTypes';
import { useFetchMCIPP } from '../../../services/IPP/useIPP';

type CollapsedPanelType = {
  header: string;
  key: string;
  children: JSX.Element;
};

type TableRowDataType = {
  title: string;
  value: string | number;
};

const TableRowData = ({ title, value }: TableRowDataType) => (
  <tr>
    <td>
      {title} <div />
    </td>
    <td>{value}</td>
  </tr>
);

const TableRow = ({ data }: { data: TableRowDataType[] }) => (
  <table className={style.merchantListingTable}>
    <tbody>
      {data.map((item, index) => (
        <TableRowData key={index} {...item} />
      ))}
    </tbody>
  </table>
);

const tableScroll = scrollTableConfig();

const MasterContractView: React.FC = () => {
  const { company_id, mc_id } = useParams();
  const parsedCompanyID = parseInt(company_id || '');
  const parsedMCID = parseInt(mc_id || '');

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const {
    data: masterDashboardListingData,
    isFetching: isMasterDashboardListingDataLoading,
  } = useFetchMasterDashboardCompanyListing({
    id: parsedMCID,
    dataset: 'company',
  });

  const {
    data: merchantFinanceAccountsData,
    isFetching: isMerchantFinanceAccountsDataLoading,
  } = useFetchMerchantFinanceAccounts({
    tableConfigs: { ...tableConfig },
    merchantID: parsedCompanyID,
    dataset: 'outlets,bank_info',
  });

  const { data: fetchFavePayData } = useFetchFavePayData({
    company_id: parsedCompanyID,
    dataset: 'outlet',
  });

  const { data: fetchApplicationFeeData } = useFetchApplicationFeeData({
    id: parsedCompanyID,
  });

  const {
    data: fetchCompanyPaymentModeListData,
    isFetching: isFetchCompanyPaymentModeLoading,
  } = useFetchCompanyPaymentModeList({
    company_id: parsedCompanyID,
    filter: 'onboarding_status=signed',
  });

  const { data: fetchIPPData, isFetching: isFetchIPPDataLoading } =
    useFetchMCIPP({ company_id: parsedCompanyID });

  const mappedApplicationFeeData =
    fetchApplicationFeeData &&
    Object.keys(fetchApplicationFeeData).map((item) => {
      const objectKey = item as keyof typeof fetchApplicationFeeData;

      const title = capitalizeFirstLetterEachWord(item.split('_').join(' '));

      const value = title.includes('Date')
        ? dateOrDash(fetchApplicationFeeData[objectKey] as string)
        : valueOrDash(fetchApplicationFeeData[objectKey]);

      return {
        title,
        value,
      };
    });

  const tableChangeHandler = createOnTableChangeHandler(setTableConfig);

  const bankDetailsColumns: ColumnsType<FinanceAccountType> = [
    {
      title: 'Outlets',
      dataIndex: 'outlets',
      width: 150,
      render: RenderColumn.mappedText,
    },
    {
      title: 'Bank Account Name',
      dataIndex: 'bank_account_name',
      width: 150,
      render: RenderColumn.rowText,
    },
    {
      title: 'Bank Account Number',
      dataIndex: 'bank_account_number',
      width: 150,
      render: RenderColumn.rowText,
    },
    {
      title: 'Bank',
      dataIndex: ['bank_info', 'name'],
      width: 100,
      render: RenderColumn.rowText,
    },
  ];

  const masterDashboardListingDataArray = [
    {
      title: 'Name',
      value: valueOrDash(masterDashboardListingData?.company?.name),
    },
    {
      title: 'Contact Person',
      value: valueOrDash(masterDashboardListingData?.company?.person_in_charge),
    },

    {
      title: 'Main Contact Number',
      value: valueOrDash(masterDashboardListingData?.company?.phone),
    },
    {
      title: 'Registered Name',
      value: valueOrDash(
        masterDashboardListingData?.company?.contract_signee
          .business_registration_name
      ),
    },
    {
      title: 'Registered Number',
      value: valueOrDash(
        masterDashboardListingData?.company?.contract_signee
          .business_registration_number
      ),
    },
    {
      title: 'Registered Address',
      value: valueOrDash(
        masterDashboardListingData?.company?.contract_signee
          .business_registration_address
      ),
    },
  ];

  const companyMerchantListingDataArray = [
    {
      header: 'Company/Merchant Details',
      key: '1',
      children: <TableRow data={masterDashboardListingDataArray} />,
    },
  ];

  const bankDetailsListingDataArray = [
    {
      header: 'Bank Details',
      key: '2',
      children: (
        <Table
          rowKey={(row) => row.id}
          size={'small'}
          dataSource={merchantFinanceAccountsData?.finance_accounts}
          columns={bankDetailsColumns}
          scroll={tableScroll}
          onChange={tableChangeHandler}
          loading={isMerchantFinanceAccountsDataLoading}
          pagination={{
            hideOnSinglePage: true,
            total: merchantFinanceAccountsData?.total_count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.page,
            showSizeChanger: false,
          }}
        />
      ),
    },
  ];

  const outletInfoListingDataArray = fetchFavePayData?.favepays.map((item) => {
    const outletInfoData = [
      {
        title: 'Outlet Name',
        value: valueOrDash(item.outlet?.name),
      },
      {
        title: 'Vendor Name',
        value: valueOrDash(item.outlet.pos_vendor.name),
      },
      {
        title: 'Fastpay Status',
        value: valueOrDash(item.outlet?.favepay_status),
      },
      {
        title: 'FastPay Fee',
        value: valueOrDash(item.fave_fees),
      },
      {
        title: 'Fee Effective Date',
        value: dateOrDash(item.fee_effective_date),
      },
    ];
    return {
      header: item.outlet?.name,
      key: item.outlet?.id,
      children: <TableRow data={outletInfoData} />,
    };
  }) as unknown as CollapsedPanelType[];

  const applicationFeeTableDataArray = [
    {
      header: 'Application Fee',
      key: '3',
      children: mappedApplicationFeeData && (
        <TableRow data={mappedApplicationFeeData} />
      ),
    },
  ] as unknown as CollapsedPanelType[];

  const paymentModeTableColumns: ColumnsType<McCompanyPaymentModesType> = [
    {
      title: 'Group',
      dataIndex: ['payment_mode', 'group'],
      width: 100,
      render: RenderColumn.rowText,
    },
    {
      title: 'Variant',
      dataIndex: ['payment_mode', 'variant'],
      width: 100,
      render: RenderColumn.rowText,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      width: 100,
      render: (value) => valueOrDash(value ? `${value}%` : ''),
    },
    {
      title: 'Fixed Amount',
      dataIndex: 'fixed_amount',
      width: 150,
      render: (value) => valueOrDash(value ? `RM ${value}` : ''),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (status) =>
        RenderColumn.onOffStatus(
          capitalizeFirstLetterEachWord(status) as EnabledStatusType
        ),
    },
  ];

  const paymentModeTableDataArray = [
    {
      header: 'Payment Mode',
      key: '4',
      children: (
        <Table
          rowKey={(row) => row.id}
          size={'small'}
          dataSource={fetchCompanyPaymentModeListData?.mc_company_payment_modes}
          columns={paymentModeTableColumns}
          scroll={tableScroll}
          onChange={tableChangeHandler}
          loading={isFetchCompanyPaymentModeLoading}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.page,
            showSizeChanger: false,
          }}
        />
      ),
    },
  ];

  const enabledIPPTenures = fetchIPPData?.mc_company_ipp_tenures.filter(
    (item) => item.status === 'enabled'
  );

  const enabledIPPIssuers = () =>
    fetchIPPData?.mc_company_ipp_issuers
      .filter((item) => item.status === 'enabled')
      .map(({ ipp_issuer }) => ipp_issuer.name)
      .join(', ');

  const ippTableColumns: ColumnsType<any> = [
    {
      title: 'Tenure',
      dataIndex: 'tenure',
      width: 100,
      render: RenderColumn.customRowText(' mth'),
    },
    {
      title: 'Fee %',
      dataIndex: 'rate',
      width: 100,
      render: RenderColumn.customRowText(' %'),
    },
    {
      title: 'Banks',
      width: 100,
      render: enabledIPPIssuers,
      onCell: (_, index) =>
        index === 0
          ? {
              rowSpan: 5,
              className: style.ippBankColumn,
            }
          : { rowSpan: 0 },
    },
  ];

  const ippTableDataArray = [
    {
      header: 'IPP',
      key: '5',
      children: (
        <Table
          rowKey={(row) => row.id}
          size={'small'}
          dataSource={enabledIPPTenures}
          columns={ippTableColumns}
          scroll={tableScroll}
          onChange={tableChangeHandler}
          loading={isFetchIPPDataLoading}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.page,
            showSizeChanger: false,
          }}
        />
      ),
    },
  ];

  const collapsedTablesArray = companyMerchantListingDataArray
    .concat(bankDetailsListingDataArray)
    .concat(outletInfoListingDataArray)
    .concat(applicationFeeTableDataArray)
    .concat(paymentModeTableDataArray)
    .concat(ippTableDataArray)
    .filter((array) => array);

  return (
    <div className={style.masterContractView}>
      <PageHeaderWithBackButton
        title={'Master Contract'}
        subTitle={
          'Please check through the details and leave a comment if anything'
        }
      />
      <div className={style.masterContractContent}>
        <div className={style.pdf}>
          {isMasterDashboardListingDataLoading ? (
            <Spin />
          ) : masterDashboardListingData?.contract_url ? (
            <embed
              src={`${masterDashboardListingData.contract_url}`}
              width="100%"
              height="100%"
              type="application/pdf"
            />
          ) : (
            <Empty emptyType={'cant-find-anything'} />
          )}
        </div>
        <div className={style.listingTable}>
          <p className={style.submissionTitle}>{'Submission Details'}</p>
          <div className={style.greyLine} />
          <div>
            <Collapse
              expandIconPosition={'left'}
              className={style.panelContent}
            >
              {collapsedTablesArray.map((item, index) => (
                <CollapsePanel {...item} key={index} />
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterContractView;
