import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { PayloadBaseType, TableSettingsType } from '../../types/configTypes';
import { CompanyType, OutletType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';

export const dealsEndpoint = 'v1/eagle_activities';
const outletEagleEndpoint = (dealID: number) =>
  `${dealsEndpoint}/${dealID}/outlet_eagle_activities`;
const outletEagleWithIDEndpoint = (eagleID: number, dealID: number) =>
  `${outletEagleEndpoint(dealID)}/${eagleID}`;

const fetchLinkedOutletsCacheKey = 'fetchLinkedOutletsList';

export const queryKeys = {
  availableOutlets: 'fetchAvailableOutlets',
  outletSummary: 'fetchOutletSummary',
};

const fetchDealInfo = createFetchAPI<EagleDealInfoPayload, EagleDealInfo>();

export const useFetchDealInfo = (
  { dealID, dataset = 'company' }: EagleDealInfoPayload,
  enabled: boolean
) =>
  useQuery(
    ['fetchDealInfo', { url: `${dealsEndpoint}/${dealID}`, dataset }],
    fetchDealInfo,
    { enabled }
  );

const fetchOutletSummary = createFetchAPI<{}, OutletSummary>();

export const useFetchOutletSummary = ({ dealID }: OutletSummaryPayload) =>
  useQuery(
    [
      queryKeys.outletSummary,
      { url: `${outletEagleEndpoint(dealID)}/summary` },
    ],
    fetchOutletSummary
  );

const fetchAvailableOutlets = createFetchAPI<{}, AvailableOutletsResponse>();

export const useFetchAvailableOutlets = ({
  dealID,
  filter,
  page,
  pageSize,
  enabled,
}: AvailableOutletsPayload) =>
  useQuery(
    [
      queryKeys.availableOutlets,
      {
        url: `${dealsEndpoint}/${dealID}/available_outlets`,
        page,
        filter,
        limit: pageSize,
      },
    ],
    fetchAvailableOutlets,
    { enabled }
  );

const mutateDealOutletLink = ({
  dealID,
  ...restPayload
}: MutateDealOutletLink) =>
  http.post(
    `/v1/eagle_activities/${dealID}/outlet_eagle_activities`,
    restPayload
  );

export const useMutateDealOutletLink = () => {
  const queryClient = useQueryClient();

  return useMutation(mutateDealOutletLink, {
    onSuccess: () => {
      // delay refreshing for now
      setTimeout(() => {
        queryClient.invalidateQueries(queryKeys.availableOutlets);
        queryClient.invalidateQueries(queryKeys.outletSummary);
      }, 3000);
    },
  });
};

export type EagleDealInfoPayload = PayloadBaseType & {
  dealID: number;
};

export type EagleDealInfo = {
  id: number;
  name: string;
  company: Pick<CompanyType, 'id' | 'name'>;
};

export type OutletSummaryPayload = {
  dealID: number;
};

export type OutletSummary = {
  linked_outlets: number;
  active_linked_outlets: number;
  inactive_linked_outlets: number;
};

export type DealOutlet = Pick<OutletType, 'id' | 'name' | 'status'> & {
  status: 'active' | 'inactive';
};

export type AvailableOutletsPayload = {
  filter: string;
  page: number;
  pageSize: number;
  dealID: number;
  enabled: boolean;
};

export type AvailableOutletsResponse = {
  outlets: DealOutlet[];
  count: number;
};

export type MutateDealOutletLink = {
  dealID: number;
  all_outlets: boolean;
  outlet_ids: number[];
};

const fetchLinkedOutletsList = createFetchAPI<{}, DealOutletsResponseType>();

export const useFetchDealOutletsList = (
  { dealID, ...props }: DealOutletsPayload,
  { enabled }: UseQueryOptions
) =>
  useQuery(
    [
      fetchLinkedOutletsCacheKey,
      { url: outletEagleEndpoint(dealID), ...props },
    ],
    fetchLinkedOutletsList,
    { enabled }
  );

export type DealOutletsPayload = OutletSummaryPayload & TableSettingsType;

type DealOutletsType = Pick<OutletType, 'id' | 'name' | 'status'>;

type DealLinkedOutletsType = {
  id: number;
  outlet: DealOutletsType;
};

type DealOutletsResponseType = {
  outlet_eagle_activities: DealLinkedOutletsType[];
  count: number;
};

const mutateUnlinkOutlet = ({
  eagleID,
  dealID,
}: {
  eagleID: number;
  dealID: number;
}) => http.delete(outletEagleWithIDEndpoint(eagleID, dealID));

export const useMutateUnlinkOutlet = () => {
  const queryClient = useQueryClient();

  return useMutation(mutateUnlinkOutlet, {
    onSuccess: () => {
      queryClient.invalidateQueries(fetchLinkedOutletsCacheKey);
    },
  });
};
