import React, { useMemo } from 'react';
import { TableSettingsType } from '../../types/configTypes';
import { searchFilterDropdownHOC } from '../SearchFilterDropdown/SearchFilterDropdown';

export const FilterDropdown = (
  setTableConfig: React.Dispatch<React.SetStateAction<TableSettingsType<{}>>>,
  placeholder?: string,
  // todo - remove setKeyword and related logic, refactor respective logic on NaturalOfBussiness
  setKeyword?: (text: string) => void
  // end todo
) =>
  useMemo(
    () =>
      searchFilterDropdownHOC({
        setKeyword,
        onOk: () =>
          setTableConfig((state) => {
            return { ...state, page: 1 };
          }),
        placeholder,
      }),
    [setKeyword, setTableConfig, placeholder]
  );
