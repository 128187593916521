import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import Form from '../../components/common/form/Form';
import SubmitButton from '../../components/common/form/SubmitButton';
import { Input } from '../../components/common/form/Input';
import http from '../../services/http';
import { PasswordRecoveryType } from './index';
import style from '../ForgotPasswordFormView/style.module.css';
import { FormikHelpers } from 'formik';

type PasswordResetFormProps = {
  email: string;
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
};

const initialValues: PasswordRecoveryType = { password: '', otp: '' };

const formSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])/,
      'Must include at least 1 uppercase and lowercase characters, 1 number, and 1 symbol.'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password does not match'),
  otp: Yup.string().length(6, 'Must be 6 characters').required('Required'),
});

const PasswordResetForm = ({ email, setErrorText }: PasswordResetFormProps) => {
  const navigate = useNavigate();

  const handleSubmit = (
    values: PasswordRecoveryType,
    { setSubmitting }: FormikHelpers<PasswordRecoveryType>
  ) =>
    http
      .put('/v1/password/reset', {
        email: { email }.email,
        new_password: values.password,
        otp: values.otp,
      })
      .then(() => navigate('/login', { replace: true }))
      .catch((error: any) => setErrorText(error.response.data.error))
      .finally(() => setSubmitting(false));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Input
        name="password"
        label="New Password"
        placeholder="password"
        type={'password'}
        allowClear
        bordered
      />
      <Input
        name="confirmPassword"
        label="Confirm Password"
        placeholder="password"
        type={'password'}
        allowClear
        bordered
      />
      <Input name="otp" label="OTP" placeholder="000000" allowClear bordered />
      <div className={style.actionButton}>
        <SubmitButton disableIfNotValid>
          {(_, isSubmitting) =>
            isSubmitting ? 'Resetting Password' : 'Reset Password'
          }
        </SubmitButton>
      </div>
    </Form>
  );
};

export default PasswordResetForm;
