import { useState } from 'react';
import { Button, Checkbox, Divider, Message, Pagination, Tag } from 'fave-ui';
import Container from '../../../components/Container/Container';
import { Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  DealOutlet,
  useFetchAvailableOutlets,
  useMutateDealOutletLink,
} from '../../../services/OutletSettings';
import clsx from 'clsx';

import style from './style.module.css';
import { Minus, Plus } from 'phosphor-react';
import { SearchBarExpandable } from '../../../components/common';
import NoDataFound from '../../../components/NoDataFound';
import { useElementIdContext } from '..';
import Banner from '../../../components/Banner/Banner';

const { Title } = Typography;

const pageSize = 20;

const OutletLinker = ({
  dealID,
  enableViewOutletsList,
  enableLinkOutlets,
}: {
  dealID: number;
  enableViewOutletsList: boolean;
  enableLinkOutlets: boolean;
}) => {
  const [page, setPage] = useState(1);
  const [outletFilter, setOutletFilter] = useState('');

  const { isFetching, data: availableOutlets } = useFetchAvailableOutlets({
    dealID,
    filter: `id_or_name=${outletFilter}`,
    page,
    pageSize,
    enabled: enableViewOutletsList,
  });

  const { divOutletList, divOutletForm } = useElementIdContext();

  const { mutate: saveChanges, isLoading } = useMutateDealOutletLink();

  const [isSelectAll, setSelectAll] = useState(false);

  const [outletsToIncludeObj, setOutletsToIncludeObj] =
    useState<CheckedOutletState>({});

  const [outletsToExcludeObj, setOutletsToExcludeObj] =
    useState<CheckedOutletState>({});

  const disabledLinkOutlets = !enableLinkOutlets;

  const reset = (resetSelectAll?: boolean) => {
    setOutletsToIncludeObj({});
    setOutletsToExcludeObj({});

    if (resetSelectAll === true) setSelectAll(false);
  };

  const handleSelectAllChange = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;

    setSelectAll(checked);

    if (checked)
      setOutletsToIncludeObj({
        0: {
          id: 0,
          name: 'All Outlets',
          status: 'active',
        },
      });
    else reset();
  };

  const handleOutletCheck = (
    isCheck: boolean,
    outlet: DealOutlet,
    isSelectAll: boolean
  ) => {
    const setStateFunction = (state: CheckedOutletState) => {
      const newState = { ...state };
      newState[outlet.id] = isCheck ? outlet : undefined;

      return newState;
    };

    if (isSelectAll) setOutletsToExcludeObj(setStateFunction);
    else setOutletsToIncludeObj(setStateFunction);
  };

  const handleOutletUncheck = (outlet: DealOutlet) =>
    handleOutletCheck(false, outlet, isSelectAll);

  const outletsToInclude = Object.values(outletsToIncludeObj).filter(
    (outlet) => outlet !== undefined
  ) as DealOutlet[];

  const outletsToExclude = Object.values(outletsToExcludeObj).filter(
    (outlet) => outlet !== undefined
  ) as DealOutlet[];

  const hasPendingChanges =
    outletsToInclude.length > 0 || outletsToExclude.length > 0;

  const handleSaveClick = () => {
    const outlets = isSelectAll ? outletsToExclude : outletsToInclude;

    saveChanges(
      {
        dealID,
        all_outlets: isSelectAll,
        outlet_ids: outlets.map((outlet) => outlet.id),
      },
      {
        onSuccess: () => {
          Message.success(
            {
              content: `${
                isSelectAll ? 'All' : outlets.length
              } outlet(s) are linking...`,
            },
            5
          );

          reset(true);
        },
      }
    );
  };

  return (
    <Spin spinning={isFetching}>
      {availableOutlets && (
        <Container className={style.container}>
          <div data-id={divOutletList} className={style.listing}>
            <Title level={5}>Outlets</Title>

            <SearchBarExpandable
              className={style.search}
              placeholder={'search here'}
              alwaysOpen
              alwaysShowBorder
              onChange={setOutletFilter}
            />

            {availableOutlets.outlets.length === 0 ? (
              <NoDataFound />
            ) : (
              <div className={style.outlets}>
                {availableOutlets.outlets.map((outlet) => {
                  const selected =
                    outletsToIncludeObj[outlet.id] !== undefined ||
                    outletsToExcludeObj[outlet.id] !== undefined;

                  return (
                    <AvailableOutletItem
                      key={outlet.id}
                      outlet={outlet}
                      isChecked={selected}
                      disabled={!enableLinkOutlets}
                      onCheck={() =>
                        handleOutletCheck(true, outlet, isSelectAll)
                      }
                    />
                  );
                })}
              </div>
            )}

            <Divider className={style.divider} />

            <div className={style.pager}>
              <Pagination
                simple
                current={page}
                pageSize={pageSize}
                total={availableOutlets?.count}
                onChange={(page) => setPage(page)}
              />
            </div>
          </div>

          <Divider className={style.formDivider} type="vertical" />

          <div data-id={divOutletForm} className={style.form}>
            <Title level={5}>Action</Title>

            <Checkbox
              checked={isSelectAll}
              disabled={disabledLinkOutlets}
              onChange={handleSelectAllChange}
            >
              Select All Outlets
            </Checkbox>

            <ActionPanel
              isAllOutlets={isSelectAll}
              outlets={outletsToInclude}
              onClose={handleOutletUncheck}
            />

            {isSelectAll && (
              <ActionPanel
                isExclude
                outlets={outletsToExclude}
                onClose={handleOutletUncheck}
              />
            )}

            <div className={style.submit}>
              <Banner>Changes might take a few minutes to take effect</Banner>

              <Button
                type="primary"
                className={style.submitBtn}
                disabled={
                  !hasPendingChanges || isLoading || disabledLinkOutlets
                }
                onClick={handleSaveClick}
              >
                {isLoading ? 'Updating...' : 'Update Changes'}
              </Button>
            </div>
          </div>
        </Container>
      )}
    </Spin>
  );
};

const ActionPanel = ({
  isAllOutlets,
  isExclude,
  outlets,
  onClose,
}: ActionPanelProps) => {
  return (
    <>
      <Typography.Text className={style.label}>
        {isExclude ? 'Exclude:' : 'Include:'}
      </Typography.Text>

      <div
        className={clsx(isExclude ? style.exclude : style.include, {
          [style.allOutlets]: isAllOutlets,
        })}
      >
        {outlets.map((outlet) => (
          <OutletLinkItem
            key={outlet.id}
            isExclude={isExclude}
            outlet={outlet}
            onClose={() => onClose(outlet)}
          />
        ))}
      </div>
    </>
  );
};

const AvailableOutletItem = ({
  outlet,
  isChecked,
  onCheck,
  disabled,
}: AvailableOutletItemProps) => {
  const text = `${outlet.id} - ${outlet.name}`;

  return (
    // Add style.ellipsize to enable ellipsis
    <div title={text} className={style.availableItem}>
      {outlet.status === 'inactive' ? (
        <Typography className={clsx(style.inactive)}>{text}</Typography>
      ) : (
        <Checkbox
          disabled={isChecked || disabled}
          checked={isChecked}
          onChange={onCheck}
        >
          {text}
        </Checkbox>
      )}
    </div>
  );
};

const OutletLinkItem = ({
  isExclude,
  outlet,
  onClose,
}: OutletLinkItemProps) => {
  const isSelectAll = outlet.id === 0;

  const text =
    !isExclude && outlet.id > 0
      ? `${outlet.id} - ${outlet.name}`
      : `${outlet.name}`;

  return (
    // Add style.ellipsize to enable ellipsis
    <Tag
      className={style.linkedItem}
      color={isExclude ? 'red' : 'green'}
      closable={!isSelectAll}
      onClose={onClose}
    >
      {isExclude ? <Minus /> : <Plus />}
      &nbsp;
      <span title={text} className={style.linkedItemText}>
        {text}
      </span>
    </Tag>
  );
};

type ActionPanelProps = {
  isAllOutlets?: boolean;
  isExclude?: boolean;
  outlets: DealOutlet[];
  onClose: (outlet: DealOutlet) => void;
};

type AvailableOutletItemProps = {
  isChecked: boolean;
  outlet: DealOutlet;
  onCheck: () => void;
  disabled: boolean;
};

type OutletLinkItemProps = {
  isExclude?: boolean;
  outlet: DealOutlet;
  onClose: () => void;
};

type CheckedOutletState = {
  [key: number | string]: DealOutlet | undefined;
};

export default OutletLinker;
