import {
  BreadcrumbParamsType,
  useBreadCrumbsContext,
} from '../contexts/BreadCrumbsContext';
import useOnceCallback from './useOnceCallback';

const useSetBreadCrumbParams = (
  data: BreadcrumbParamsType[],
  // run function only if true
  enabled: boolean
) => {
  const { setBreadcrumbParams } = useBreadCrumbsContext();

  useOnceCallback(() => setBreadcrumbParams(data), enabled);
};

export default useSetBreadCrumbParams;
