import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { OutletStatusType, CampaignStatusType, UpdateStatusType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';
import { AxiosResponse, AxiosError } from 'axios';

const endpoint = '/v1/arcade/game_campaigns';
export const gamesListingCacheKey = 'fetchArcadeGamesListing';

export type GamesListingResponseType = {
  game_campaigns: GamesListingItem[];
  count: number;
};

export type GamesSettingResponseType = {
  game_settings: GamesSettingItem[];
};

export type GamesListingItem = {
  id: number;
  campaign_name: string;
  game_type: string;
  ticket_to_play: number;
  daily_play_limit: number;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType;
  start_date?: string;
  end_date?: string;
};

export type GamesSettingItem = {
  order: number;
  game_type: string;
  displayed?: boolean;
}
export type GamesDataItem = {
  id: number;
  campaign_name: string;
  game_type: string;
  description?:string;
  subtitle?: string;
  ticket_to_play: number;
  daily_play_limit: number;
  status?: OutletStatusType | CampaignStatusType;
  background_image_url?: string;
  thumbnail_image_url?: string;
  rewards: Reward[];
  page?: number;
  created_at?: string;
};

export type GameOptionsItem = {
  game_types: string[];
  page?: number;
}

const fetchGamesListing = createFetchAPI<
  TableConfigType,
  GamesListingResponseType
>(endpoint);

const fetchGamesSetting = createFetchAPI<
  TableConfigType,
  GamesSettingResponseType
>(`${endpoint}/settings`);

const fetchGameListingData = createFetchAPI<
  TableConfigType,
  GamesDataItem
>();

const fetchGameOptionsData = createFetchAPI<
  TableConfigType,
  GameOptionsItem
>();

export const useFetchGamesListing = (props: TableConfigType) =>
  useQuery<GamesListingResponseType>(
    [gamesListingCacheKey, { ...props }],
    fetchGamesListing,
    { staleTime: 300_000 } // 5min
  );
export const useFetchGamesSetting = (props: TableConfigType) =>
  useQuery<GamesSettingResponseType>(
    ['fetchArcadeGamesSetting', { ...props }],
    fetchGamesSetting,
    { staleTime: 300_000 } // 5min
  );

export const useFetchGameListingData = ({ id }: { id?: number | string }) =>
  useQuery<GamesDataItem>(
    ['fetchArcadeGameListingData', { url: `${endpoint}/${id}` }],
    fetchGameListingData,
    { enabled: !!id }
  );

export const useFetchGameOptions = () =>
  useQuery<GameOptionsItem>(
    ['fetchArcadeGameOptions', { url: `${endpoint}/options` }],
    fetchGameOptionsData,
    { staleTime: 300_000 }
  );

export type AddCampaignPayload = { formData: FormData };

export type Reward = {
  no_of_token: number;
  reward_percentage: number;
}

export const addNewCampaign = (formData: FormData) =>
  http.post<AddCampaignPayload>(endpoint, formData);

export function useAddCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddCampaignPayload) => addNewCampaign(payload.formData),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(gamesListingCacheKey);
      }
    }
  );
}

export type UpdateCampaignPayload = {
  status_event: string;
  id: number | string;
  confirm?: boolean;
}

export type UpdateCampaignStatusError = {
  code: number;
  error: string;
  error_details: {
    conflict_campaigns: ConflictCampaign[];
  };
}

export type ConflictCampaign = {
  id: number | string;
  campaign_name: string;
}

export const updateCampaignStatus = (payload: UpdateCampaignPayload) =>
  http.put<UpdateCampaignPayload>(
    `${endpoint}/${payload.id}/update_status`,
    payload
  );

export function useUpdateCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse,
    AxiosError<UpdateCampaignStatusError>,
    UpdateCampaignPayload
  >(
    async (payload: UpdateCampaignPayload) => updateCampaignStatus(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(gamesListingCacheKey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  );
}

export const updateGamesOrderSetting = (payload: GamesSettingItem) =>
  http.put<UpdateCampaignPayload>(
    `${endpoint}/update_order_setting`,
    payload
  );

export function useUpdateGamesMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: GamesSettingItem) => updateGamesOrderSetting(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('fetchArcadeGamesSetting');
      },
    }
  );
}
