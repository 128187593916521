import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ChangeHistoryTable from '../../../components/ChangeHistoryTable';
import { PageHeaderWithBackButton } from '../../../components/common/PageHeaderWithBackButton';
import {
  FetchNatureOfBusinessDataResponse,
  useFetchNatureOfBusinessData,
  mutateNatureOfBusiness,
  UpdateOrCreateNatureOfBusinessError,
} from '../../../services/NatureOfBusiness/useFetchNatureOfBusiness';
import { EnabledStatusType } from '../../../types/dataTypes';
import * as Yup from 'yup';
import style from '../style.module.css';
import { Input } from '../../../components/common/form/Input';
import Form from '../../../components/common/form/Form';
import { Row, Spin } from 'antd';
import { Select } from '../../../components/common/form/Select';
import SubmitButton from '../../../components/common/form/SubmitButton';
import { Message } from 'fave-ui';
import { AxiosError } from 'axios';
import { PageHeaderWithSpace } from '../../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
});

const statusOptions = [
  {
    label: 'Enabled',
    key: '1',
    value: `enabled`,
  },
  {
    label: 'Disabled',
    key: '2',
    value: `disabled`,
  },
];

export const CreateNatureOfBusinessView: React.FC = () => {
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : '';
  const isUpdateNatureOfBusiness = !!parsedID;
  const navigate = useNavigate();

  const { data: fetchNatureOfBusinessData, isFetching } =
    useFetchNatureOfBusinessData(
      {
        id: parsedID,
      },
      isUpdateNatureOfBusiness
    );

  const initialFormValues: FetchNatureOfBusinessDataResponse = {
    id: fetchNatureOfBusinessData?.id as number,
    name: fetchNatureOfBusinessData?.name as string,
    status: fetchNatureOfBusinessData?.status as EnabledStatusType,
  };

  const onSuccessSubmitForm = (isUpdate: boolean) => {
    if (isUpdate) {
      Message.success({
        content: 'Successfully updated Nature of Business.',
      });
    } else {
      Message.success({
        content: 'Successfully created Nature of Business.',
      });
      navigate('/settings/nature_of_business');
    }
  };

  const onErrorSubmitForm = (
    err: AxiosError<UpdateOrCreateNatureOfBusinessError>
  ) =>
    Message.error({
      content: err.response?.data.error,
    });

  const onSubmitForm = (values: FetchNatureOfBusinessDataResponse) =>
    mutateNatureOfBusiness(
      values.id ? [values] : values,
      () => onSuccessSubmitForm(!!values.id),
      onErrorSubmitForm
    );

  return (
    <div>
      <PageHeaderWithBackButton
        title={`${parsedID ? 'Update' : 'Create'} Nature of Business`}
      />
      <Spin spinning={isFetching}>
        <div className={style.formBackground}>
          <Form
            initialValues={initialFormValues}
            onSubmit={onSubmitForm}
            validationSchema={formSchema}
            validateOnMount
            validateOnChange
          >
            <Input
              name={'name'}
              label={'Name'}
              className={style.input}
              placeholder="Nature of business"
              allowClear
              bordered
            />
            <Select
              name={'status'}
              label={'Status'}
              placeholder="Status"
              options={statusOptions}
            />
            <div className={style.actionButton}>
              <SubmitButton disableIfNotValid className={style.submitButton}>
                {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Submit')}
              </SubmitButton>
            </div>
          </Form>
        </div>
      </Spin>

      {isUpdateNatureOfBusiness && (
        <div>
          <Row>
            <PageHeaderWithSpace title={'Change History'} />
          </Row>
          <ChangeHistoryTable
            item_type={'NatureOfBusiness'}
            item_id={parsedID}
          />
        </div>
      )}
    </div>
  );
};
