import { useQuery } from 'react-query';
import { CompanyOutletScopePayloadType } from '../types/configTypes';
import { OutletType } from '../types/dataTypes';
import { createFetchAPI } from './http';

type FetchCompanyOutletsResponseType = {
  outlets: OutletType[];
};

const fetchCompanyOutlets = createFetchAPI<
  CompanyOutletScopePayloadType,
  FetchCompanyOutletsResponseType
>('/v1/companies/outlets');

export const useFetchCompanyOutlets = (props: CompanyOutletScopePayloadType) =>
  useQuery<FetchCompanyOutletsResponseType>(
    ['fetchCompanyOutlets', { ...props }],
    fetchCompanyOutlets
  );
