import {
  createSearchParams,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import AppLayout from './components/AppLayout';

import MyMerchantView from './views/MyMerchantView';
import MyMerchantSearchView from './views/MyMerchantSearchView';
import ForgotPasswordFormView from './views/ForgotPasswordFormView';
import LoginFormView from './views/LoginFormView';
import { Empty } from 'fave-ui';
import {
  RedirectToAuthenticatedPagesView,
  RedirectToLoginPageView,
} from './views/RedirectView/RedirectView';
import { ProductDashboardView } from './views/ProductDashboardView/ProductDashboardView';
import { NatureOfBusinessView } from './views/NatureOfBusinessView';
import { CreateNatureOfBusinessView } from './views/NatureOfBusinessView/CreateNatureOfBusiness';
import { MasterDashboardView } from './views/MasterDashboardView';
import MasterContractView from './views/MasterDashboardView/MasterContractView';
import PaymentModeConfigurationFormView from './views/PaymentModeView';
import { useTenantContext } from './contexts/TenantContext';
import ApplicationFeeView from './views/PaymentModeView/ApplicationFee';
import ApplicationPaymentModeView from './views/PaymentModeView/ApplicationPaymentMode';
import CampaignManagementView from './views/CampaignManagementView';
import CampaignChangeLogView from './views/CampaignManagementView/CampaignChangeLogView';
import CreateUpdateIPPView from './views/IPPView/CreateUpdateIPPView';
import IPPConfigurationView from './views/IPPView/IPPConfigurationView';
import useOnceCallback from './hooks/useOnceCallback';
import OutletSettingsView from './views/OutletSettings';
import TicketCenterView from './views/FaveArcadeView/TicketCenterView';
import CreateNewCampaignView from './views/FaveArcadeView/TicketCenterView/CreateNewCampaignView';
import TicketChangelogView from './views/FaveArcadeView/TicketCenterView/TicketChangelogView';
import TicketDetailsView from './views/FaveArcadeView/TicketCenterView/TicketDetailsView';
import GamesCenterView from './views/FaveArcadeView/GamesCenterView';
import CreateNewGameCampaignView from './views/FaveArcadeView/GamesCenterView/CreateNewGameCampaign';
import GameChangelogView from './views/FaveArcadeView/GamesCenterView/GameCampaignChangelogView';
import GameDetailsView from './views/FaveArcadeView/GamesCenterView/GameDetailsView';
import PrizeCenterView from './views/FaveArcadeView/PrizeCatalogView';
import CreateNewPrizeView from './views/FaveArcadeView/PrizeCatalogView/CreateNewPrizeView';
import PrizeCampaignChangelogView from './views/FaveArcadeView/PrizeCatalogView/PrizeCampaignChangelogView';
import PrizeDetailsView from './views/FaveArcadeView/PrizeCatalogView/PrizeDetailsView';

import MySubmissionView from './views/MySubmissionView';
import FavePaySubmissionsDetailsView from './views/MySubmissionView/FavepayTab/FavePaySubmissionsDetailsView';
import DealsSubmissionsDetailsView from './views/MySubmissionView/DealsTab/DealsDetailsView';
import ECardSubmissionsDetailsView from './views/MySubmissionView/ECardsTab/ECardDetailsView';
import InstallationPaymentPlanDetailsView from './views/MySubmissionView/InstalmentPaymentPlanTab/InstallationPaymentPlanDetailsView';
import MakerCheckerView from './views/MakerCheckerView';
import CashbackCampaignsView from './views/CashbackCampaignsView';
import GroupFormView from './views/MakerCheckerView/MakerCheckerGroup/GroupFormView';
import NewFaveCashbackCampaign from './views/CashbackCampaignsView/FaveCashbackView/CreateNewCampaign';
import CashbackCampaignChangeLogView from './views/CashbackCampaignsView/FaveCashbackView/ChangelogView';
import NewFLPCashbackCampaign from './views/CashbackCampaignsView/FLPCashbackView/CreateNewCampaign';
import EditFLPCashbackCampaign from './views/CashbackCampaignsView/FLPCashbackView/EditFLPCashbackCampaign';
import EditFaveCashbackCampaign from './views/CashbackCampaignsView/FaveCashbackView/EditFaveCashbackCampaign';

const WithIdParamValidation = ({ Component }: { Component: JSX.Element }) => {
  const data = useParams();

  const idParamsArray = Object.keys(data);
  const validIDParamsArray = idParamsArray.filter((item) =>
    data ? parseInt(data[item] as string) > 0 : ''
  );
  const isIDValid = idParamsArray.length === validIDParamsArray.length;

  return isIDValid ? (
    Component
  ) : (
    <Empty
      emptyType={'cant-find-anything'}
      customTitle={'Error. Invalid ID detected.'}
      customDescription={'Please enter valid ID to see page content.'}
    />
  );
};

export const AdminToolRoutes = () => {
  const [queryString] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isID } = useTenantContext();

  const queryStringObject = Object.fromEntries(queryString);
  const { tenant_code, ...restQueryString } = queryStringObject;
  const shouldRedirect = !!(tenant_code || restQueryString);

  useOnceCallback(() => {
    const redirectLinkWithoutTenantCode = `${location.pathname
      }?${createSearchParams(restQueryString)}`;

    navigate(redirectLinkWithoutTenantCode, { replace: true });
  }, shouldRedirect);

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <RedirectToLoginPageView>
            <AppLayout>
              <Outlet />
            </AppLayout>
          </RedirectToLoginPageView>
        }
      >
        <Route
          path={'/'}
          caseSensitive
          element={
            <Navigate to={'/admin/merchant_connect/my_merchant/merchants'} />
          }
        />
        <Route path="operation_dashboard" element={<Outlet />}>
          <Route path="master_dashboard" element={<Outlet />}>
            <Route index element={<MasterDashboardView />} />
            <Route
              path="company/:company_id/mc_submission/:mc_id"
              element={
                <WithIdParamValidation Component={<MasterContractView />} />
              }
            />
          </Route>
        </Route>
        <Route path="admin" element={<Outlet />}>
          <Route index />
          <Route path="maker_checker_dashboard" element={<Outlet />}>
            <Route index element={<MakerCheckerView />} />
            <Route path="group" element={<Outlet />}>
              <Route index element={<GroupFormView />} />
              <Route path=":id" element={<GroupFormView />} />
            </Route>
          </Route>
          <Route path="merchant_connect" element={<Outlet />}>
            <Route index />
            <Route path="my_merchant" element={<Outlet />}>
              <Route index />
              <Route path="merchants" element={<Outlet />}>
                <Route
                  index
                  element={
                    queryString.get('query') ? (
                      <MyMerchantSearchView />
                    ) : (
                      <MyMerchantView />
                    )
                  }
                />
                <Route path=":id" element={<Outlet />}>
                  <Route index />
                  <Route
                    path="product_dashboard"
                    element={<ProductDashboardView />}
                  />
                  {!isID && (
                    <>
                      <Route path="payment_mode">
                        <Route index />
                        <Route
                          path=":mode"
                          element={<PaymentModeConfigurationFormView />}
                        />
                      </Route>
                      <Route path="application_fee">
                        <Route index />
                        <Route path="edit" element={<ApplicationFeeView />} />
                      </Route>
                    </>
                  )}
                  <Route path="ipp" element={<Outlet />}>
                    <Route path=":mode" element={<CreateUpdateIPPView />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="merchants" element={<Outlet />}>
              <Route path=":id" element={<Outlet />}>
                <Route
                  path="payment_mode/:mode"
                  element={<PaymentModeConfigurationFormView />}
                />
              </Route>
            </Route>
            <Route path="my_submissions" element={<Outlet />}>
              <Route index element={<MySubmissionView />} />
              <Route path="favepay" element={<Outlet />}>
                <Route path=":id" element={<FavePaySubmissionsDetailsView />} />
              </Route>
              <Route path="deals" element={<Outlet />}>
                <Route
                  path=":id"
                  element={<DealsSubmissionsDetailsView />}
                ></Route>
              </Route>
              <Route path="ecards" element={<Outlet />}>
                <Route path=":id" element={<ECardSubmissionsDetailsView />} />
              </Route>
              <Route path="ipp" element={<Outlet />}>
                <Route
                  path=":id"
                  element={<InstallationPaymentPlanDetailsView />}
                ></Route>
              </Route>
            </Route>
          </Route>
          <Route path="partners" element={<Outlet />}>
            <Route path=":id" element={<Outlet />}>
              <Route path="payment_mode" element={<Outlet />}>
                <Route path="edit" element={<ApplicationPaymentModeView />} />
              </Route>
              <Route path="ipp" element={<Outlet />}>
                <Route path="edit" element={<IPPConfigurationView />} />
              </Route>
              <Route path="open_vouchers/:deal_id" element={<Outlet />}>
                <Route
                  path="outlet_settings"
                  element={
                    <WithIdParamValidation Component={<OutletSettingsView />} />
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path="merchandise_and_campaign" element={<Outlet />}>
            <Route path="campaign_management_tool" element={<Outlet />}>
              <Route index element={<CampaignManagementView />} />
              <Route path="changelog/:id" element={<CampaignChangeLogView />} />
            </Route>
            <Route path="cashback_campaigns" element={<Outlet />}>
              <Route index element={<CashbackCampaignsView />} />
              <Route path="new_campaign" element={<NewFaveCashbackCampaign />} />
              <Route path="new_FLP_campaign" element={<NewFLPCashbackCampaign />} />
              <Route path="edit_campaign/:id" element={<EditFaveCashbackCampaign />} />
              <Route path="edit_FLP_campaign/:id" element={<EditFLPCashbackCampaign />} />
              <Route path="changelog/:id" element={<CashbackCampaignChangeLogView />} />
            </Route>
          </Route>
        </Route>
        <Route path="settings" element={<Outlet />}>
          <Route path="nature_of_business" element={<Outlet />}>
            <Route index element={<NatureOfBusinessView />} />
            <Route path="new" element={<CreateNatureOfBusinessView />} />
            <Route path=":id" element={<CreateNatureOfBusinessView />} />
          </Route>
        </Route>
        <Route path="fave_arcade" element={<Outlet />}>
          <Route path="ticket_center" element={<Outlet />}>
            <Route index element={<TicketCenterView />} />
            <Route path="new" element={<CreateNewCampaignView />} />
            <Route path="changelog/:id" element={<TicketChangelogView />} />
            <Route path="view_campaign/:id" element={<TicketDetailsView />} />
          </Route>
          <Route path="games_center" element={<Outlet />}>
            <Route index element={<GamesCenterView />} />
            <Route path="new" element={<CreateNewGameCampaignView />} />
            <Route path="changelog/:id" element={<GameChangelogView />} />
            <Route path="view_campaign/:id" element={<GameDetailsView />} />
          </Route>
          <Route path="prize_catalog" element={<Outlet />}>
            <Route index element={<PrizeCenterView />} />
            <Route path="new" element={<CreateNewPrizeView />} />
            <Route path="changelog/:id" element={<PrizeCampaignChangelogView />} />
            <Route path="view_campaign/:id" element={<PrizeDetailsView />} />
          </Route>
        </Route>
        {/* no page found */}
        <Route path="*" element={<Empty emptyType={'cant-find-anything'} />} />
      </Route>
      <Route
        path={'/login'}
        element={
          <RedirectToAuthenticatedPagesView>
            <LoginFormView />
          </RedirectToAuthenticatedPagesView>
        }
      />
      <Route path="forgot_password" element={<ForgotPasswordFormView />} />
    </Routes>
  );
};
