import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { Notification } from 'fave-ui';
import Form from '../../components/common/form/Form';
import DatePicker from '../../components/common/form/DatePicker';
import { Select } from '../../components/common/form/Select';
import { Input } from '../../components/common/form/Input';
import SubmitButton from '../../components/common/form/SubmitButton';
import {
  useDealRibbonsList,
  useInternalReportingsList,
} from '../../services/CampaignManagementTool/useLabelAttributes';
import {
  useAddCampaignMutation,
  useEditCampaignMutation,
} from '../../services/CampaignManagementTool/useFetchCampaignListing';
import { formatDate } from '../../utils/utilFunctions';
import { StatusType } from '../../types/dataTypes';

type CampaignFormFields = {
  id?: number;
  name: string;
  deal_ribbon: string;
  internal_reporting: string;
  start_at: string;
  end_at: string;
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

const initialValues: CampaignFormFields = {
  name: '',
  deal_ribbon: '',
  internal_reporting: '',
  start_at: '',
  end_at: '',
};

const CampaignFormSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Please give your camapign a name'),
  deal_ribbon: Yup.string().required('Please select one deal ribbon'),
  internal_reporting: Yup.string().required(
    'Please select one internal reporting'
  ),
  start_at: Yup.date()
    .typeError('Please select a start date')
    .required('Please select a start date'),
  end_at: Yup.date()
    .typeError('Please select a end date')
    .min(Yup.ref('start_at'), "End date can't be before start date")
    .required('Please select a end date'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Campaign Submitted!',
    description:
      'Your new submission has been added too Campaign mangement tool succesfully',
  });
};

const editSuccessNotification = () => {
  Notification.success({
    message: 'Campaign edited',
    description: 'Your edit to the campaign has been saved succesfully',
  });
};

export default function CampaignForm({
  onSuccessCb,
  initialData,
}: {
  onSuccessCb?: () => void;
  initialData?: CampaignFormFields;
}) {
  const { mutateAsync: addNewCampaign } = useAddCampaignMutation();
  const { mutateAsync: editCampaign } = useEditCampaignMutation();

  const { data: dealRibbons, isLoading: _isDealRibbonLoading } =
    useDealRibbonsList();

  const dealRibbonsList =
    dealRibbons?.map(({ name }) => ({
      key: name,
      label: name,
      value: name,
    })) ?? [];

  const { data: internalReports, isLoading: _isInternalReportsLoading } =
    useInternalReportingsList();

  const internalReportsList =
    internalReports?.map(({ name }) => ({
      key: name,
      label: name,
      value: name,
    })) ?? [];

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>
  ) => {
    const payload = {
      product_campaign: {
        ...values,
        start_at: formatDate(values.start_at, 'yyyy-MM-d'),
        end_at: formatDate(values.end_at, 'yyyy-MM-d'),
      },
    };

    try {
      if (initialData) {
        await editCampaign(
          { ...payload },
          {
            onSuccess: () => {
              editSuccessNotification();
              onSuccessCb?.();
            },
          }
        );
      } else {
        await addNewCampaign(
          { ...payload },
          {
            onSuccess: () => {
              successNotification();
              onSuccessCb?.();
            },
          }
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialData ?? initialValues}
      onSubmit={handleSubmit}
      validationSchema={CampaignFormSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="name"
            label="Campaign Name"
            placeholder="sales!"
            allowClear
            bordered // ??? styles need to be fixed from fave-ui
            style={{ marginBottom: 0 }}
            disabled={initialData && initialData.status !== 'Scheduled'}
          />
        </Col>
        <Col span={24}>
          <Select
            name="internal_reporting"
            label="Internal Reporting"
            placeholder="Select one"
            options={internalReportsList}
            // loading={_isInternalReportsLoading} need to fix from fave-ui
            disabled={initialData && initialData.status !== 'Scheduled'}
          />
        </Col>

        <Col span={24}>
          <Select
            name="deal_ribbon"
            label="Deal Ribbon"
            placeholder="Select one"
            options={dealRibbonsList}
            // loading={_isDealRibbonLoading} need to fix from fave-ui
            disabled={initialData && initialData.status !== 'Scheduled'}
          />
        </Col>

        {/* these 2 inputs can be replaced with range-picker */}
        <Col span={24}>
          <DatePicker
            name="start_at"
            label="Start Date"
            allowClear
            bordered
            disabled={initialData && initialData.status !== 'Scheduled'}
          />
        </Col>
        <Col span={24}>
          <DatePicker name="end_at" label="End Date" allowClear bordered />
        </Col>

        <div>
          <SubmitButton disableIfNotValid disableIfNotDirty>
            {(_, isSubmitting) =>
              isSubmitting ? 'Submiting...' : initialData ? 'Save' : 'Submit'
            }
          </SubmitButton>
        </div>
      </Row>
    </Form>
  );
}
