import style from './style.module.css';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useParams } from 'react-router';
import { Card, Col, Statistic } from 'antd';
import { Row } from 'fave-ui';
import ChangeHistoryTable from '../../../../components/ChangeHistoryTable';
import { useFetchPrizeListingData } from '../../../../services/FaveArcade/useFetchPrizesListing';
import { capitalizeFirstLetterEachWord } from '../../../../utils/utilFunctions';


const PrizeCampaignChangelogView = () => {
    const { id } = useParams();
    const parsedID = id ? parseInt(id) : undefined;
    
    const { data: campaignData, isLoading: isCampaignLoading } =
    useFetchPrizeListingData({ id: id });

    const summaryData = campaignData && {
        ...campaignData,
        prize_type: capitalizeFirstLetterEachWord(campaignData.prize_type),
    }

    return (
        <>
            <PageHeaderWithBackButton
                title={`Fave Arcade Prize Details`}
            />
            <Card className={style.card}>
                <Row>
                    <h2 className={style.h2}>{'Details Summary'}</h2>
                </Row>
                <Row className={style.summaryWrapper}>
                    <Col>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="ID"
                                value={summaryData?.id}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Campaign Name"
                                value={summaryData?.campaign_name}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Ticket to Play"
                                value={summaryData?.cost_of_token}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Daily Play Limit"
                                value={summaryData?.max_user_limit}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                    <Col className={style.statsCol}>
                        <Row className={style.statsRow}>
                            <Statistic
                                loading={isCampaignLoading}
                                title="Highlighted"
                                value={summaryData?.is_highlighted?"True":'False'}
                                className={style.statItem}
                            />
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Row className={style.table}>
                {parsedID && (
                    <ChangeHistoryTable
                        item_type={'Arcade::Prize'}
                        item_id={parsedID}
                    />
                )}
            </Row>
        </>
    );
};

export default PrizeCampaignChangelogView;
