import { useQuery, useQueryClient, useMutation } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { OutletStatusType, CampaignStatusType, UpdateStatusType, CashbackValueType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';

const endpoint = '/v1/cashback_campaigns';
export const campaignListingCacheKey = 'fetchFLPCashbackListing';
export const campaignDataCachekey = 'fetchFLPCashbackListingData';

export type FLPCashbackListingResponseType = {
  cashback_campaigns: FLPCashbackListingItem[];
  count: number;
};

export type FLPCashbackListingItem = {
  id: number;
  campaign_type: string;
  campaign_name: string;
  funder_type: string;
  funder_id: number;
  funder_name: string;
  currency: string;
  available_budget_amount_cents: number;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType[];
};

export type FLPCashbackDataItem = {
  id: number | string;
  campaign_type: string;
  campaign_name: string;
  funder_type: string;
  funder_id: number;
  funder_name: string;
  currency: string;
  min_transaction_amount_cents?: number;
  min_monthly_transaction_count?: number;
  cashback_value_type: CashbackValueType;
  cashback_rate?: number;
  cashback_amount_cents?: number;
  max_cashback_amount_cents?: number;
  max_budget_amount_cents: number;
  available_budget_amount_cents: number;
  status: OutletStatusType | CampaignStatusType;
  whitelisted_outlet_ids?: number[];
};

export type FLPCashbackParamsType = TableConfigType & {
  funder_type: string;
}

type FunderType = {
  campaign_type: string;
  value: string
}

type FLPPartner = {
  funder_id: number;
  funder_type: string;
  funder_name: string;
}

export type CashbackCampaignOptions = {
  funder_types: FunderType[];
  currency: string;
}

export type FLPPartnerOptions = {
  flp_partners: FLPPartner[];
  cashback_value_types: CashbackValueType[];
}

export type UpdateFLPCashbackStatus = {
  status_event: UpdateStatusType;
  id: number | string;
}

export type UpdateFLPCashbackWhitelisted = {
  whitelisted_outlet_ids?: (string | number)[];
  id: number | string;
}

export type OptionsParamsType = {
  funder_type: string;
}

export type AddFLPCashbackPayload = {
  campaign_type?: string;
  campaign_name: string;
  funder_type?: string;
  funder_id?: number;
  funder_name?: string;
  min_transaction_amount_cents?: number;
  min_monthly_transaction_count?: number;
  cashback_value_type?: CashbackValueType;
  cashback_rate?: number;
  cashback_amount_cents?: number;
  max_cashback_amount_cents?: number;
  max_budget_amount_cents: number;
  whitelisted_outlet_ids?: string[] | string;
}

const fetchFLPCashbackListing = createFetchAPI<
  FLPCashbackParamsType,
  FLPCashbackListingResponseType
>(endpoint);

export const useFetchFLPCashbackListing = (props: FLPCashbackParamsType) =>
  useQuery<FLPCashbackListingResponseType>(
    [campaignListingCacheKey, { ...props }],
    fetchFLPCashbackListing,
    { staleTime: 300_000 }
  );

const fetchLFPCashbackListingData = createFetchAPI<
  TableConfigType,
  FLPCashbackDataItem
>()

export const useFetchFLPCashbackListingData = ({ id }: { id?: number | string }) =>
  useQuery<FLPCashbackDataItem>(
    [campaignDataCachekey, { url: `${endpoint}/${id}` }],
    fetchLFPCashbackListingData,
    { enabled: !!id }
  );

const fetchCashbackCampaignOptions = createFetchAPI<
  OptionsParamsType,
  CashbackCampaignOptions
>(`${endpoint}/options`);

export const useFetchCashbackCampaignOptions = (props: OptionsParamsType) =>
  useQuery<CashbackCampaignOptions>(
    ['fetchCashbackCampaignOptions', { ...props }],
    fetchCashbackCampaignOptions,
    { staleTime: 300_000 }
  );

const fetchFLPPartnerOptions = createFetchAPI<
  OptionsParamsType,
  FLPPartnerOptions
>(`${endpoint}/options`);

export const useFetchFLPPartnerOptions = (props: OptionsParamsType) =>
  useQuery<FLPPartnerOptions>(
    ['fetchFLPPartnerOptions', { ...props }],
    fetchFLPPartnerOptions,
    { staleTime: 300_000 }
  );

export const addFLPCashbackCampaign = (payload: AddFLPCashbackPayload) =>
  http.post<AddFLPCashbackPayload>(endpoint, payload);

export function useAddFLPCashbackMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddFLPCashbackPayload) => addFLPCashbackCampaign(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
      }
    }
  );
}

export const updateFLPCashbackStatus = (payload: UpdateFLPCashbackStatus) =>
  http.put<UpdateFLPCashbackStatus>(
    `${endpoint}/${payload.id}/update_status`,
    payload
  );

export function useUpdateFLPCashbackMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateFLPCashbackStatus) => updateFLPCashbackStatus(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(campaignDataCachekey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  );
}

const updateFLPCashbackWhitelisted = (payload: UpdateFLPCashbackWhitelisted) =>
  http.put<UpdateFLPCashbackWhitelisted>(
    `${endpoint}/${payload.id}`,
    payload
  );

export const useUpdateFLPWhitelistedMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (payload: UpdateFLPCashbackWhitelisted) => updateFLPCashbackWhitelisted(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(campaignDataCachekey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  )
}
