import { Message } from 'fave-ui';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import FileUpload from '../../../components/common/form/FileUpload';
import RequiredFieldLabel from '../../../components/common/RequiredFieldLabel';
import { showRemoveFileModal } from '../../../helpers/formHelpers';
import { useDeletePaymentModeDocument } from '../../../services/PaymentMode/usePaymentMode';
import {
  ApprovalDocumentsType,
  McApprovalDocumentsType,
} from '../../../types/dataTypes';

type IPPApprovalDocumentFormProps = {
  companyID: number;
  isCreateMode: boolean;
};

const onSuccessDeleteDocument = () =>
  Message.success({
    content: 'File successfully deleted.',
  });

const IPPApprovalDocumentForm: React.FC<IPPApprovalDocumentFormProps> = ({
  companyID: company_id,
  isCreateMode,
}) => {
  const { values } = useFormikContext<ApprovalDocumentsType>();
  const { mutate: deletePaymentModeDocument } = useDeletePaymentModeDocument();

  const fileList = useMemo(
    () =>
      values?.approval_documents?.map((item) => {
        const hasFileURL = !!item.file_url;
        const data = {
          ...item,
        };

        if (hasFileURL) {
          data.name = item.file_url.substring(
            item.file_url.lastIndexOf('/') + 1
          );
        }

        return data;
      }),
    [values.approval_documents]
  );

  return (
    <>
      <RequiredFieldLabel
        text={'Please upload BD manager approved list of tenure & fee'}
      />
      <FileUpload
        name={'approval_documents'}
        fileList={fileList}
        onRemove={(file) =>
          isCreateMode
            ? showRemoveFileModal({ file })
            : showRemoveFileModal({
                file,
                onOk: (resolve, reject) =>
                  (file as unknown as McApprovalDocumentsType).id
                    ? deletePaymentModeDocument(
                        {
                          company_id,
                          approval_document_id: (
                            file as unknown as McApprovalDocumentsType
                          ).id,
                        },
                        {
                          onSuccess: () => {
                            resolve();
                            onSuccessDeleteDocument();
                          },
                          onError: reject,
                        }
                      )
                    : resolve(),
              })
        }
      />
    </>
  );
};

export default IPPApprovalDocumentForm;
