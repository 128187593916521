import { isValid } from 'date-fns';
import { RequestChangesContent, RequestQueryParams } from '../../../services/MakerChecker/useMakerChecker';
import { formatDate } from '../../../utils/utilFunctions';
import { AmendmentType } from '../MakerCheckerRequest';
import { MKTab } from './enum';
import { GroupQueryParams } from '../../../services/MakerChecker/useMakerCheckerGroup';

export const isCheckerTab = (activeTab: MKTab.Checker | MKTab.Maker) => {
  return activeTab === MKTab.Checker;
};

export const initialFilterObject: RequestQueryParams = {
  page: 1,
  limit: 10,
  approval_end_datetime: '',
  approval_start_datetime: '',
  created_end_datetime: '',
  created_start_datetime: '',
  request_list_type: '',
  models: ''
};

export const initialInfosecFilterObject: GroupQueryParams = {
  page: 1,
  limit: 10,
  name: '',
  isAdmin: false,
};

export const distinctArrayValue = (arr: (string | number)[]) =>
  arr.filter((value, index, array) => array.indexOf(value) === index && value !== '');

export const formatReturnValue = (val: string | number | (string | number)[]) => {
  if (!Number.isNaN(Number(val))) return val; // val can be '1' which should considered as number
  if (typeof val === 'string') return isValid(new Date(val)) ? formatDate(val, 'dd-MM-yyyy') : val;
  return val.toString();
}

export const formatRequestChangesToData = (model: RequestChangesContent, mainKey?: string) : AmendmentType[] => {
  return Object.keys(model).map((key) => ({
    input: (mainKey || key).replaceAll('_', ' '),
    original_input: `${mainKey ? key.replaceAll('_', ' ') + ' ' : ''}${model[key][0] ?? ''}`,
    amended_input: `${mainKey ? key.replaceAll('_', ' ') + ' ' : ''}${model[key][1] ?? ''}`,
  }))
}