import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import cookieSet from '../helpers/cookieSet';

export type TenantCodeType = 'id' | 'my' | 'sg';

type TenantContextType = {
  selectedTenant: TenantCodeType;
  switchTenant: (value: TenantCodeType, bool?: boolean) => void;
  resetTenant: () => void;
  isID: boolean;
  isMY: boolean;
  isSG: boolean;
  tenantCurrency: string;
};

export const TenantContext = createContext<TenantContextType>({
  selectedTenant: cookieSet.currentTenant.get(),
  switchTenant: () => ({}),
  resetTenant: () => ({}),
  isID: false,
  isMY: false,
  isSG: false,
  tenantCurrency: '',
});
TenantContext.displayName = 'TenantContext';

export const TenantContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [selectedTenant, setSelectedTenant] = useState(
    cookieSet.currentTenant.get()
  );

  const switchTenant = useCallback((value: TenantCodeType, refresh = true) => {
    setSelectedTenant(value);
    cookieSet.currentTenant.set(value);
    if (refresh) window.location.reload();
  }, []);

  const resetTenant = () => cookieSet.currentTenant.remove();

  const isID = selectedTenant === 'id';
  const isMY = selectedTenant === 'my';
  const isSG = selectedTenant === 'sg';

  const tenantCurrency = isMY ? 'RM' : isSG ? 'SGD' : isID ? 'Rp' : '';

  return (
    <TenantContext.Provider
      value={{
        selectedTenant,
        switchTenant,
        resetTenant,
        isID,
        isMY,
        isSG,
        tenantCurrency,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantContext = () => useContext(TenantContext);
