import React, { memo } from 'react';
import {
  Checkbox as FaveCheckbox,
  CheckboxProps as FaveCheckboxProps,
  FormItem,
} from 'fave-ui';
import { useFormikContext } from 'formik';
import style from './style.module.css';

type CheckboxProps<T> = FaveCheckboxProps & {
  onChangeCheckbox?: (isChecked: boolean, handleChange: () => void) => void;
  valuePath?: string;
  name: keyof T;
};

const handleChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (
    field: string,
    value: boolean,
    shouldValidate?: boolean | undefined
  ) => void,
  path?: string
) => {
  const { name, checked } = e.target;

  setFieldValue(path ? `${path}.${name}` : name, checked);
};

const Checkbox = <T extends {}>({
  disabled,
  name,
  valuePath,
  checked,
}: CheckboxProps<T>) => {
  const { setFieldValue } = useFormikContext<T>();

  const MemoisedCheckbox = memo(() => (
    <FaveCheckbox
      name={name}
      checked={checked}
      onChange={(e) =>
        handleChange(
          e as unknown as React.ChangeEvent<HTMLInputElement>,
          setFieldValue,
          valuePath
        )
      }
      disabled={disabled}
    />
  ));

  MemoisedCheckbox.displayName = 'MemoisedCheckbox';

  return (
    <FormItem className={style.checkbox}>
      <MemoisedCheckbox />
    </FormItem>
  );
};

export default Checkbox;
