import { TabPane, Tabs } from 'fave-ui';
import { BNPLTab } from './BNPLTab/BNPLTab';
import { DealsTab } from './DealsTab';
import { ECardsTab } from './ECardsTab';
import { FavepayTab } from './FavepayTab';
import { PaymentModeTab } from './PaymentModeTab/PaymentModeTab';
import { InstalmentPaymentPlanTab } from './InstalmentPaymentPlanTab';
import { ContractMigrationTab } from './ContractMigrationTab';
import {
  PageHeaderWithSpace,
  SearchBarExpandable,
} from '../../components/common';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
} from '../../helpers/ClevertapKeys';
import useOnceCallback from '../../hooks/useOnceCallback';
import useCleverTap from '../../hooks/useCleverTap';
import { useUserContext } from '../../contexts/UserContext';
import { useEffect } from 'react';
import { toolRedirect } from '../../services/http';
import LockPage from '../../components/LockPage';
import styles from '../MySubmissionView/style.module.css';

const mySubmissionsPreID = (id: string) => `mySubmissions-${id}`;

export const mySubmissionElementIDs = {
  // favepay
  favePayTab: mySubmissionsPreID('favePayTab'),
  favePayCompanyFilterIcon: mySubmissionsPreID('favePayCompanyFilterIcon'),
  favePaySubmissionStatusFilterIcon: mySubmissionsPreID(
    'favePaySubmissionStatusFilterIcon'
  ),
  favePayActionBtn: mySubmissionsPreID('favePayActionBtn'),
  favePayViewBtn: mySubmissionsPreID('favePayViewBtn'),
  favePayEditResubmitBtn: mySubmissionsPreID('favePayEditResubmitBtn'),
  favePayCopyLinkBtn: mySubmissionsPreID('favePayCopyLinkBtn'),
  favePayDownloadContractBtn: mySubmissionsPreID('favePayDownloadContractBtn'),
  // deals
  dealsTab: mySubmissionsPreID('dealsTab'),
  dealsSubmissionStatusFilterIcon: mySubmissionsPreID(
    'dealsSubmissionStatusFilterIcon'
  ),
  dealsActionBtn: mySubmissionsPreID('dealsActionBtn'),
  dealsViewBtn: mySubmissionsPreID('dealsViewBtn'),
  dealsEditResubmitBtn: mySubmissionsPreID('dealsEditResubmitBtn'),
  dealsCopyLinkBtn: mySubmissionsPreID('dealsCopyLinkBtn'),
  dealsDownloadContractBtn: mySubmissionsPreID('dealsDownloadContractBtn'),
  dealsDuplicateBtn: mySubmissionsPreID('dealsDuplicateBtn'),
  dealsCopyIDBtn: mySubmissionsPreID('dealsCopyIDBtn'),
  // ecards
  eCardsTab: mySubmissionsPreID('eCardsTab'),
  eCardsSubmissionStatusFilterIcon: mySubmissionsPreID(
    'eCardsSubmissionStatusFilterIcon'
  ),
  eCardsActionBtn: mySubmissionsPreID('eCardsActionBtn'),
  eCardsViewBtn: mySubmissionsPreID('eCardsViewBtn'),
  eCardsEditResubmitBtn: mySubmissionsPreID('eCardsEditResubmitBtn'),
  eCardsCopyLinkBtn: mySubmissionsPreID('eCardsCopyLinkBtn'),
  eCardsDownloadContractBtn: mySubmissionsPreID('eCardsDownloadContractBtn'),
  eCardsDuplicateBtn: mySubmissionsPreID('eCardsDuplicateBtn'),
  eCardsCopyIDBtn: mySubmissionsPreID('eCardsCopyIDBtn'),
  // bnpl
  bnplTab: mySubmissionsPreID('bnplTab'),
  // payment mode
  paymentModeTab: mySubmissionsPreID('paymentModeTab'),
  // ipp
  ippTab: mySubmissionsPreID('ippTab'),
  ippSortSubmissionDateBtn: mySubmissionsPreID('ippSortSubmissionDateBtn'),
  ippSubmissionStatusFilterIcon: mySubmissionsPreID(
    'ippSubmissionStatusFilterIcon'
  ),
  ippActionBtn: mySubmissionsPreID('ippActionBtn'),
  ippViewBtn: mySubmissionsPreID('ippViewBtn'),
  ippEditResubmitBtn: mySubmissionsPreID('ippEditResubmitBtn'),
  ippCopyLinkBtn: mySubmissionsPreID('ippCopyLinkBtn'),
  ippDownloadContractBtn: mySubmissionsPreID('ippDownloadContractBtn'),
  // contract migration
  contractMigrationTab: mySubmissionsPreID('contractMigrationTab'),
  contractMigrationSortSubmissionDateBtn: mySubmissionsPreID(
    'contractMigrationSortSubmissionDateBtn'
  ),
  contractMigrationSubmissionStatusFilterIcon: mySubmissionsPreID(
    'contractMigrationSubmissionStatusFilterIcon'
  ),
  contractMigrationActionBtn: mySubmissionsPreID('contractMigrationActionBtn'),
  contractMigrationDownloadContractBtn: mySubmissionsPreID(
    'contractMigrationDownloadContractBtn'
  ),
  contractMigrationCancelContractBtn: mySubmissionsPreID(
    'contractMigrationCancelContractBtn'
  ),
};

const MySubmissionView = () => {
  const cleverTap = useCleverTap();
  const navigate = useNavigate();
  const { getPageSettings } = useUserContext();
  const pageSettings = getPageSettings('merchant_connect');
  const enableMerchantConnect = pageSettings?.show;
  const enableMySubmissions = pageSettings?.hello_my_submissions;

  // TODO: temporary fix until we find a better solution
  // to validate routes in Routes.tsx
  useEffect(() => {
    if (enableMerchantConnect)
      if (!enableMySubmissions) {
        toolRedirect('/admin/merchant_connect/my_submissions/favepay');
      }
  }, [enableMerchantConnect, enableMySubmissions]);

  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.MY_SUBMISSION,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    })
  );

  const onTapClickHandler = (key: string) =>
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.PRODUCT_DASHBOARD,
      tapped_on:
        CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_TAP[
          key as keyof typeof CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_TAP
        ],
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    });

  const handleSearch = (value: string) => {
    const params = {
      ...(value && { query: value }),
    };

    navigate(
      `/admin/merchant_connect/my_submissions?${createSearchParams(params)}`
    );
  };

  const productTabs = [
    {
      tab: 'FavePay',
      dataId: mySubmissionElementIDs.favePayTab,
      key: '1',
      children: <FavepayTab />,
    },
    {
      tab: 'Deals',
      dataId: mySubmissionElementIDs.dealsTab,
      key: '2',
      children: <DealsTab />,
    },
    {
      tab: 'eCards',
      dataId: mySubmissionElementIDs.eCardsTab,
      key: '3',
      children: <ECardsTab />,
    },
    {
      tab: 'BNPL',
      dataId: mySubmissionElementIDs.bnplTab,
      key: '4',
      children: <BNPLTab />,
    },
    {
      tab: 'Payment Mode',
      dataId: mySubmissionElementIDs.paymentModeTab,
      key: '5',
      children: <PaymentModeTab />,
    },
    {
      tab: 'Instalment Payment Plan',
      dataId: mySubmissionElementIDs.ippTab,
      key: '6',
      children: <InstalmentPaymentPlanTab />,
    },
    {
      tab: 'Contract Migration',
      dataId: mySubmissionElementIDs.contractMigrationTab,
      key: '7',
      children: <ContractMigrationTab />,
    },
  ];

  return enableMerchantConnect ? (
    <div>
      <PageHeaderWithSpace
        title={'My Submissions'}
        subTitle={'All your submission progress can be tracked here'}
      />
      <Tabs
        tabBarExtraContent={<SearchBarExpandable onSearch={handleSearch} />}
        defaultActiveKey="1"
        onTabClick={(key) => onTapClickHandler(key)}
        className={styles.tabs}
      >
        {productTabs.map(({ tab, key, dataId, ...restItems }) => (
          <TabPane
            {...restItems}
            tab={<span data-id={dataId}>{tab}</span>}
            key={key}
          />
        ))}
      </Tabs>
    </div>
  ) : (
    <LockPage />
  );
};

export default MySubmissionView;
