import { useState } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import { PageHeaderWithSpace } from '../../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col } from 'antd';
import { Empty, FilterIcon, Row, Table, Button, Checkbox, Notification, Modal, Menu, Dropdown } from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import { createOnTableChangeHandler, formatFilter } from '../../../utils/utilFunctions';
import {
  useFetchGamesListing, useFetchGamesSetting,
  GamesSettingItem, GamesListingItem,
  UpdateCampaignPayload, useUpdateCampaignMutation,
  ConflictCampaign, useUpdateGamesMutation
} from '../../../services/FaveArcade/useFetchGamesListing';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { DotsThree, Plus } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../../components/common/SearchBarExpandable/SearchBarExpandable';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import { CampaignStatusType } from '../../../types/dataTypes';
import { DragDropContext, DropResult, DroppableProps } from 'react-beautiful-dnd';
import { DraggableRow, DroppableTableBody } from '../../../components/common/DraggableTable';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const successNotification = (message: string) => {
  Notification.success({
    message: message
  });
};

export type UpdateCampaignStatusError = {
  code: number;
  error: string;
  error_details: {
    conflict_campaigns: ConflictCampaign[];
  };
}

const GamesCenterView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [confirmText, setConfirmText] = useState('');
  const [campaign, setCampaign] = useState<GamesListingItem>();
  const [showModal, setShowModal] = useState(false);
  const [deActivateModal, setDeActivateModal] = useState(false);
  const [confirmCampaign, setConfirmCampaign] = useState<UpdateCampaignPayload>({ status_event: '', id: '' });
  const [conflictCampaigns, setConflictCampaigns] = useState<ConflictCampaign[]>([]);
  const [tableConfig, setTableConfig] = useState<TableSettingsType>(defaultTableConfig);
  const { mutateAsync: updateGamesOrder } = useUpdateGamesMutation();
  const { mutateAsync: updateCampaignStatus } = useUpdateCampaignMutation();
  const navigate = useNavigate();

  const handleUpdateStatus = async (campaign: UpdateCampaignPayload) =>
    await updateCampaignStatus(campaign, {
      onSuccess: (res) => {
        successNotification(res?.data?.message ?? 'Your action is success!');
        setShowModal(false);
        setDeActivateModal(false);
      },
      onError: (error) => {
        if ((error)?.response?.data?.code === 400) {
          setConfirmText(error.response?.data?.error ?? 'Please try again later');
          setConfirmCampaign({ ...confirmCampaign, id: campaign.id, status_event: campaign.status_event, confirm: true });
          setConflictCampaigns(error.response?.data?.error_details?.conflict_campaigns ?? []);
          setShowModal(true);
        }
      }
    });

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (gamesSettingData?.game_settings.length) {
      await updateGamesOrder({
        order: result.destination.index + 1,
        game_type: result.draggableId
      },
        {
          onSuccess: () => successNotification("Games order changed successfully")
        })
    }
  };

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) => formatFilter([{ key: 'status', value: filters.status }])
  );

  const { data: gamesSettingData, isFetching: gamesSettingFetching } = useFetchGamesSetting({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
  });

  const { data: campaignData, isFetching: campaignFetching } = useFetchGamesListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const campaignListingData = campaignData?.game_campaigns?.map((ticket: GamesListingItem) => {
    return {
      ...ticket,
      status: capitalizeFirstLetterEachWord(ticket.status) as CampaignStatusType,
      game_type: capitalizeFirstLetterEachWord(ticket.game_type),
    }
  })

  const settingColumns: ColumnsType<GamesSettingItem> = [
    {
      title: 'Order',
      dataIndex: 'order',
      width: 80,
      render: RenderColumn.rowText,
    },
    {
      title: 'Displayed',
      dataIndex: 'displayed',
      width: 80,
      render: (row: GamesSettingItem) => (
        <Checkbox defaultChecked={row?.displayed} disabled></Checkbox>
      )
    },
    {
      title: 'Game',
      dataIndex: 'game_type',
      width: 200,
      render: RenderColumn.rowText,
    },
  ];

  const gameListingColumns: ColumnsType<GamesListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      render: RenderColumn.rowText,
    },

    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      width: 250,
      render: RenderColumn.rowText,
    },
    {
      title: 'Game',
      dataIndex: 'game_type',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
      ],
      width: 120,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'Cost To Play',
      dataIndex: 'ticket_to_play',
      width: 100,
      render: RenderColumn.rowText,
    },
    {
      title: 'Daily Limit',
      dataIndex: 'daily_play_limit',
      width: 100,
      render: RenderColumn.rowText,
    },
    {
      title: 'Change Log',
      width: 120,
      render: (row: GamesListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (row: GamesListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'view',
            label: 'View',
            onClick: () => navigate(`/fave_arcade/games_center/view_campaign/${row.id}`),
          },
          {
            key: 'editStatus',
            label: row.status === 'Active' ? 'Deactivate' : 'Activate',
            onClick: () => {
              if (row.status === 'Active') {
                setDeActivateModal(true);
                setCampaign(row);
              } else {
                handleUpdateStatus({ id: row.id, status_event: row.status_event, confirm: false })
              }
            },
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={(e) => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <PageHeaderWithSpace
        title={'Games Center Manager'}
        subTitle={''}
      />
      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col span={8}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Table
                size="small"
                rowKey={(row: GamesSettingItem) => row.game_type}
                loading={gamesSettingFetching}
                columns={settingColumns}
                onChange={tableChangeHandler}
                dataSource={gamesSettingData?.game_settings}
                pagination={{
                  hideOnSinglePage: true
                }}
                locale={{
                  emptyText: !gamesSettingFetching && <Empty emptyType="cant-find-anything" />,
                }}
                components={{
                  body: {
                    row: gamesSettingData?.game_settings.length ? ((props, index) => {
                      return <DraggableRow index={index} {...props} />;
                    }) : undefined,
                    wrapper: (props: DroppableProps) => <DroppableTableBody {...props} droppableId={'gameSettingTable'} />
                  },
                }}
                onRow={(record, index) => ({
                  index,
                  record,
                  onClick: (e) => e.preventDefault(),
                })}
              />
            </DragDropContext>
          </Col>

          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={(keyword) => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => navigate('/fave_arcade/games_center/new')}
                type="primary"
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Table
          size="large"
          rowKey={(row) => row.id}
          loading={campaignFetching}
          columns={gameListingColumns}
          onChange={tableChangeHandler}
          dataSource={campaignListingData}
          className={style.table}
          pagination={{
            total: campaignData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !campaignFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Modal
        title="Warning!"
        visible={showModal}
        okText="Continue"
        cancelText="Back"
        onOk={() => handleUpdateStatus(confirmCampaign)}
        onCancel={() => setShowModal(false)}
      >
        <p>{confirmText}</p>
        {conflictCampaigns?.map(campaign => {
          return <p key={campaign.id}>ID:{campaign.id}-{campaign.campaign_name}</p>
        })}
      </Modal>
      <Modal title={`Warning!`}
        visible={deActivateModal}
        okText="Yes, proceed"
        cancelText="Cancel"
        onOk={() => campaign && handleUpdateStatus({ id: campaign.id, status_event: campaign.status_event, confirm: false })}
        onCancel={() => setDeActivateModal(false)}
      >
        You are about to deactivate {campaign?.campaign_name}, proceed?
      </Modal>
    </>
  );
};

export default GamesCenterView;
