import { ReactNode } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { Button, ButtonProps } from 'fave-ui';

export type SubmitButtonProps<T> = Pick<
  ButtonProps,
  'icon' | 'iconPlacement' | 'size'
> & {
  isDisabled?:
    | boolean
    | ((values: T, context: FormikContextType<T>) => boolean);
  disableIfNotValid: boolean;
  disableIfNotDirty?: boolean;
  children?: ReactNode | ((values: T, isSubmitting: boolean) => ReactNode);
  className?: string;
};

const SubmitButton = <T extends {}>({
  children,
  isDisabled,
  disableIfNotValid,
  className,
  disableIfNotDirty,
  size = 'large',
  ...restProps
}: SubmitButtonProps<T>) => {
  const context = useFormikContext<T>();

  const { values, handleSubmit, isValid, isSubmitting, dirty } = context;

  const conditionallyDisabled = isDisabled
    ? typeof isDisabled === 'boolean'
      ? isDisabled
      : isDisabled(values, context)
    : false;

  const disabled =
    isSubmitting ||
    conditionallyDisabled ||
    (disableIfNotValid && !isValid) ||
    (disableIfNotDirty && !dirty);

  return (
    <Button
      {...restProps}
      type="primary"
      disabled={disabled}
      onClick={(_event) => handleSubmit()}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      size={size}
      className={className}
    >
      {children &&
        (typeof children === 'function'
          ? children(values, isSubmitting)
          : children)}
    </Button>
  );
};

export default SubmitButton;
