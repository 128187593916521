import { useState } from 'react';
import style from './style.module.css';
import { PageHeaderWithSpace } from '../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col, Statistic } from 'antd';
import {
  Dropdown,
  Empty,
  FilterIcon,
  Menu,
  Row,
  Table,
  Button,
  Drawer,
  ModalFunctions,
  Notification,
} from 'fave-ui';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  createOnTableChangeHandler,
  formatFilter,
} from '../../utils/utilFunctions';
import {
  CampaignListingItem,
  useDeleteCampaignMutation,
  useFetchCampaignListing,
} from '../../services/CampaignManagementTool/useFetchCampaignListing';
import { useCampaignSummary } from '../../services/CampaignManagementTool/useCampaignSummary';
import { defaultTableConfig } from '../../helpers/defaults';
import { TableSettingsType } from '../../types/configTypes';
import { PencilSimpleLine, Trash, DotsThree, Plus } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../components/common/SearchBarExpandable/SearchBarExpandable';
import CampaignForm from './CampaignForm';

const deleteSuccessNotification = (item?: CampaignListingItem) => {
  Notification.success({
    message: 'Campaign deleted',
    description: `Campaign ${item?.campaign_name} successfully deleted`,
  });
};

const CampaignManagementView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editItem, setEditItem] = useState<CampaignListingItem>();

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) => formatFilter([{ key: 'status', value: filters.status }])
  );

  const { data: summaryData, isLoading: isSummaryLoading } =
    useCampaignSummary();

  const { mutateAsync: deleteCampaign } = useDeleteCampaignMutation();

  const { data: campaignListingData, isFetching } = useFetchCampaignListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const deleteCampaignModal = (item: CampaignListingItem) => {
    ModalFunctions.confirm({
      title: 'Delete Campaign?',
      icon: <Trash size={24} />,
      // why do we have to pass `hasIcon: true`? please check on fave-ui
      hasIcon: true,
      content: (
        <div>
          <span className={style.modalPrimaryText}>{item.campaign_name}</span>
          <div>
            Are you sure to permanently delete the campaign titled above?
          </div>
        </div>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => handleDeleteCampaign(item),
    });
  };

  const handleDeleteCampaign = (item: CampaignListingItem) =>
    deleteCampaign(item.id, {
      onSuccess: (res) => {
        deleteSuccessNotification(res.data);
      },
    });

  const handleEditCampaign = (item: CampaignListingItem) => setEditItem(item);

  const campaignListingColumns: ColumnsType<CampaignListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 93,
      render: RenderColumn.rowText,
    },

    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      width: 262,
      render: RenderColumn.rowText,
    },
    {
      title: 'Internal Reporting',
      dataIndex: 'internal_reporting',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Deal Ribbon',
      dataIndex: 'deal_ribbon',
      width: 150,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
        { text: `Scheduled`, value: 'scheduled' },
      ],
      width: 127,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'Change Log',
      width: 100,
      render: (row: CampaignListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 60,
      render: (row: CampaignListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'edit',
            label: 'Edit',
            icon: <PencilSimpleLine size={16} />,
            onClick: () => handleEditCampaign(row),
          },
          {
            key: 'delete',
            label: 'Delete',
            icon: <Trash size={16} />,
            onClick: () => deleteCampaignModal(row),
            disabled: row.status !== 'Scheduled',
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={(e) => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
      fixed: 'right',
    },
  ];

  return (
    <>
      <PageHeaderWithSpace
        title={'Campaign Management Tool'}
        subTitle={
          'One-stop for MRP to track and manage current, past and future campaigns'
        }
      />

      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Campaign Summary'}</h2>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isSummaryLoading}
                title="Total"
                value={summaryData?.total_campaigns}
                className={style.statItem}
              />

              <Statistic
                loading={isSummaryLoading}
                title="Live"
                value={summaryData?.live_campaigns}
                className={style.statItem}
              />

              <Statistic
                loading={isSummaryLoading}
                title="Scheduled"
                value={summaryData?.scheduled_campaigns}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={(keyword) => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => setOpenDrawer(true)}
                type="primary"
              >
                Add Campaign
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row>
        <Table
          size="large"
          rowKey={(row) => row.id}
          loading={isFetching}
          columns={campaignListingColumns}
          onChange={tableChangeHandler}
          dataSource={campaignListingData?.product_campaigns}
          className={style.table}
          pagination={{
            total: campaignListingData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !isFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Drawer
        visible={openDrawer || !!editItem}
        onClose={() => {
          setOpenDrawer(false);
          setEditItem(undefined);
        }}
        maskClosable={false}
        extra={
          <Button
            type="text"
            onClick={() => {
              setOpenDrawer(false);
              setEditItem(undefined);
            }}
          >
            Cancel
          </Button>
        }
        title={editItem ? 'Edit Campaign' : 'Add New Campaign'}
        placement="right"
      >
        <CampaignForm
          onSuccessCb={() => {
            setOpenDrawer(false);
            setEditItem(undefined);
          }}
          initialData={
            editItem && {
              id: editItem.id,
              name: editItem.campaign_name,
              start_at: editItem.start_date,
              end_at: editItem.end_date,
              deal_ribbon: editItem.deal_ribbon,
              internal_reporting: editItem.internal_reporting,
              status: editItem.status,
            }
          }
        />
      </Drawer>
    </>
  );
};

export default CampaignManagementView;
