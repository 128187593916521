import {
  PageHeaderWithBackButton,
  SectionHeader,
} from '../../../components/common';
import { Form, Input, SubmitButton } from '../../../components/common/form';
import * as Yup from 'yup';
import {
  companyIPPIssuerValidation,
  companyIPPTenureValidation,
  FetchIPPResponse,
  ippMerchantIDValidation,
  MutateMIDFormType,
  useFetchIPP,
  useFetchIPPIssuers,
  useMutateIPPConfiguration,
  useMutateMID,
} from '../../../services/IPP/useIPP';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router';
import { Button, Drawer, Message, Table } from 'fave-ui';
import style from './style.module.css';
import IPPBankListForm from '../IPPBankListForm';
import GreyDivider from '../../../components/GreyDivider';
import { Spin } from 'antd';
import IPPTenureListForm from '../IPPTenureListForm';
import {
  calcTableHeight,
  dateTimeOrDash,
  defaultDateTimeSecondsForm,
  groupByArray,
  scrollTableConfig,
  valueOrDash,
} from '../../../utils/utilFunctions';
import { useUserContext } from '../../../contexts/UserContext';
import { EnabledStatusType } from '../../../types/dataTypes';
import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  ChangeHistoryItem,
  useFetchChangeHistory,
} from '../../../services/useFetchChangeHistory';

const ippMerchantIDValidationSchema = Yup.object().shape(
  ippMerchantIDValidation
);

const bankAndTenureValidationSchema = Yup.object().shape({
  company_ipp_issuers: companyIPPIssuerValidation,
  company_ipp_tenures: companyIPPTenureValidation,
});

const listHeight = calcTableHeight(460);
const changeLogScrollTableConfig = scrollTableConfig({ height: 500 });

const changeLogColumns: ColumnsType<ChangeHistoryItem> = [
  {
    title: 'When',
    dataIndex: 'created_at',
    className: style.alignBaseline,
    width: 250,
    render: (date) => dateTimeOrDash(date, defaultDateTimeSecondsForm),
  },
  {
    title: 'Who',
    dataIndex: 'whodunnit',
    className: style.alignBaseline,
    width: 200,
    render: RenderColumn.rowText,
  },
  {
    title: 'Old Value',
    dataIndex: 'items',
    width: 250,
    render: (data: ChangeHistoryItem[]) => (
      <ul>
        {data?.map(({ old_value, id }) => (
          <li key={id}>{valueOrDash(old_value)}</li>
        ))}
      </ul>
    ),
  },
  {
    title: 'New Value',
    dataIndex: 'items',
    width: 250,
    render: (data: ChangeHistoryItem[]) => (
      <ul>
        {data?.map(({ new_value, id }) => (
          <li key={id}>{valueOrDash(new_value)}</li>
        ))}
      </ul>
    ),
  },
];

const midChangeLogColumns = [...changeLogColumns];
const bankChangeLogColumns = [...changeLogColumns];

bankChangeLogColumns.splice(2, 0, {
  title: 'Field',
  dataIndex: 'items',
  width: 200,
  render: (data: ChangeHistoryItem[]) => (
    <ul>
      {data?.map(({ changed_field, id }) => (
        <li key={id}>{valueOrDash(changed_field)}</li>
      ))}
    </ul>
  ),
});

const IPPConfigurationView = () => {
  const { id } = useParams();
  const parsedID = parseInt(id || '');

  const {
    data: fetchIPPIssuersData,
    isFetching: isFetchIPPIssuersDataLoading,
  } = useFetchIPPIssuers({ filter: 'status=enabled' });

  const { data: fetchIPPData, isFetching: isFetchIPPLoading } = useFetchIPP({
    company_id: parsedID,
  });

  const {
    data: fetchMIDChangeLogData,
    isFetching: isFetchMIDChangeLogLoading,
    refetch: refetchMIDChangeLog,
  } = useFetchChangeHistory(
    {
      item_type: 'Company',
      paper_trail_event: 'update_mid',
      item_id: parsedID,
      queryKey: 'MIDChangeLog',
    },
    { enabled: false }
  );

  const {
    data: fetchBankChangeLogData,
    isFetching: isFetchBankChangeLogLoading,
    refetch: refetchBankChangeLog,
  } = useFetchChangeHistory(
    {
      item_type: 'Company',
      item_association: 'company_ipp_tenures,company_ipp_issuers',
      item_id: parsedID,
      queryKey: 'bankChangeLog',
    },
    { enabled: false }
  );

  const { mutate: updateMID } = useMutateMID();
  const { mutate: updateIPPConfiguration } = useMutateIPPConfiguration();

  const { getPageSettings } = useUserContext();
  const pageSettings = getPageSettings('partners');
  const enableUpdateMID = !!pageSettings?.update_mid;
  const enableUpdateIPP = !!pageSettings?.update_ipp;

  const [isChangeLogOpen, setIsChangeLogOpen] = useState(false);

  const ippMerchantIDInitialValues = useMemo(
    () => ({
      ipp_merchant_id: fetchIPPData?.ipp_merchant_id || undefined,
    }),
    [fetchIPPData]
  );

  const bankAndTenureInitialValues = useMemo(
    () => ({
      company_ipp_issuers: fetchIPPIssuersData?.ipp_issuers.map(
        (defaultConfig) => {
          const updatedIPPIssuerConfig = fetchIPPData?.company_ipp_issuers.find(
            (savedConfig) => savedConfig.ipp_issuer.id === defaultConfig.id
          );

          if (updatedIPPIssuerConfig) {
            const { tenures, id, name, status, ipp_issuer } =
              updatedIPPIssuerConfig;

            return {
              tenures,
              id,
              name,
              ipp_issuer_id: ipp_issuer?.id,
              status: status,
            };
          } else {
            const { tenures, name, id } = defaultConfig;

            return {
              tenures,
              name,
              ipp_issuer_id: id,
              status: false,
            };
          }
        }
      ),
      company_ipp_tenures: fetchIPPIssuersData?.ipp_tenures.map((item) => {
        const updatedIPPTenureConfig = fetchIPPData?.company_ipp_tenures.find(
          (savedConfig) => savedConfig.tenure === item
        );
        const tenure = updatedIPPTenureConfig?.tenure || item;

        return {
          id: updatedIPPTenureConfig?.id,
          status: updatedIPPTenureConfig?.status || false,
          rate: updatedIPPTenureConfig?.rate || undefined,
          tenure,
        };
      }),
    }),
    [fetchIPPData, fetchIPPIssuersData]
  );

  const parsedMIDChangeLogData = useMemo(
    () =>
      fetchMIDChangeLogData
        ? groupByArray(
            fetchMIDChangeLogData.pages.flatMap((page) => page.list),
            ['created_at', 'whodunnit']
          )
        : [],
    [fetchMIDChangeLogData]
  );

  const parsedBankChangeLogData = useMemo(
    () =>
      fetchBankChangeLogData
        ? groupByArray(
            fetchBankChangeLogData.pages.flatMap((page) => page.list),
            ['created_at', 'whodunnit']
          )
        : [],
    [fetchBankChangeLogData]
  );

  const handleUpdateMID = (
    values: MutateMIDFormType,
    { setSubmitting, resetForm }: FormikHelpers<MutateMIDFormType>
  ) => {
    const { ipp_merchant_id } = values;
    const payloadData = { company_id: parsedID, ipp_merchant_id };

    updateMID(
      { ...payloadData },
      {
        onSuccess: () => {
          Message.success({
            content: 'Successfully updated IPP Merchant ID',
          });
          resetForm({ values: payloadData });
        },
        onSettled: () => setSubmitting(false),
      }
    );
  };

  const handleUpdateIPPConfiguration = (
    values: FetchIPPResponse,
    { setSubmitting, resetForm }: FormikHelpers<FetchIPPResponse>
  ) => {
    const { company_ipp_issuers, company_ipp_tenures } = values;

    const parsedCompanyIPPTenures = company_ipp_tenures.map((item) => ({
      ...item,
      status: (item.status ? 'enabled' : 'disabled') as EnabledStatusType,
    }));

    const parsedCompanyIPPIssuers = company_ipp_issuers.map(
      ({ id, status, ipp_issuer_id }) => ({
        id,
        ipp_issuer_id,
        status: (status ? 'enabled' : 'disabled') as EnabledStatusType,
      })
    );

    updateIPPConfiguration(
      {
        company_id: parsedID,
        company_ipp_tenures: parsedCompanyIPPTenures,
        company_ipp_issuers: parsedCompanyIPPIssuers,
      },
      {
        onSuccess: () => {
          Message.success({
            content: 'Successfully updated IPP Configuration',
          });
          resetForm({ values });
        },
        onSettled: () => setSubmitting(false),
      }
    );
  };

  const handleOpenChangeLogs = () => {
    refetchMIDChangeLog();
    refetchBankChangeLog();
    setIsChangeLogOpen(true);
  };

  return (
    <div>
      <PageHeaderWithBackButton
        title={'IPP Configuration '}
        subTitle={'Instalment Payment Plan configuration'}
      />
      <Spin spinning={isFetchIPPLoading || isFetchIPPIssuersDataLoading}>
        <div className={style.ippSummary}>
          <div className={style.ippSummaryLabel}>
            <span className={style.fontBold}>IPP Summary</span>
          </div>
          <div className={style.ippMerchantIDContainer}>
            <span className={style.ippMerchantIDLabel}>IPP Merchant ID</span>
            <Form
              initialValues={ippMerchantIDInitialValues}
              validationSchema={ippMerchantIDValidationSchema}
              validateOnChange={true}
              onSubmit={handleUpdateMID}
            >
              <div className={style.form}>
                <div className={style.input}>
                  <Input name="ipp_merchant_id" disabled={!enableUpdateMID} />
                </div>
                <SubmitButton
                  disableIfNotValid
                  disableIfNotDirty
                  size={'middle'}
                  isDisabled={!enableUpdateMID}
                >
                  {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Save')}
                </SubmitButton>
              </div>
            </Form>
            <Button
              type="text"
              className={style.changeLogButton}
              onClick={handleOpenChangeLogs}
            >
              Change log
            </Button>
          </div>
        </div>
        <div>
          <Form
            initialValues={bankAndTenureInitialValues}
            validationSchema={bankAndTenureValidationSchema}
            validateOnChange={true}
            onSubmit={handleUpdateIPPConfiguration}
          >
            <div className={style.bankAndTenureContainer}>
              <div>
                <SectionHeader>Banks</SectionHeader>
                <GreyDivider />
                <IPPBankListForm
                  ippIssuerKey={'company_ipp_issuers'}
                  ippTenureKey={'company_ipp_tenures'}
                  showRequiredLabel={false}
                  height={listHeight}
                  disableInputs={!enableUpdateIPP}
                />
              </div>
              <div>
                <SectionHeader>{'Tenure & Fee'}</SectionHeader>
                <GreyDivider />
                <IPPTenureListForm
                  ippTenureKey={'company_ipp_tenures'}
                  showRequiredLabel={false}
                  height={listHeight}
                  disableInputs={!enableUpdateIPP}
                />
              </div>
            </div>
            <div className={style.submitButton}>
              <SubmitButton
                disableIfNotValid
                disableIfNotDirty
                isDisabled={!enableUpdateIPP}
              >
                {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Save')}
              </SubmitButton>
            </div>
          </Form>
        </div>
      </Spin>
      <Drawer
        visible={isChangeLogOpen}
        onClose={() => setIsChangeLogOpen(false)}
        maskClosable={false}
        width={'65vw'}
        extra={
          <Button type="text" onClick={() => setIsChangeLogOpen(false)}>
            Close
          </Button>
        }
        title={'Change Log'}
        placement="right"
      >
        <SectionHeader>{'IPP Merchant ID'}</SectionHeader>
        <Table
          rowKey={(row) => row.id}
          className={style.changeLogTable}
          dataSource={parsedMIDChangeLogData}
          columns={midChangeLogColumns}
          scroll={changeLogScrollTableConfig}
          loading={isFetchMIDChangeLogLoading}
          pagination={{
            pageSize: parsedMIDChangeLogData?.length,
            hideOnSinglePage: true,
          }}
        />
        <SectionHeader>{'Bank, Tenure & Rate'}</SectionHeader>
        <Table
          rowKey={(row) => row.id}
          dataSource={parsedBankChangeLogData}
          columns={bankChangeLogColumns}
          scroll={changeLogScrollTableConfig}
          loading={isFetchBankChangeLogLoading}
          pagination={{
            pageSize: parsedBankChangeLogData?.length,
            hideOnSinglePage: true,
          }}
        />
      </Drawer>
    </div>
  );
};

export default IPPConfigurationView;
