import style from './style.module.css';
import { Col, Row } from 'antd';
import { Empty, Select } from 'fave-ui';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useFetchPrizeOptions } from '../../../../services/FaveArcade/useFetchPrizesListing';
import { arrayToOptions } from '../../../../utils/utilFunctions';
import { useState } from 'react';
import PromoCodeForm from './PromoCodeForm';
import LuckyDrawForm from './LuckyDrawForm';

export default function CreateNewPrizeView() {
  const [prizeType, setPrizeType] = useState('');
  const { data: optionsData } = useFetchPrizeOptions();
  const prizeTypes = optionsData && arrayToOptions(optionsData?.prize_types, '_');

  return (
    <>
      <PageHeaderWithBackButton
        title={`Prize Configurations`}
      />
      <Row >
        <Col span={24}>
          <p className={style.label}>* Prize Type</p>
          <Select
            placeholder="Please Select one option"
            options={prizeTypes}
            onChange={(e) => setPrizeType(e)}
            className={style.prizeType}
          />
        </Col>
        {prizeType === 'lucky_draw' ?
          (
            <Col span={24}>
              <LuckyDrawForm prizeType={prizeType} />
            </Col>
          ) : prizeType === 'promo_code' ?
            (
              <Col span={24}>
                <PromoCodeForm prizeType={prizeType} promoCodeValues={optionsData?.promo_code} currency={optionsData?.currency} />
              </Col>
            ) : (
              <Col span={24}>
                <Empty customTitle='Please select a Prize Type to continue' />
              </Col>)
        }
      </Row>
    </>
  );
}

