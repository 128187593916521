import React from 'react';
import { Input } from '../../../components/common/form/Input';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import SubmitButton from '../../../components/common/form/SubmitButton';
import style from '../style.module.css';
import DatePicker from '../../../components/common/form/DatePicker';
import clsx from 'clsx';
import { useTenantContext } from '../../../contexts/TenantContext';

type ApplicationFeeFormProps = {
  isApplicationFeeFormPage?: boolean;
  enableEditDatepicker?: boolean;
};

type FormFieldsType = {
  name: string;
  isApplicationFeeFormPage?: boolean;
  enableEditDatepicker?: boolean;
};

const FormFields: React.FC<FormFieldsType> = ({
  name,
  isApplicationFeeFormPage,
  enableEditDatepicker,
}) => {
  const inputName = name.split('_').join(' ');
  const feeField = name;
  const remarksField = `${name}_remarks`;
  const datesField = `${name}_activation_date`;

  const label = capitalizeFirstLetterEachWord(inputName);

  const showDatepicker = !isApplicationFeeFormPage;
  const isFeeAndRemarksDisabled = !isApplicationFeeFormPage;

  const { tenantCurrency } = useTenantContext();

  return (
    <div
      className={clsx(
        style.applicationFeeRow,
        showDatepicker ? style.gridThreeColumns : style.gridTwoColumns
      )}
    >
      <div className={style.inputBox}>
        <Input
          label={`${label} (${tenantCurrency})`}
          name={feeField}
          type={'number'}
          allowClear
          bordered={false}
          disabled={isFeeAndRemarksDisabled}
          min={0}
        />
      </div>
      <div className={style.inputBox}>
        <div>
          <Input
            label={'Remarks'}
            placeholder={'Remarks here'}
            name={remarksField}
            allowClear
            bordered={false}
            disabled={isFeeAndRemarksDisabled}
          />
        </div>
      </div>
      {showDatepicker && (
        <div className={style.inputBox}>
          <div>
            <DatePicker
              label={'Activation Date'}
              name={datesField}
              allowClear
              bordered={false}
              disabled={!enableEditDatepicker}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const applicationFormFields = [
  { name: 'annual_license_fee' },
  { name: 'one_time_setup_fee' },
  { name: 'annual_maintenance_fee' },
  { name: 'any_other_fee' },
];

const ApplicationFeeForm: React.FC<ApplicationFeeFormProps> = ({
  isApplicationFeeFormPage = true,
  enableEditDatepicker = true,
}) => (
  <>
    {applicationFormFields.map((item, index) => (
      <FormFields
        {...item}
        key={index}
        isApplicationFeeFormPage={isApplicationFeeFormPage}
        enableEditDatepicker={enableEditDatepicker}
      />
    ))}
    {isApplicationFeeFormPage && (
      <div className={style.submitButtonContainer}>
        <SubmitButton disableIfNotValid={true} className={style.submitButton}>
          {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Save')}
        </SubmitButton>
      </div>
    )}
  </>
);

export default ApplicationFeeForm;
