import { useState } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import { PageHeaderWithSpace } from '../../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col } from 'antd';
import { Empty, FilterIcon, Row, Table, Button, Modal, Notification, Menu, Dropdown } from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  createOnTableChangeHandler,
  formatFilter,
} from '../../../utils/utilFunctions';
import {
  CampaignListingItem,
  useFetchCampaignListing,
  useUpdateCampaignMutation,
  UpdateCampaignPayload,
  ConflictCampaign
} from '../../../services/FaveArcade/useFetchCampaignListing';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { DotsThree, Plus } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../../components/common/SearchBarExpandable/SearchBarExpandable';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import { CampaignStatusType } from '../../../types/dataTypes';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const successNotification = (message: string) => {
  Notification.success({
    message: message
  });
};

const TicketCenterView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [confirmText, setConfirmText] = useState('')
  const [campaign, setCampaign] = useState<CampaignListingItem>();
  const [showModal, setShowModal] = useState(false);
  const [deActivateModal, setDeActivateModal] = useState(false);
  const [confirmCampaign, setConfirmCampaign] = useState<UpdateCampaignPayload>({ status_event: '', id: '' })
  const [conflictCampaigns, setConflictCampaigns] = useState<ConflictCampaign[]>([])

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const { mutateAsync: updateCampaignStatus } = useUpdateCampaignMutation()
  const navigate = useNavigate();

  const handleUpdateStatus = async (campaign: UpdateCampaignPayload) =>
    await updateCampaignStatus(campaign, {
      onSuccess: (res) => {
        successNotification(res?.data?.message ?? 'Your action is success!')
        setShowModal(false)
        setDeActivateModal(false)
      },
      onError: (error) => {
        if ((error).response?.data?.code === 400) {
          setConfirmText(error.response?.data?.error ?? 'Please try again later')
          setConfirmCampaign({ ...confirmCampaign, id: campaign.id, status_event: campaign.status_event, confirm: true })
          setConflictCampaigns(error.response?.data?.error_details?.conflict_campaigns ?? [])
          setShowModal(true)
        }
      }
    });

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) => formatFilter([{ key: 'status', value: filters.status }])
  );

  const { data: campaignData, isFetching } = useFetchCampaignListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const campaignListingData = campaignData?.ticket_campaigns.map((ticket: CampaignListingItem) => {
    return {
      ...ticket,
      status: capitalizeFirstLetterEachWord(ticket.status) as CampaignStatusType,
      campaign_type: capitalizeFirstLetterEachWord(ticket.campaign_type),
    }
  })

  const campaignListingColumns: ColumnsType<CampaignListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 90,
      render: RenderColumn.rowText,
    },

    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      width: 260,
      render: RenderColumn.rowText,
    },
    {
      title: 'Ticket Campaign',
      dataIndex: 'campaign_type',
      width: 220,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
      ],
      width: 120,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'No. of Tickets Issued',
      dataIndex: 'no_of_ticket',
      width: 120,
      render: RenderColumn.rowText,
    },
    {
      title: 'Change Log',
      width: 120,
      render: (row: CampaignListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (row: CampaignListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'view',
            label: 'View',
            onClick: () => navigate(`/fave_arcade/ticket_center/view_campaign/${row.id}`),
          },
          {
            key: 'editStatus',
            label: row.status === 'Active' ? 'Deactivate' : 'Activate',
            onClick: () => {
              if (row.status === 'Active') {
                setDeActivateModal(true);
                setCampaign(row);
              } else {
                handleUpdateStatus({ id: row.id, status_event: row.status_event, confirm: false })
              }
            },
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={(e) => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <PageHeaderWithSpace
        title={'Ticket Center Manager'}
        subTitle={''}
      />

      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Campaign Summary'}</h2>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={(keyword) => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => navigate('/fave_arcade/ticket_center/new')}
                type="primary"
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row>
        <Table
          size="large"
          rowKey={(row) => row.id}
          loading={isFetching}
          columns={campaignListingColumns}
          onChange={tableChangeHandler}
          dataSource={campaignListingData}
          className={style.table}
          pagination={{
            total: campaignData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !isFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Modal
        title="Warning!"
        visible={showModal}
        okText="Continue"
        cancelText="Back"
        onOk={() => { handleUpdateStatus(confirmCampaign) }}
        onCancel={() => { setShowModal(false) }}
      >
        <p>{confirmText}</p>
        {conflictCampaigns?.map(campaign => {
          return <p key={campaign.id}>ID: {campaign.id} - Name: {campaign.campaign_name}</p>
        })}
      </Modal>
      <Modal title={`Warning!`}
        visible={deActivateModal}
        okText="Yes, proceed"
        cancelText="Cancel"
        onOk={() => campaign && handleUpdateStatus({ id: campaign.id, status_event: campaign.status_event, confirm: false })}
        onCancel={() => setDeActivateModal(false)}
      >
        You are about to deactivate {campaign?.campaign_name}, proceed?
      </Modal>
    </>
  );
};

export default TicketCenterView;
