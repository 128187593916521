export enum OutletStatusPaymentMode {
    Existing = 'existing',
    New = 'new',
}

export enum ActionForm {
    Create = 'new',
    Edit = 'edit',
    Resubmit = 'resubmit',
    AdmitEdit = 'admin',
}

export enum FieldName {
    CreateAt = 'Created at',
    UpdateAt = 'Updated at',
    FieldId = 'Id',
    GroupNameOnlineBanking = 'Online Banking',
}