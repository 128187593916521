import { useEffect, useState } from "react";

const useOnceCallback = (
	callback: () => void,
	onlyIf?: undefined | boolean | (() => boolean),
) => {
	const [used, setUsed] = useState(false);

	useEffect(() => {
		if (!used) {
			const canUse =
				onlyIf === undefined
					? true
					: typeof onlyIf === "boolean"
					? onlyIf
					: onlyIf();

			if (canUse) {
				callback();
				setUsed(true);
			}
		}
	}, [used, onlyIf, setUsed, callback]);

	return used;
};

export default useOnceCallback;
