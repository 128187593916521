import { useQuery, UseQueryOptions } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { createFetchAPI } from '../http';

const labelAttrEndpoints = {
  dealRibbon: '/v1/label_attributes?label_attribute_type=deal_ribbon',
  internalReporting:
    '/v1/label_attributes?label_attribute_type=internal_reporting',
};
export const labelAttrCacheKeys = {
  dealRibbon: 'labelAttr_dealRibbonsList',
  internalReporting: 'labelAttr_internalReportingsList',
};

type LabelItem = {
  id: number;
  name: string;
  status: string;
};
type LabelResponseType = Array<LabelItem>;

const fetchDealRibbons = createFetchAPI<TableConfigType, LabelResponseType>(
  labelAttrEndpoints.dealRibbon
);

export const useDealRibbonsList = (
  options?: UseQueryOptions<LabelResponseType, unknown>
) =>
  useQuery<LabelResponseType>(
    [labelAttrCacheKeys.dealRibbon],
    fetchDealRibbons,
    {
      staleTime: 300_000, // 5min
      ...options,
    }
  );

const fetchInternalReportings = createFetchAPI<
  TableConfigType,
  LabelResponseType
>(labelAttrEndpoints.internalReporting);

export const useInternalReportingsList = (
  options?: UseQueryOptions<LabelResponseType, unknown>
) =>
  useQuery<LabelResponseType>(
    [labelAttrCacheKeys.internalReporting],
    fetchInternalReportings,
    {
      staleTime: 300_000, // 5min
      ...options,
    }
  );
