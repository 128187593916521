import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';
import { Table } from 'fave-ui';
import { useState } from 'react';
import { defaultTableConfig } from '../../../helpers/defaults';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  ECardsActionsType,
  useFetchEcardsList,
  useMutateDuplicateECard,
} from '../../../services/MySubmissions';
import { TableSettingsType } from '../../../types/configTypes';
import { MySubmissionStatusType } from '../../../types/dataTypes';
import {
  createOnTableChangeHandler,
  formatFilter,
  scrollTableConfig,
} from '../../../utils/utilFunctions';
import { actionItems, statusFilter } from '../helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from './style.module.css';
import { Funnel } from 'phosphor-react';
import { mySubmissionElementIDs } from '..';

const scrollConfig = scrollTableConfig();

export const ECardsTab: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('query');

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    (filters) =>
      formatFilter([{ key: 'mc_my_submission_status', value: filters.status }])
  );

  const { data: ecardsData, isFetching } = useFetchEcardsList({
    ...tableConfig,
    page: tableConfig.current,
    filter: `partner=${keyword || ''}|${tableConfig.filter || ''}`,
    dataset: 'company,e_card',
  });

  const { mutate: mutateDuplicateECard } = useMutateDuplicateECard();

  const ecardsTabColumns: ColumnsType<ECardsActionsType> = [
    {
      title: 'Submission Date',
      dataIndex: 'submission_time',
      width: 200,
      render: (date: string) =>
        date ? format(parseISO(date), 'd MMM yyyy') : '-',
    },
    {
      title: 'Submitted by',
      dataIndex: 'bd_email',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Source',
      dataIndex: 'submission_type',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Merchant Name',
      dataIndex: ['company', 'name'],
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Payable amount',
      dataIndex: ['e_card', 'payable_amount'],
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Bonus Credit',
      dataIndex: ['e_card', 'bonus_credit_amount'],
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Submission Status',
      dataIndex: 'status',
      width: 200,
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={mySubmissionElementIDs.eCardsSubmissionStatusFilterIcon}
        />
      ),
      filters: statusFilter,
      filterMode: 'tree',
      render: (status: MySubmissionStatusType, data) =>
        RenderColumn.mySubmissionsStatusPopover(status, data, 'eCard'),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (data) =>
        RenderColumn.dropdownMenu(
          actionItems(data, 'eCard', navigate, mutateDuplicateECard),
          '. . .',
          'bottomLeft',
          mySubmissionElementIDs.eCardsActionBtn
        ),
      fixed: 'right',
    },
  ];

  return (
    <Table
      dataSource={ecardsData?.e_card_actions}
      columns={ecardsTabColumns}
      scroll={scrollConfig}
      className={style.table}
      onChange={tableChangeHandler}
      loading={isFetching}
      pagination={{
        total: ecardsData?.count,
        position: ['bottomCenter'],
        pageSize: tableConfig.pageSize,
        current: tableConfig.page,
        showSizeChanger: false,
      }}
      rowKey={(row) => row.id}
    />
  );
};
