import { useQuery, useQueryClient, useMutation } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { OutletStatusType, CampaignStatusType, UpdateStatusType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';

const endpoint = '/v1/cashback_campaigns';
export const campaignListingCacheKey = 'fetchCashbackCampaignsListing';

export type CashbackCampaignListingResponseType = {
  cashback_campaigns: CashbackCampaignListingItem[];
  count: number;
};

export type CashbackCampaignListingItem = {
  id: number;
  campaign_type: string;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType;
};

export type CashbackCampaignDataItem = {
  id: number | string;
  campaign_type: string;
  status: OutletStatusType | CampaignStatusType;
  whitelisted_company_ids?: (string | number)[];
  whitelisted_outlet_ids?: (string | number)[];
};

export type UpdateCashbackCampaignWhitelisted = {
  whitelisted_company_ids?: (string | number)[];
  id: number | string;
}

export type UpdateCashbackCampaignStatus = {
  status_event: UpdateStatusType;
  id: number | string;
}

const fetchCashbackCampaignListing = createFetchAPI<
  TableConfigType,
  CashbackCampaignListingResponseType
>(endpoint);

export const useFetchCashbackCampaignListing = (props: TableConfigType) =>
  useQuery<CashbackCampaignListingResponseType>(
    [campaignListingCacheKey, { ...props }],
    fetchCashbackCampaignListing,
    { staleTime: 300_000 }
  );

const fetchCashbackCampaignListingData = createFetchAPI<
  TableConfigType,
  CashbackCampaignDataItem
>();

export const useFetchCashbackCampaignListingData = ({ id }: { id?: number | string }) =>
  useQuery<CashbackCampaignDataItem>(
    ['fetchCashbackCampaignListingData', { url: `${endpoint}/${id}` }],
    fetchCashbackCampaignListingData,
    { enabled: !!id }
  );

const updateCashbackCampaignWhitelisted = (payload: UpdateCashbackCampaignWhitelisted) =>
  http.put<UpdateCashbackCampaignWhitelisted>(
    `${endpoint}/${payload.id}`,
    payload
  );

export const useUpdateWhitelistedMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (payload: UpdateCashbackCampaignWhitelisted) => updateCashbackCampaignWhitelisted(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries('fetchCashbackCampaignListingData');
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  )
}

export const updateCashbackCampaignStatus = (payload: UpdateCashbackCampaignStatus) =>
  http.put<UpdateCashbackCampaignStatus>(
    `${endpoint}/${payload.id}/update_status`,
    payload
  );

export function useUpdateCashbackCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateCashbackCampaignStatus) => updateCashbackCampaignStatus(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    }
  );
}
