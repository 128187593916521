import React from 'react';
import { useParams } from 'react-router';
import { Form, SubmitButton } from '../../../components/common/form';
import { PageHeaderWithBackButton } from '../../../components/common/PageHeaderWithBackButton';
import {
  approvalDocumentsValidation,
  companyIPPIssuerValidation,
  companyIPPTenureValidation,
  CreateUpdateIPPFormType,
  useCreateIPP,
  useFetchIPPIssuers,
  useFetchMCIPP,
} from '../../../services/IPP/useIPP';
import * as Yup from 'yup';
import IPPTenureListForm from '../IPPTenureListForm';
import { Spin } from 'antd';
import IPPApprovalDocumentForm from '../IPPApprovalDocumentForm';
import IPPBankListForm from '../IPPBankListForm';
import { FormikHelpers } from 'formik';
import { toolRedirect } from '../../../services/http';
import { Message } from 'fave-ui';
import { useUserContext } from '../../../contexts/UserContext';
import style from './style.module.css';
import { useSearchParams } from 'react-router-dom';

const formSchema = Yup.object().shape({
  mc_company_ipp_tenures: companyIPPTenureValidation,
  mc_company_ipp_issuers: companyIPPIssuerValidation,
  ...approvalDocumentsValidation,
});

const onSuccessSubmitForm = (parsedID: number, isUpdate = false) =>
  Message.success({
    content: `Successfully ${
      isUpdate ? 'updated' : 'created'
    } Instalment Payment Plan.`,
  }).then(() =>
    toolRedirect(`/admin/merchant_connect/merchants/${parsedID}/ipp`)
  );

const CreateUpdateIPPView: React.FC = () => {
  // has 2 modes, new / edit
  const { id, mode } = useParams();
  const [searchParams] = useSearchParams();
  const { getPageSettings } = useUserContext();

  const parsedID = parseInt(id || '');
  const isCreateMode = mode === 'new';
  const isUpdateMode = mode === 'edit';
  const isResubmit = searchParams.get('resubmit');
  const onboardingStatus = isResubmit ? 'unsigned,cancelled' : 'draft';

  const pageSettings = getPageSettings('merchant_connect');
  const enableIPPOnboarding = !!pageSettings?.ipp_onboarding;

  const {
    data: fetchIPPIssuersData,
    isFetching: isFetchIPPIssuersDataLoading,
  } = useFetchIPPIssuers({ filter: 'status=enabled' });

  const { data: fetchMCIPPData, isFetching: isFetchMCIPPDataLoading } =
    useFetchMCIPP({
      company_id: parsedID,
      enabled: isUpdateMode,
      filter: `onboarding_status=${onboardingStatus}`,
    });

  const { mutate: createIPP } = useCreateIPP();

  // error handling, to prevent page from crashing
  // when user is still on resubmit ipp page after resubmit submission
  const isMCIPPDataEmpty = fetchMCIPPData?.mc_company_ipp_tenures.length === 0;

  const ippTenures =
    fetchIPPIssuersData?.ipp_tenures.map((item, index) => {
      const data =
        isCreateMode || isMCIPPDataEmpty
          ? {
              status: false,
              rate: undefined,
              tenure: item,
            }
          : {
              status:
                fetchMCIPPData?.mc_company_ipp_tenures[index].status ===
                'enabled',
              rate: fetchMCIPPData?.mc_company_ipp_tenures[index].rate,
              tenure: fetchMCIPPData?.mc_company_ipp_tenures[index].tenure,
              id: fetchMCIPPData?.mc_company_ipp_tenures[index].id,
            };

      return data;
    }) || [];

  const ippIssuers = isCreateMode
    ? fetchIPPIssuersData?.ipp_issuers.map((item) => {
        return {
          ...item,
          ipp_issuer_id: item.id,
          status: true,
        };
      }) || []
    : fetchMCIPPData?.mc_company_ipp_issuers.map(
        ({ ipp_issuer, status, ...restItem }) => ({
          ...restItem,
          name: ipp_issuer.name,
          status: status === 'enabled',
          ipp_issuer_id: ipp_issuer.id,
          tenures: ipp_issuer.tenures,
        })
      ) || [];

  const approvalDocuments = fetchMCIPPData?.approval_documents || undefined;

  const initialValues = {
    mc_company_ipp_tenures: ippTenures,
    mc_company_ipp_issuers: ippIssuers,
    approval_documents: approvalDocuments,
  } as CreateUpdateIPPFormType;

  const onSubmitForm = (
    values: CreateUpdateIPPFormType,
    { setSubmitting }: FormikHelpers<CreateUpdateIPPFormType>
  ) => {
    const formData = new FormData();

    values.mc_company_ipp_tenures.forEach((item) => {
      isUpdateMode &&
        formData.append('mc_company_ipp_tenures[][id]', item.id.toString());
      formData.append(
        'mc_company_ipp_tenures[][status]',
        item.status ? 'enabled' : 'disabled'
      );
      formData.append(
        'mc_company_ipp_tenures[][rate]',
        item.rate as unknown as string
      );
      formData.append(
        'mc_company_ipp_tenures[][tenure]',
        item.tenure.toString()
      );
    });

    values.mc_company_ipp_issuers.forEach((item) => {
      isUpdateMode &&
        formData.append('mc_company_ipp_issuers[][id]', item.id.toString());
      formData.append(
        'mc_company_ipp_issuers[][status]',
        item.status ? 'enabled' : 'disabled'
      );
      formData.append(
        'mc_company_ipp_issuers[][ipp_issuer_id]',
        item.ipp_issuer_id.toString()
      );
    });

    values.approval_documents?.forEach(
      (file) =>
        file.originFileObj &&
        formData.append(`approval_documents[]`, file.originFileObj)
    );

    createIPP(
      { company_id: parsedID, formData, isUpdate: isUpdateMode },
      {
        onSuccess: () => {
          onSuccessSubmitForm(parsedID, isUpdateMode);
          setSubmitting(false);
        },
        onError: () => setSubmitting(false),
      }
    );
  };

  return (
    <div>
      <PageHeaderWithBackButton title={'Tenure & Fee'} />
      <Spin spinning={isFetchIPPIssuersDataLoading || isFetchMCIPPDataLoading}>
        <Form
          initialValues={initialValues || []}
          validationSchema={formSchema}
          validateOnMount
          validateOnChange
          validateOnSchemaChange
          onSubmit={onSubmitForm}
        >
          <IPPTenureListForm ippTenureKey={'mc_company_ipp_tenures'} />
          <IPPBankListForm
            ippIssuerKey={'mc_company_ipp_issuers'}
            ippTenureKey={'mc_company_ipp_tenures'}
          />
          <IPPApprovalDocumentForm
            companyID={parsedID}
            isCreateMode={isCreateMode}
          />
          <div className={style.submitButton}>
            <SubmitButton disableIfNotValid isDisabled={!enableIPPOnboarding}>
              {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Save')}
            </SubmitButton>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateUpdateIPPView;
