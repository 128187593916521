import style from './style.module.css';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { PopConfirm, Button, Col, Row, Notification } from 'fave-ui';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useState } from 'react';
import { AddFLPCashbackPayload, useAddFLPCashbackMutation, useFetchCashbackCampaignOptions, useFetchFLPPartnerOptions } from '../../../../services/CashbackCampaign/useFetchFLPCashbackListing';
import { arrayToOptions } from '../../../../utils/utilFunctions';

const initialValues: AddFLPCashbackPayload = {
  campaign_type: '',
  max_budget_amount_cents: 0,
  funder_type: '',
  campaign_name: '',
  min_transaction_amount_cents: 0,
  min_monthly_transaction_count: 0,
};

const CampaignFormSchema = Yup.object().shape({
  campaign_type: Yup.string().required('Please select a campaign type to continue'),
  funder_id: Yup.number().min(1).required('Please select a FLP partner'),
  campaign_name: Yup.string().required('Please give your campaign a name'),
  max_budget_amount_cents: Yup.number().min(1).required('Please enter an amount'),
  cashback_value_type: Yup.string().required('Please select a cashback type'),
  cashback_amount_cents: Yup.number().when('cashback_value_type', {
    is: 'absolute_amount',
    then: Yup.number().min(1).required('Please enter an amount'),
    otherwise: Yup.number().nullable()
  }),
  cashback_rate: Yup.number().when('cashback_value_type', {
    is: 'percentage',
    then: Yup.number().min(1).required('Please enter an percentage'),
    otherwise: Yup.number().nullable()
  }),
});

const successNotification = () => {
  Notification.success({
    message: 'New Campaign Submitted!',
    description:
      'Your new submission has been added too Cashback Campaign center succesfully',
  });
};

export default function NewFLPCashbackCampaign() {
  const [campaignType, setCampaignType] = useState('');
  const [cashbackType, setCashbackType] = useState('');
  const [funderId, setFunderId] = useState('');
  const { data: optionsData } = useFetchCashbackCampaignOptions({ funder_type: '' });
  const { data: cashbackOptionsData } = useFetchFLPPartnerOptions({ funder_type: campaignType });
  const { mutateAsync: addFLPCashbackCampaign } = useAddFLPCashbackMutation();
  const navigate = useNavigate();

  const cashbackCampaignTypes = optionsData?.funder_types?.map(item => {
    return { label: item.campaign_type, value: item.value, key: item.value };
  });

  const flpPartnerTypes = cashbackOptionsData?.flp_partners?.map(item => {
    return { label: item.funder_name, value: item.funder_id.toString(), key: item.funder_name };
  });

  const cashbackValueTypes = cashbackOptionsData?.cashback_value_types && arrayToOptions(cashbackOptionsData?.cashback_value_types, '_');

  const handleSubmit = async (
    values: AddFLPCashbackPayload,
    { setSubmitting }: FormikHelpers<AddFLPCashbackPayload>
  ) => {
    const typeCampaign = optionsData?.funder_types.find(item => item.value === campaignType)?.campaign_type;
    const typeFunder = cashbackOptionsData?.flp_partners.find(item => item.funder_id.toString() === funderId)?.funder_type;

    const payload = {
      ...values,
      campaign_type: typeCampaign,
      funder_type: typeFunder,
      max_budget_amount_cents: values.max_budget_amount_cents * 100,
      max_cashback_amount_cents: values.max_cashback_amount_cents && values.max_cashback_amount_cents * 100,
      min_transaction_amount_cents: values.min_transaction_amount_cents && values.min_transaction_amount_cents * 100,
      cashback_amount_cents: values.cashback_amount_cents && values.cashback_amount_cents * 100,
      whitelisted_outlet_ids: (values.whitelisted_outlet_ids as string)?.replaceAll('\n', ',').split(','),
    };

    await addFLPCashbackCampaign(
      { ...payload },
      {
        onSuccess: () => {
          successNotification();
          navigate('/admin/merchandise_and_campaign/cashback_campaigns');
        },
        onSettled: () => {
          setSubmitting(false);
        }
      }
    );
  };

  return (
    <>
      <PageHeaderWithBackButton
        title={`Create New FLP Cashback Campaign`}
      />
      <Form
        initialValues={initialValues}
        validationSchema={CampaignFormSchema}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <p className={style.section}>1. Campaign Type</p>
          <Col span={24}>
            <Select
              name="campaign_type"
              label="* Campaign Type"
              placeholder="Please Select one option"
              options={cashbackCampaignTypes}
              onSelect={setCampaignType}
            />
          </Col>
          {campaignType === 'Flp::Client' ? (
            <>
              <p className={style.section}>2. Campaign Detail</p>
              <Col span={24}>
                <Select
                  name="funder_id"
                  label="* FLP Partner"
                  placeholder="Please Select one option"
                  options={flpPartnerTypes}
                  onSelect={setFunderId}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="campaign_name"
                  label="* Campaign Name"
                  placeholder="Enter campaign name"
                  bordered
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  type='number'
                  name="max_budget_amount_cents"
                  label={`* Budget (${optionsData?.currency})`}
                  placeholder="Enter an amount"
                  bordered
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <p className={style.section}>3. Cashback Detail</p>
              <Col span={8}>
                <Select
                  name="cashback_value_type"
                  label="* Cashback type"
                  placeholder="Please Select one option"
                  options={cashbackValueTypes}
                  onSelect={setCashbackType}
                />
              </Col>
              {cashbackType === 'absolute_amount' ? (
                <Col span={8}>
                  <Input
                    type='number'
                    name="cashback_amount_cents"
                    label={`* Cashback Amount (${optionsData?.currency})`}
                    placeholder="Enter an amount"
                    bordered
                    style={{ marginBottom: 0 }}
                  />
                </Col>
              ) : cashbackType === 'percentage' ? (
                <>
                  <Col span={8}>
                    <Input
                      type='number'
                      name="cashback_rate"
                      label="* Cashback Rate (%)"
                      placeholder="Enter the cashback percentage"
                      bordered
                      style={{ marginBottom: 0 }}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      type='number'
                      name="max_cashback_amount_cents"
                      label={`Max Cashback Cap (${optionsData?.currency})`}
                      placeholder="Enter an amount"
                      bordered
                      style={{ marginBottom: 0 }}
                    />
                  </Col>
                </>
              ) : null}
              <p className={style.section}>4. Cashback Condition</p>
              <Col span={24}>
                <Input
                  type='number'
                  name="min_transaction_amount_cents"
                  label={`Minimum Spend (${optionsData?.currency})`}
                  placeholder="Enter an amount"
                  bordered
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  type='number'
                  name="min_monthly_transaction_count"
                  label="Minimum Transaction Count Per Month"
                  placeholder="Enter an amount"
                  bordered
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  type='textArea'
                  name="whitelisted_outlet_ids"
                  label="Whitelisted Outlets"
                  placeholder="Enter the IDs"
                  bordered
                  style={{ marginBottom: 0 }}
                />
              </Col>
            </>
          ) : null}
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty isDisabled={campaignType === 'Fave'}>
              {(_, isSubmitting) =>
                isSubmitting ? 'Submiting...' : 'Save'
              }
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/admin/merchandise_and_campaign/cashback_campaigns')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type='primary' size='large' className={style.cancelBtn}>
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}

