import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { serializeError, ErrorObject } from 'serialize-error';
import { isProduction } from '../../helpers/env';

import style from './style.module.css';
import { Button } from 'fave-ui';

const tabChar = String.fromCharCode(8195);

// Todo:
// *  This is a temporary implementation because there is no design,
//    to be cleaned when the design is ready
// *  Add an optional fallback component if needed. It should receive the error as prop
// *  Add server logging if needed

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    error: null,
  };

  static propTypes = {
    children: PropTypes.element,
  };

  static getDerivedStateFromError(error: ErrorBoundaryState) {
    return { error: error };
  }

  componentDidCatchError(_error: unknown, _info: unknown) {
    // log error to server
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return (
      <>
        {error ? <DefaultFallback error={serializeError(error)} /> : children}
      </>
    );
  }
}

const DefaultFallback = ({ error }: ErrorDetailsProps) => (
  <div className={style.errorContainer}>
    <strong className={style.title}>Oops! An Error Occurred!</strong>
    <div className={style.buttons}>
      <Button type="primary" onClick={() => location.reload()}>
        Refresh
      </Button>
      <Button type="primary" onClick={() => location.assign('/')}>
        Home
      </Button>

      <Button
        type="primary"
        onClick={() =>
          navigator.permissions
            .query({ name: 'clipboard-write' as PermissionName })
            .then((result) => {
              if (result.state === 'granted' || result.state === 'prompt')
                navigator.clipboard.writeText(JSON.stringify(error));
            })
        }
      >
        Copy Error
      </Button>
    </div>
    {!isProduction && <SerializedError error={serializeError(error)} />}
  </div>
);

const SerializedError = ({ error }: ErrorDetailsProps) => {
  const { stack, message } = error;

  if (stack)
    return (
      <ul className={style.errorInfo}>
        {stack.split('\n').map((row, index) => (
          <li key={row}>{index > 0 ? `${tabChar}${tabChar}${row}` : row}</li>
        ))}
      </ul>
    );
  else if (message) return <span>{message}</span>;

  return <span>{JSON.stringify(error)}</span>;
};

type ErrorDetailsProps = { error: ErrorObject };

type ErrorBoundaryProps = {};

type ErrorBoundaryState = {
  error?: unknown;
};

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

export default ErrorBoundary;
