import { Tag } from 'fave-ui';
import React, { useMemo } from 'react';
import { StatusType } from '../../types/dataTypes';
import './style.module.css';

type StatusPropsType = {
  status: StatusType | string;
  getStatusColor?: (status: StatusType | string) => any;
};

const statusColorMap = {
  Live: 'success',
  Active: 'success',
  Scheduled: 'warning',
  Inactive: 'default',
  Draft: '#198cf4',
};

const getStatusColorFromMap = (colorMap: any) => (status: string) =>
  colorMap[status] || 'default';

export const StatusTag: React.FC<StatusPropsType> = ({
  status,
  getStatusColor = getStatusColorFromMap(statusColorMap),
}) => (
  <Tag className={getStatusColor(status)} color={getStatusColor(status)}>
    {status}
  </Tag>
);

const merchantStatusColorMap = {
  Delist: 'warning',
  'Qa Not Approved': 'warning',
  Fraud: 'error',
  Test: 'warning',
  'Out Of Business': 'warning',
  Duplicate: 'warning',
  Suspicious: 'warning',
  Active: 'success',
};

export const MerchantStatusTag: React.FC<StatusPropsType> = ({ status }) => {
  const getMerchantStatusColor = useMemo(
    () => getStatusColorFromMap(merchantStatusColorMap),
    []
  );

  return (
    <StatusTag
      status={status ?? 'Active'}
      getStatusColor={getMerchantStatusColor}
    />
  );
};

const onOffStatusColorMap = {
  Enabled: 'success',
  Disabled: 'error',
};

export const OnOffStatusTag: React.FC<StatusPropsType> = ({ status }) => {
  const getOnOffStatusColor = useMemo(
    () => getStatusColorFromMap(onOffStatusColorMap),
    []
  );

  return <StatusTag status={status} getStatusColor={getOnOffStatusColor} />;
};

const campaignStatusColorMap = {
  Active: 'success',
  Scheduled: 'warning',
  Inactive: 'default',
  Archived: 'error'
};

export const CampaignStatusTag: React.FC<StatusPropsType> = ({ status }) => {
  const getCampaignStatusColor = useMemo(
    () => getStatusColorFromMap(campaignStatusColorMap),
    []
  );

  return <StatusTag status={status} getStatusColor={getCampaignStatusColor} />;
};

const ippApprovalStatusColorMap = {
  Approved: 'success',
  Rejected: 'error',
  'In Progress': 'default',
  Pending: 'default',
  'N/A': 'default',
};

export const IPPApprovalStatusTag: React.FC<StatusPropsType> = ({ status }) => {
  const getStatusColor = useMemo(
    () => getStatusColorFromMap(ippApprovalStatusColorMap),
    []
  );

  return <StatusTag status={status} getStatusColor={getStatusColor} />;
};

const mySubmissionStatusColorMap = {
  'Pending signature': 'default',
  'Pending Ack.': 'default',
  Completed: 'success',
  Cancelled: 'error',
  Signed: 'success',
};

export const MySubmissionsStatusTag: React.FC<StatusPropsType> = ({
  status,
}) => {
  const getStatusColor = useMemo(
    () => getStatusColorFromMap(mySubmissionStatusColorMap),
    []
  );

  return <StatusTag status={status} getStatusColor={getStatusColor} />;
};

const makerCheckerStatusColorMap = {
  Pending: 'pending',
  Rejected: 'error',
  Completed: 'success',
  Approved: 'warning',
  Processing: 'warning',
  Cancelled: 'default',
};

export const MakerCheckerStatusTag: React.FC<StatusPropsType> = ({
  status,
}) => {
  const getStatusColor = useMemo(
    () => getStatusColorFromMap(makerCheckerStatusColorMap),
    []
  );

  return <StatusTag status={status} getStatusColor={getStatusColor} />;
};
