import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Notification, PopConfirm, Button, Table, Empty, Tooltip, InputNumber, UploadFile } from 'fave-ui';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { StatusType } from '../../../../types/dataTypes';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useFetchGameOptions, useAddCampaignMutation } from '../../../../services/FaveArcade/useFetchGamesListing';
import { appendFormData, arrayToOptions } from '../../../../utils/utilFunctions';
import { useState } from 'react';
import { Trash } from 'phosphor-react';
import { ColumnsType } from 'antd/lib/table/interface';
import { RenderColumn } from '../../../../helpers/tableHelpers/RenderColumn';
import ImageUpload from '../../../../components/common/form/ImageUpload';

type CampaignFormFields = {
  id?: number;
  campaign_name: string;
  game_type: string;
  subtitle?: string;
  description: string;
  thumbnail_image: UploadFile[];
  background_image: UploadFile[];
  ticket_to_play: number;
  daily_play_limit: number;
  rewards: Reward[]
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

type Reward = {
  id?: string;
  no_of_token: number;
  reward_percentage: number;
}

const initialValues: CampaignFormFields = {
  campaign_name: '',
  game_type: '',
  description: '',
  ticket_to_play: 0,
  daily_play_limit: 0,
  thumbnail_image: [],
  background_image: [],
  rewards: []
};

const CampaignFormSchema = Yup.object().shape({
  campaign_name: Yup.string().required('Please give your campaign a name'),
  game_type: Yup.string().required('Please select at least one game'),
  description: Yup.string().required('Please give your campaign a name'),
  daily_play_limit: Yup.number().min(0).required('Please enter the daily play limit'),
  ticket_to_play: Yup.number().min(0).required('Please enter enter the number of tickets to play'),
  thumbnail_image: Yup.array().of(Yup.mixed().nullable().required("An image is required")).min(1, 'Please upload an image'),
  background_image: Yup.array().of(Yup.mixed().nullable().required("An image is required")).min(1, 'Please upload an image'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Campaign Submitted!',
    description:
      'Your new submission has been added too Fave Arcade ticket center succesfully',
  });
};

const warningNotification = (message: string) => {
  Notification.warning({
    message: message,
    description:
      'The total percentage of rewards should be equal to 100%',
    placement: "bottomRight",
  })
}

export default function NewCampaign() {
  const [rewards, setRewards] = useState<Reward[]>([])
  const [tokens, setTokens] = useState(0)
  const [chance, setChance] = useState(0)
  const { mutateAsync: addNewCampaign } = useAddCampaignMutation();
  const { data: optionsData } = useFetchGameOptions();
  const navigate = useNavigate();
  const gameTypes = optionsData && arrayToOptions(optionsData?.game_types, '_');

  const settingColumns: ColumnsType<Reward> = [
    {
      title: 'Tokens',
      dataIndex: 'no_of_token',
      width: 90,
      sorter: (a, b) => a.no_of_token - b.no_of_token,
      render: RenderColumn.rowText,
    },
    {
      title: '% Chance',
      dataIndex: 'reward_percentage',
      width: 90,
      sorter: (a, b) => a.reward_percentage - b.reward_percentage,
      render: RenderColumn.rowText,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 90,
      render: (row: Reward) => {
        return (
          <Tooltip title="Delete reward">
            <Button key={row.id} onClick={() => handleDeleteReward(row.id as string)} type='ghost'>
              <Trash size={16} />
            </Button>
          </Tooltip>
        )

      }
    },
  ];

  const handleRewards = () => {
    const totalChance = rewards.reduce((total, reward) => total + reward.reward_percentage, 0);
    if (chance <= 0 || tokens <= 0) {
      warningNotification("Number of tokens and percentage must be more than 0");
      return;
    }
    if (totalChance + chance > 100) {
      warningNotification('The percentage exceeds 100%');
      return;
    }
    setRewards([{ id: (Math.random() * 10000).toFixed(0) + tokens.toString(), no_of_token: tokens, reward_percentage: chance }, ...rewards]);
    setTokens(0);
    setChance(0);
  }

  const handleDeleteReward = (id: string) => {
    setRewards(rewards.filter(reward => reward.id !== id));
  }

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>
  ) => {
    const payload = {
      ...values,
      rewards: rewards,
      thumbnail_image: values.thumbnail_image[0].originFileObj,
      background_image: values.background_image[0].originFileObj,
    };
    const formData = appendFormData(payload);
    const totalChance = rewards.reduce((total, reward) => total + reward.reward_percentage, 0);
    if (totalChance !== 100) {
      warningNotification('The percentage must be equal to 100%');
      return;
    }
    try {
      await addNewCampaign(
        { formData },
        {
          onSuccess: () => {
            successNotification();
          },
        }
      );
    }
    finally {
      setSubmitting(false);
      navigate('/fave_arcade/games_center');
    }
  };

  return (
    <Row>
      <PageHeaderWithBackButton
        title={`Create New Game`}
      />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CampaignFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              placeholder="Enter campaign name"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select
              name="game_type"
              label="Game"
              placeholder="Please Select one option"
              options={gameTypes}
            />
          </Col>
          <Col span={24}>
            <Input
              name="subtitle"
              label="Game Subtitle (Optional)"
              placeholder="Enter game subtitle"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type='textArea'
              name="description"
              label="Game Description"
              placeholder="Enter game description"
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={12}>
            <ImageUpload
              name="background_image"
              label="Background Image"
              types={["image/jpeg", "image/png"]}
              imgSize={2}
            />
          </Col>
          <Col span={12}>
            <ImageUpload
              name="thumbnail_image"
              label="Thumbnail Image"
              types={["image/jpeg", "image/png"]}
              imgSize={2}
            />
          </Col>
          <Col span={24}>
            <Input
              type='number'
              name="daily_play_limit"
              label="Daily Play Limit"
              placeholder="Enter daily limit"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type='number'
              name="ticket_to_play"
              label="Tickets to Play"
              placeholder="Enter number of tickets"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>

          <Col span={24}>Reward Distribution</Col>
          <Col span={12}>
            <Table
              size="small"
              columns={settingColumns}
              dataSource={rewards}
              pagination={{
                hideOnSinglePage: true
              }}
              locale={{
                emptyText: !rewards.length && <Empty customDescription='Empty! Use "Add Entry" to add reward distribution.' />,
              }}
            />
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={12}>
                <InputNumber
                  name="no_of_token"
                  addonBefore="Tokens"
                  min={0}
                  value={tokens}
                  onChange={(e) => setTokens(e as number)}
                />
              </Col>
              <Col span={12}>
                <InputNumber
                  min={0}
                  max={100}
                  name="reward_percentage"
                  addonBefore="% Chance"
                  value={chance}
                  onChange={(e) => setChance(e as number)}
                />
              </Col>
              <Col span={4}>
                <Button type='primary' size='large' onClick={handleRewards}>Add Entry</Button>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) =>
                isSubmitting ? 'Submiting...' : 'Save'
              }
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/fave_arcade/games_center')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type='primary' size='large'>
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

