import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Spin } from 'antd';
import { Button, Message } from 'fave-ui';
import { CaretLeft, CaretRight } from 'phosphor-react';
import style from '../style.module.css';
import { PageHeaderWithBackButton } from '../../../components/common';
import GreyDivider from '../../../components/GreyDivider';
import { calcTableHeight } from '../../../utils/utilFunctions';
import {
  Form,
  Input,
  Select,
  SubmitButton,
} from '../../../components/common/form';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import {
  FormValueGroupDetail,
  formGroupSchema,
  initiallFormGroup,
} from '../helper/formSchema';
import {
  CheckerQueryParams,
  useFetchMembers,
} from '../../../services/MakerChecker/useMakerChecker';
import {
  useCreateCheckerGroup,
  useFetchCheckerGroupDetail,
  useUpdateCheckerGroup,
} from '../../../services/MakerChecker/useMakerCheckerGroup';
import { FieldName, MKTab } from '../helper/enum';
import { FormikHelpers } from 'formik';

const tableHeight = calcTableHeight(470);

const successNotification = () => {
  Message.success({
    content: 'Group detail successfully submitted',
  });
};

const failedNotification = () => {
  Message.error({
    content: 'Something wrong, please try again',
  });
};

const GroupFormView: React.FC = () => {
  const { id: groupId } = useParams();
  const navigate = useNavigate();

  const [searchMember, setSearchMember] = useState<
    CheckerQueryParams | undefined
  >(!groupId ? { name: '' } : undefined);

  const {
    isLoading: isLoadingDetails,
    data: groupDetails,
    refetch: refetchCheckerGroup,
  } = useFetchCheckerGroupDetail({ id: groupId || '' });

  const { data: members } = useFetchMembers(searchMember);

  const { mutate: createCheckerGroup } = useCreateCheckerGroup();
  const { mutate: updateCheckerGroup } = useUpdateCheckerGroup();

  const handleSearchChecker = useDebouncedCallback(([value]) => {
    value.length > 1 && setSearchMember({ name: value });
  }, 1000);

  const handleSearchMember = useDebouncedCallback(([value]) => {
    value.length > 1 && setSearchMember({ name: value });
  }, 1000);

  const checkerOptions = useMemo(
    () =>
    members
        ? members?.users.map((checker) => ({
            key: `${checker.id}`,
            label: checker.name,
            value: `${checker.id}`,
          }))
        : groupDetails?.checkers.map((checker) => ({
            key: `${checker.id}`,
            label: checker.name,
            value: `${checker.id}`,
          })),
    [members, groupDetails]
  );

  const memberOptions = useMemo(
    () =>
      members
        ? members?.users.map((member) => ({
            key: `${member.id}`,
            label: member.name,
            value: `${member.id}`,
          }))
        : groupDetails?.members.map((member) => ({
            key: `${member.id}`,
            label: member.name,
            value: `${member.id}`,
          })),
    [members, groupDetails]
  );

  const initialForm = useMemo(
    () =>
      groupId
        ? {
            name: groupDetails?.name,
            members: groupDetails?.members.map((mem) => `${mem.id}`),
            checkers: groupDetails?.checkers.map((checker) => `${checker.id}`),
          }
        : initiallFormGroup,
    [groupId, groupDetails]
  );

  const handleNavigateBack = useCallback(() => {
    navigate('/admin/maker_checker_dashboard', {
      state: {
        activeTab: MKTab.Infosec_groups,
      },
    });
  }, []);

  const handleOnBlur = useCallback((fieldname) => {
    if (fieldname === FieldName.Checker) return setSearchMember({ name: '' });
    setSearchMember({ name: '' });
  }, []);

  const handleSubmit = useCallback(
    (
      values: FormValueGroupDetail,
      { setSubmitting }: FormikHelpers<FormValueGroupDetail>
    ) => {
      const listMemberRemoved = groupDetails?.members
        ?.filter((member) => {
          return !values.members.find(
            (mem) => Number(mem) === Number(member.id)
          );
        })
        .map((member) => member.id);
      const listCheckerRemoved = groupDetails?.checkers
        ?.filter((checker) => {
          return !values.checkers.find(
            (user) => Number(user) === Number(checker.id)
          );
        })
        .map((checker) => checker.id);
      const members = values.members.map(mem => Number(mem));
      const checkers = values.checkers.map(checker => Number(checker));

      try {
        if (groupId) {
          updateCheckerGroup(
            {
              id: groupId,
              name: values.name,
              checkers,
              members,
              removed_checkers: listCheckerRemoved || [],
              removed_members: listMemberRemoved || [],
            },
            {
              onSuccess: () => successNotification(),
            }
          );
          return;
        }
        createCheckerGroup(
          {
            name: values.name,
            checkers,
            members,
          },
          {
            onSuccess: () => successNotification(),
          }
        );
      } catch (err) {
        failedNotification();
      } finally {
        setSubmitting(false);
        refetchCheckerGroup();
      }
    },
    [groupId, groupDetails]
  );

  return (
    <div>
      <PageHeaderWithBackButton
        title={'Group'}
        subTitle={'Please fill necessary fields'}
      />
      <div className={style.contentGroupForm}>
        <p className={style.headerGroupForm}>Group details</p>
        <GreyDivider />
        <div className={style.form}>
          <Spin spinning={isLoadingDetails}>
            <Form
              initialValues={initialForm}
              onSubmit={handleSubmit}
              validationSchema={formGroupSchema}
              validateOnMount
              validateOnChange
              validateOnSchemaChange
            >
              <div style={{ height: tableHeight }}>
                <Input
                  label="Name"
                  name="name"
                  placeholder="Group Name"
                  allowClear
                />
                <Select
                  name={FieldName.Member as string}
                  label="Members"
                  placeholder="Select members"
                  onSearch={handleSearchMember}
                  isLoadingSelect={true}
                  options={memberOptions}
                  mode="multiple"
                  filterOption={false}
                  onBlur={() => handleOnBlur(FieldName.Member)}
                />
                <Select
                  name={FieldName.Checker as string}
                  label="Checkers"
                  placeholder="Select checkers"
                  onSearch={handleSearchChecker}
                  isLoadingSelect={true}
                  options={checkerOptions}
                  mode="multiple"
                  filterOption={false}
                  onBlur={() => handleOnBlur(FieldName.Checker)}
                />
              </div>
              <div>
                <GreyDivider />
                <div className={style.submitButtonContainer}>
                  <Button type="primary" onClick={handleNavigateBack}>
                    <CaretLeft className="mr-2.5" />
                    Back
                  </Button>
                  <SubmitButton disableIfNotValid={true}>
                    {(_, isSubmitting) => (
                      <div className="flex items-center">
                        {isSubmitting ? 'Saving...' : 'Save'}
                        <CaretRight className="ml-2.5 " />
                      </div>
                    )}
                  </SubmitButton>
                </div>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default GroupFormView;
