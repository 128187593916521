import { FormItem, DateRangePicker, DateRangePickerProps } from 'fave-ui';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { formatDate } from '../../../utils/utilFunctions';

export type FaveDatePickerProps<T> = DateRangePickerProps & {
  name: keyof T;
  label?: string;
  dateFormat?: any;
  handleOnChange?: (date: Date[] | null) => void;
};

const RangePicker = <T extends {}>({
  name,
  label,
  disabled,
  dateFormat = formatDate,
  placeholder = ['Select date', 'Select date'],
  handleOnChange,
  ...rest
}: FaveDatePickerProps<T>) => {
  const { errors, touched, handleBlur, values, setFieldValue } =
    useFormikContext<T>();

  const value = values[name]
    ? (values[name] as [Date | null, Date | null])
    : null;

  const error = errors[name] as unknown as string;

  const isTouched = touched[name] as unknown as string;

  const helpText = isTouched && error !== undefined ? error : '';

  const dateRangePicker = useMemo(
    () => (
      <DateRangePicker
        name={name}
        value={value}
        onChange={(date) => {
          setFieldValue(name, date);
          handleOnChange && handleOnChange(date as Date[]);
        }}
        onBlur={handleBlur}
        disabled={disabled}
        format={dateFormat}
        placeholder={placeholder}
      />
    ),
    [name, value, setFieldValue, disabled, dateFormat, placeholder]
  );

  return (
    <FormItem label={label} help={helpText} {...rest}>
      {dateRangePicker}
    </FormItem>
  );
};

export default RangePicker;
