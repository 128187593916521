import React from 'react';
import { OutletType } from '../../types/dataTypes';
import style from './style.module.css';
import { ModalFunctions } from 'fave-ui';
import useCleverTap from '../../hooks/useCleverTap';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
} from '../../helpers/ClevertapKeys';

type OutletsModalButtonProps = {
  outlets: OutletType[];
};

export const OutletsModalButton: React.FC<OutletsModalButtonProps> = ({
  outlets,
}) => {
  const cleverTap = useCleverTap();

  const clickHandler = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.PRODUCT_DASHBOARD,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ECARDS_ALL_OUTLETS,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    });

    ModalFunctions.confirm({
      title: 'Outlets',
      okCancel: false,
      icon: false,
      centered: true,
      className: style.outletModalButton,
      okText: 'Close',
      content: (
        <ul>
          {outlets.map((item) => (
            <li
              key={item.id}
              className={
                item.has_fave_payment ? style.hasFavePay : style.hasNoFavePay
              }
            >
              {item.name}
            </li>
          ))}
        </ul>
      ),
    });
  };

  return (
    <>
      <span className={style.viewOutlets} onClick={clickHandler}>
        <span
          className={style.viewOutletsButton}
        >{`${outlets.length} outlets`}</span>
      </span>
    </>
  );
};
