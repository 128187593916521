import clsx from 'clsx';
import { Button, Search, SearchProps } from 'fave-ui';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useState } from 'react';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import style from './style.module.css';

type SearchBarExpandableProps = Omit<SearchProps, 'onChange'> & {
  alwaysOpen?: boolean;
  alwaysShowBorder?: boolean;
  className?: string;
  // onSearch is when user press enter or click magnifying glass
  onSearch?: (value: string) => void;
  // onChange is when user types and immediately executes API call
  onChange?: (value: string) => void;
  debounce?: boolean;
};

export const SearchBarExpandable: React.FC<SearchBarExpandableProps> = ({
  alwaysOpen,
  alwaysShowBorder,
  className,
  onSearch,
  placeholder = 'Search',
  onChange = () => ({}),
  debounce = true,
  ...restProps
}: SearchBarExpandableProps) => {
  const [isOpen, setIsopen] = useState(false);

  const handleInputClear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.type === 'click' && e.target.value === '') {
      setIsopen(false);
    }
  };

  // need to use same number of hooks on every render
  const returnedSearch = useDebouncedCallback(
    ([searchValue]) => onChange(searchValue),
    debounce ? 400 : 0
  );

  const handleSearchChange = alwaysOpen
    ? ({ target }: React.ChangeEvent<HTMLInputElement>) =>
        returnedSearch(target.value)
    : handleInputClear;

  return (
    <div className={style.searchBarExpandable}>
      {alwaysOpen || isOpen ? (
        <Search
          placeholder={placeholder}
          allowClear={true}
          onSearch={onSearch}
          className={clsx(style.isSearch, className, {
            [style.showBorder]: alwaysShowBorder,
          })}
          autoFocus={true}
          onChange={handleSearchChange}
          {...restProps}
        />
      ) : (
        <Button
          size={'large'}
          shape={'circle'}
          icon={<MagnifyingGlass />}
          onClick={() => setIsopen(true)}
          className={style.searchButton}
        />
      )}
    </div>
  );
};
