export const CLEVERTAP_SCREEN_NAME = {
  MY_MERCHANT: 'merchant_index',
  MY_MERCHANT_SEARCH: 'search',
  PRODUCT_DASHBOARD: 'product_dashboard',
  MY_SUBMISSION: 'my_submission'
};

export const CLEVERTAP_SECTION_NAME = {
  ADD_MENU: 'add_menu',
  ACTIONS_MENU: 'actions_menu',
};

export const CLEVERTAP_TAPPED_ON = {
  BUTTON_ADD_NEW_PRODUCT: 'add_new_product',
  BUTTON_GENERATE_MASTER_CONTRACT: 'generate_master_contract',
  BUTTON_ADD_NEW_MERCHANT: 'add_new_merchant',
  BUTTON_PRODUCT_DASHBOARD: 'product_dashboard',
  BUTTON_PRODUCT_TAP: {
    '1': 'favepay_tab',
    '2': 'deals_tab',
    '3': 'ecards_tab',
    '4': 'bnpl_tab',
    '5': 'payment_mode_tab',
    '6': 'instalment_payment_plan_tab',
    '7': 'contract_migration_tab'
  },
  BUTTON_ECARDS_ALL_OUTLETS: 'ecards_all_outlets',
};

export const CLEVERTAP_ADDITIONAL = {
  MERCHANT_CONNECT: 'merchant_connect',
  MERCHANT_INDEX: 'merchant_index',
};
