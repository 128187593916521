
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { DroppableProps } from 'react-beautiful-dnd';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
    index: number;
}

export const DraggableRow = ({ index, children, ...rest }: RowProps) => (
    <Draggable key={rest["data-row-key"]} draggableId={rest['data-row-key']} index={index}>
        {(provided) => (
            <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {children}
            </tr>
        )}
    </Draggable>
);

export const DroppableTableBody = ({ droppableId, ...props }: DroppableProps) => (
    <Droppable droppableId={droppableId}>
        {(provided) => (
            <>
                <tbody
                    ref={provided.innerRef}
                    {...props}
                    {...provided.droppableProps}

                ></tbody>
                {provided.placeholder}
            </>
        )}
    </Droppable>
);
