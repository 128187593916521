import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ColumnsType,
  ColumnType,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  Button,
  Dropdown,
  HeaderWithSort,
  Menu,
  ModalFunctions,
  Table,
  Tag,
  Search,
  Select,
  Empty,
  FilterIcon,
} from 'fave-ui';

import style from '../MyMerchantSearchView/style.module.css';

import useCleverTap from '../../hooks/useCleverTap';
import useOnceCallback from '../../hooks/useOnceCallback';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON,
} from '../../helpers/ClevertapKeys';
import {
  FetchMerchantType,
  useFetchFlipper,
  useFetchMerchants,
} from '../../services/merchants';
import { toolRedirect } from '../../services/http';
import { CompanyType, OutletType } from '../../types/dataTypes';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  formatFilter,
  isTableDataEmpty,
  valueOrDash,
} from '../../utils/utilFunctions';
import { PageHeaderWithBackButton } from '../../components/common/PageHeaderWithBackButton';
import { MerchantAvatar } from '../../components/MerchantAvatar/MerchantAvatar';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import { getValidActionItems } from '../MyMerchantView';
import { useTenantContext } from '../../contexts/TenantContext';
import {
  AddProductOptionsType,
  getPath,
  getNewProductOptions,
} from '../MyMerchantView/helpers';
import { useUserContext } from '../../contexts/UserContext';
import IconCompanyLogo from '../../assests/icons/IconCompanyLogo';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();
const tableConfigDefault = {
  current: 1,
  pageSize: 10,
  sort_order: undefined,
};

const MyMerchantSearchView = () => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('query') || '';
  const [tableConfig, setTableConfig] = useState<
    TablePaginationConfig & FetchMerchantType
  >(tableConfigDefault);
  const navigate = useNavigate();
  const cleverTap = useCleverTap();
  const { data, isFetching } = useFetchMerchants({
    currentPage: tableConfig.current,
    limit: tableConfig.pageSize,
    sort_order: tableConfig.sort_order,
    filter: `merchant_name_or_bd_email=${keyword}|status=approved|${
      tableConfig.filter || ''
    }`,

    dataset: 'outlets',
  });

  const { isID, selectedTenant } = useTenantContext();

  const { data: flipper } = useFetchFlipper({
    key: `fave_payment_mode_enabled_${selectedTenant}`,
  });

  const { getPageSettings } = useUserContext();

  const pageSettings = getPageSettings('merchant_connect');

  const enableIPPOnboarding = !!pageSettings?.ipp_onboarding;

  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT_SEARCH,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
      from_page: CLEVERTAP_ADDITIONAL.MERCHANT_INDEX,
    })
  );

  const dataColumns: ColumnsType<CompanyType> = [
    {
      title: ({
        sortColumns,
      }: {
        sortColumns: {
          column: ColumnType<{}>;
          order: SortOrder;
        }[];
      }) => (
        <HeaderWithSort sortColumns={sortColumns} title={'Merchant Name'} />
      ),
      dataIndex: 'name',
      width: 210,
      sorter: true,
      render: (name: string, company: CompanyType) => {
        return (
          <div className={style.merchantColumn}>
            <MerchantAvatar
              logo={company.logo != null ? company.logo : <IconCompanyLogo />}
            />
            {name}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'flagged_reason',
      width: 150,
      render: RenderColumn.merchantStatus,
    },
    {
      title: 'Outlets',
      dataIndex: 'outlets',
      width: 90,
      render: (outlets: OutletType[]) => <>{outlets.length}</>,
    },
    {
      title: 'BD assigned',
      dataIndex: 'bd_manager_email',
      width: 208,
      ellipsis: {
        showTitle: false,
      },
      render: (bd_manager_email: string | undefined) =>
        valueOrDash(bd_manager_email),
    },
    {
      title: 'Master Contract',
      dataIndex: 'contract_status',
      width: 176,
      render: (contract_status: string) => (
        <>
          <Tag color={contract_status === 'Signed' ? 'success' : 'default'}>
            {contract_status}
          </Tag>
        </>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      width: 136,
      render: RenderColumn.products,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 124,
      //todo: Unify this function with the one in MyMerchantView. It is a duplicate.
      render: (company: CompanyType) => {
        const id = company.id;
        const { user } = useUserContext();

        const newProductOptions = getNewProductOptions(
          company,
          enableIPPOnboarding,
          flipper?.value
        );

        const defaultPath = getPath(newProductOptions[0].value);

        let path: { value: string; redirect: boolean } = {
          value: defaultPath,
          redirect: true,
        };

        const handleSelect = (value: string, option: AddProductOptionsType) => {
          path = {
            value: option.redirect ? getPath(value) : value,
            redirect: option.redirect,
          };
        };

        const handleAction = (url: string) => {
          path.redirect ? toolRedirect(url) : navigate(url);
        };

        const modalContent = (
          <div>
            <span>
              Please select the product you would like to add to {company.name}.
            </span>
            <Select
              className={style.addNewProduclModal}
              onChange={(value, option) =>
                handleSelect(value, option as AddProductOptionsType)
              }
              placeholder="Select a product"
              showArrow
              options={newProductOptions}
              defaultValue={newProductOptions[0]}
            />
          </div>
        );

        const onClickAddNewProductToMerchant = () => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT_SEARCH,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ADD_NEW_PRODUCT,
            platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
            from_page: CLEVERTAP_ADDITIONAL.MERCHANT_INDEX,
          });

          ModalFunctions.confirm({
            title: 'Select a product',
            content: modalContent,
            okText: 'Confirm',
            cancelText: 'Cancel',
            confirmMode: false,
            okButtonProps: {
              // Modal is not react component, hence it cannot change value dynamically
              disabled: false,
            },
            hasIcon: false,
            icon: null,
            onOk: () => handleAction(path.value),
            centered: true,
          });
        };

        const onClickViewMerchant = () =>
          handleAction(`${window.location.pathname}/${id}/outlets`);

        const onClickProductDashboard = (merchantId: number) => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT_SEARCH,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_DASHBOARD,
            section_name: CLEVERTAP_SECTION_NAME.ACTIONS_MENU,
            platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
            from_page: CLEVERTAP_ADDITIONAL.MERCHANT_INDEX,
          });

          navigate(`${merchantId}/product_dashboard`);
        };

        const onClickGenerateMasterContract = () => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT_SEARCH,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_GENERATE_MASTER_CONTRACT,
            platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
            from_page: CLEVERTAP_ADDITIONAL.MERCHANT_INDEX,
          });

          handleAction(`${window.location.pathname}/${id}/contract_migration`);
        };

        const actionItems: ItemType[] = [
          {
            key: 'view_merchant',
            label: 'View Merchant',
            onClick: onClickViewMerchant,
          },
          {
            key: 'product_dashboard',
            label: 'Product Dashboard',
            onClick: () => onClickProductDashboard(id),
          },
          {
            key: 'generate_master_contract',
            label: 'Generate Master Contract',
            onClick: onClickGenerateMasterContract,
          },
          {
            key: 'add_new_product',
            label: 'Add New Product',
            onClick: onClickAddNewProductToMerchant,
          },
        ];

        const menu = (
          <Menu items={getValidActionItems(actionItems, user!, company)} />
        );

        return (
          <Dropdown overlay={menu} trigger={['hover']}>
            <Button type={'text'} onClick={(e) => e.preventDefault()}>
              Actions
            </Button>
          </Dropdown>
        );
      },
      fixed: 'right',
    },
  ];

  if (!isID)
    dataColumns.unshift({
      title: 'Company',
      dataIndex: 'merchant_type',
      filterIcon: FilterIcon,
      filters: [
        { text: `Fave`, value: 'fave' },
        { text: `FastPay`, value: 'fastpay' },
      ],
      width: 130,
      render: RenderColumn.merchantType,
    });

  const _handleSearch = (value: string) => {
    navigate(
      value
        ? `/admin/merchant_connect/my_merchant/merchants?query=${value}`
        : `/admin/merchant_connect/my_merchant/merchants`,
      { replace: true }
    );
  };

  const handleChangeTable = createOnTableChangeHandler(
    setTableConfig,
    (filters) =>
      formatFilter([{ key: 'merchant_type', value: filters.merchant_type }])
  );

  return (
    <div>
      <PageHeaderWithBackButton
        title={'Search'}
        subTitle={"Here is what you're looking for"}
        additionalContent={
          <div className={style.inputSearch}>
            <Search
              placeholder="Search"
              allowClear={true}
              defaultValue={keyword || undefined}
              onSearch={_handleSearch}
            />
          </div>
        }
      />
      <Table
        size="large"
        showHeader={!isTableDataEmpty(data?.fave_save_merchants)}
        rowKey={(row) => row.id}
        loading={isFetching}
        dataSource={data?.fave_save_merchants}
        columns={dataColumns}
        scroll={{
          x: tableWidth,
          y: tableHeight,
        }}
        className={style.table}
        pagination={{
          total: data?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.current,
          showSizeChanger: false,
        }}
        locale={{
          emptyText: !isFetching && (
            <Empty
              emptyType="cant-find-anything"
              style={{ height: tableHeight }}
            />
          ),
        }}
        onChange={handleChangeTable}
      />
    </div>
  );
};

export default MyMerchantSearchView;
