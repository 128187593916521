import style from './style.module.css'
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { PopConfirm, Button, Col, Row, Notification } from 'fave-ui';
import Form from '../../../../components/common/form/Form';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { CashbackValueType, StatusType } from '../../../../types/dataTypes';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { Select } from '../../../../components/common/form/Select';
import { arrayToOptions } from '../../../../utils/utilFunctions';
import { useFetchFLPCashbackListingData, useUpdateFLPWhitelistedMutation } from '../../../../services/CashbackCampaign/useFetchFLPCashbackListing';

type CampaignFormFields = {
  id?: number;
  campaign_type: string;
  funder_name: string;
  campaign_name: string;
  max_budget_amount_cents: number;
  cashback_value_type: CashbackValueType;
  cashback_amount_cents?: number;
  cashback_rate?: number;
  max_cashback_amount_cents: number;
  min_transaction_amount_cents?: number;
  min_monthly_transaction_count?: number;
  whitelisted_outlet_ids?: (string | number)[];
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

const successNotification = () => {
  Notification.success({
    message: 'Campaign Successfully Edited!',
    description:
      'Your FLP funded cashback campaign has been edited succesfully',
  });
};

export default function EditFLPCashbackCampaign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: cashbackCampaignData } = useFetchFLPCashbackListingData({ id: id });
  const { mutateAsync: updateCampaignWhitelisted } = useUpdateFLPWhitelistedMutation();
  const idOptions = cashbackCampaignData?.whitelisted_outlet_ids && arrayToOptions(cashbackCampaignData?.whitelisted_outlet_ids?.map(item => item.toString()), ' ');

  const initialValues = {
    campaign_type: cashbackCampaignData?.campaign_type,
    funder_name: cashbackCampaignData?.funder_name,
    campaign_name: cashbackCampaignData?.campaign_name,
    max_budget_amount_cents: cashbackCampaignData?.max_budget_amount_cents && cashbackCampaignData?.max_budget_amount_cents / 100,
    cashback_value_type: cashbackCampaignData?.cashback_value_type,
    cashback_amount_cents: cashbackCampaignData?.cashback_amount_cents && cashbackCampaignData?.cashback_amount_cents / 100,
    cashback_rate: cashbackCampaignData?.cashback_rate,
    max_cashback_amount_cents: cashbackCampaignData?.max_cashback_amount_cents && cashbackCampaignData?.max_cashback_amount_cents / 100,
    min_transaction_amount_cents: cashbackCampaignData?.min_transaction_amount_cents && cashbackCampaignData?.min_transaction_amount_cents / 100,
    min_monthly_transaction_count: cashbackCampaignData?.min_monthly_transaction_count,
    whitelisted_outlet_ids: cashbackCampaignData?.whitelisted_outlet_ids,
  };

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>
  ) => {
    const outletsId = values.whitelisted_outlet_ids && (values.whitelisted_outlet_ids.map(value => {
      if (typeof value === 'string') {
        return value.split(' ');
      }
      return value;
    })).flat();

    const payload = {
      id: id as string | number,
      whitelisted_outlet_ids: outletsId
    };
    await updateCampaignWhitelisted(
      { ...payload, },
      {
        onSuccess: () => {
          successNotification();
        },
        onSettled: () => {
          setSubmitting(false);
        }
      }
    );
  };

  return (
    <>
      <PageHeaderWithBackButton
        title={`Edit FLP Cashback Campaign`}
      />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <p className={style.section}>1. Campaign Type</p>
          <Col span={24}>
            <Input
              name="campaign_type"
              label="Campaign Type"
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <p className={style.section}>2. Campaign Detail</p>
          <Col span={24}>
            <Input
              name="funder_name"
              label="FLP Partner"
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="max_budget_amount_cents"
              label={`Budget (${cashbackCampaignData?.currency})`}
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <p className={style.section}>3. Cashback Detail</p>
          <Col span={8}>
            <Input
              name="cashback_value_type"
              label="Cashback type"
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          {cashbackCampaignData?.cashback_amount_cents &&
            <Col span={8}>
              <Input
                name="cashback_amount_cents"
                label={`Cashback Amount (${cashbackCampaignData?.currency})`}
                bordered
                disabled
                style={{ marginBottom: 0 }}
              />
            </Col>}
          {cashbackCampaignData?.cashback_rate &&
            <>
              <Col span={8}>
                <Input
                  name="cashback_rate"
                  label="Cashback Rate (%)"
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="max_cashback_amount_cents"
                  label={`Max Cashback Cap (${cashbackCampaignData?.currency})`}
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
            </>
          }
          <p className={style.section}>4. Cashback Condition</p>
          <Col span={24}>
            <Input
              name="min_transaction_amount_cents"
              label={`Minimum Spend (${cashbackCampaignData?.currency})`}
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="min_monthly_transaction_count"
              label="Minimum Transaction Count Per Month"
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select
              name="whitelisted_outlet_ids"
              label="Whitelisted Outlets"
              placeholder=""
              mode='tags'
              bordered
              options={idOptions}
              disabled={cashbackCampaignData?.status === 'archived' ? true : false}
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) =>
                isSubmitting ? 'Submiting...' : 'Save'
              }
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/admin/merchandise_and_campaign/cashback_campaigns')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type='primary' size='large' className={style.cancelBtn}>
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}

