import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { sideNavRoutes } from '../components/sideNavRoutes';
import cookieSet from '../helpers/cookieSet';
import useFetchUserPermissions, {
  UserPagePermission,
  UserSettingsResponseType,
} from '../services/userSettings';
import { UserType } from '../types/dataTypes';
import { CleverTapContext } from './CleverTapContext';
import { TenantContext } from './TenantContext';
import { SideNavRoutesType } from '../types/configTypes';
import { Message } from 'fave-ui';
import { validateTenant } from '../services/auth';
import { manualLogout } from '../services/auth';

type ContextType = {
  user: UserType | undefined;
  login: (user: UserType) => void;
  logout: () => void;
  isAuthenticated: boolean;
  isManualLogout: boolean;
  userSettingsLoading: boolean;
  userSettings: UserSettingsResponseType | undefined;
  getPageSettings: (screenKey: string) => UserPagePermission | undefined;
  filteredMenuItems: SideNavRoutesType[];
};

export const UserContext = createContext<ContextType>({
  user: undefined,
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  isManualLogout: false,
  userSettingsLoading: false,
  userSettings: {},
  getPageSettings: () => undefined,
  filteredMenuItems: [],
});
UserContext.displayName = 'UserContext';

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<UserType | undefined>(
    cookieSet.auth.get() || undefined
  );
  const { isLoading: userSettingsLoading, data: userSettings } =
    useFetchUserPermissions(user);
  const { cleverTapProfile } = useContext(CleverTapContext);
  const { switchTenant, selectedTenant, resetTenant } =
    useContext(TenantContext);
  const queryClient = useQueryClient();
  const [isManualLogout, setIsManualLogout] = useState(false);

  const isAuthenticated = user !== undefined;

  const login = useCallback(
    (user: UserType) => {
      cookieSet.auth.set(user);
      const firstTenant = user.tenants[0].code;
      const tenantCode = cookieSet.currentTenant.get();
      const isValidTenant = validateTenant(tenantCode);

      // to prevent custom tenant codes to be passed
      if (isValidTenant) switchTenant(tenantCode, false);
      else {
        if (tenantCode)
          Message.error({
            content: `This tenant: ${tenantCode} is not valid for the current user`,
          });
        switchTenant(firstTenant, false);
      }

      cleverTapProfile.push(user);
      setIsManualLogout(false);
      setUser(user);
    },
    [cleverTapProfile, switchTenant, setUser, setIsManualLogout, selectedTenant]
  );

  const logout = useCallback(() => {
    manualLogout().then(() => {
      cookieSet.auth.remove();
      resetTenant();
      cleverTapProfile.logout();
    });
    queryClient.removeQueries();
    setIsManualLogout(true);
    setUser(undefined);
  }, [cleverTapProfile, queryClient, setUser, setIsManualLogout, switchTenant]);

  const getPageSettings = (screenKey: string) =>
    userSettings && { ...userSettings }[screenKey];

  const getFilteredMenuItems = (
    menuItems: SideNavRoutesType[]
  ): SideNavRoutesType[] => {
    const filteredMenuItem = menuItems.filter((item) => {
      const showMenuItem =
        !item.screenkey || getPageSettings(item.screenkey)?.show;

      if (showMenuItem && item.children) {
        item.children = getFilteredMenuItems(item.children);

        const hasNoChildren = !item.children || item.children.length === 0;

        // hide menu if no children after filtering
        if (hasNoChildren) return false;
      }

      return showMenuItem;
    });

    return filteredMenuItem as unknown as SideNavRoutesType[];
  };

  const filteredMenuItems: SideNavRoutesType[] =
    !userSettingsLoading && userSettings !== undefined
      ? getFilteredMenuItems(sideNavRoutes())
      : [];

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthenticated,
        isManualLogout,
        userSettingsLoading,
        userSettings,
        getPageSettings,
        filteredMenuItems,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
