import style from './style.module.css';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PopConfirm, Button, UploadFile, Notification } from 'fave-ui';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import ImageUpload from '../../../../components/common/form/ImageUpload';
import { Select } from '../../../../components/common/form';
import { PromoCodeValues, useAddPrizeMutation } from '../../../../services/FaveArcade/useFetchPrizesListing';
import { appendFormData, arrayToOptions } from '../../../../utils/utilFunctions';

type PromoCodeProps = {
  prizeType: string;
  promoCodeValues?: PromoCodeValues;
  currency?: string;
}

type PromoCodeFormFields = {
  id?: number;
  campaign_name: string;
  prize_name: string;
  prize_subtitle?: string;
  prize_description: string;
  asset: UploadFile[];
  is_highlighted: boolean;
  product_types: string[];
  cost_of_token: number;
  promo_code_type: string;
  promo_code_value_type: string;
  amount_value_cents: number;
  percentage_value: number;
  max_cap_amount_cents: number;
  min_purchase_amount_cents?: number;
  cashback_type?: string;
  promo_code_prefix: string;
  validity_day: number;
  max_campaign_limit?: number;
  max_user_limit?: number;
  whitelisted_outlet_ids?: string;
  blacklisted_outlet_ids?: string;
  whitelisted_deal_ids?: string;
  blacklisted_deal_ids?: string;
  whitelisted_e_card_ids?: string;
  blacklisted_e_card_ids?: string;
};

const initialPromoCodeValues: PromoCodeFormFields = {
  campaign_name: '',
  prize_name: '',
  prize_description: '',
  asset: [],
  is_highlighted: false,
  product_types: [],
  cost_of_token: 0,
  promo_code_type: '',
  promo_code_value_type: '',
  amount_value_cents: 0,
  percentage_value: 0,
  max_cap_amount_cents: 0,
  min_purchase_amount_cents: 0,
  promo_code_prefix: '',
  validity_day: 0,
};

const PromoCodeFormSchema = Yup.object().shape({
  campaign_name: Yup.string().required('Please give your prize a name'),
  prize_name: Yup.string().required('Please give your prize a name'),
  prize_description: Yup.string().required('Please give your prize a description'),
  asset: Yup.array().of(Yup.mixed().nullable().required("An image is required")).min(1, 'Please upload an image'),
  product_types: Yup.array().min(1, 'Please select at least one product'),
  cost_of_token: Yup.number().min(1).required('Please enter enter the number of tokens'),
  promo_code_type: Yup.string().required('Please select one promo code value'),
  promo_code_value_type: Yup.string().required('Please select one promo code type'),
  amount_value_cents: Yup.number().when('promo_code_value_type', {
    is: 'absolute_amount',
    then: Yup.number().min(1).required('Please enter an amount in cash'),
    otherwise: Yup.number().nullable()
  }),
  percentage_value: Yup.number().when('promo_code_value_type', {
    is: 'percentage',
    then: Yup.number().min(1).required('Please enter a percentage value'),
    otherwise: Yup.number().nullable()
  }),
  max_cap_amount_cents: Yup.number().when('promo_code_value_type', {
    is: 'percentage',
    then: Yup.number().min(0).required('Please enter a max cap value'),
    otherwise: Yup.number().nullable()
  }),
  cashback_type: Yup.string().when(['promo_code_type', 'promo_code_value_type'], {
    is: (promoCodeType: string, promoCodeValue: string) => promoCodeType === 'cashback' && promoCodeValue,
    then: Yup.string().required('Choose a cashback type'),
    otherwise: Yup.string().nullable()
  }),
  promo_code_prefix: Yup.string().required('Please enter a promo code prefix'),
  validity_day: Yup.number().min(1).required('Please enter enter the period in days'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Prize Campaign Submitted!',
    description:
      'Your new submission has been added too Fave Arcade prize center succesfully',
  });
};

const PromoCodeForm = ({ prizeType, promoCodeValues, currency }: PromoCodeProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [promoValue, setPromoValue] = useState('');
  const [promoType, setPromoType] = useState('');

  const navigate = useNavigate();
  const { mutateAsync: addNewPrize } = useAddPrizeMutation('promo_code');

  const productTypes = promoCodeValues && arrayToOptions(promoCodeValues.product_types, '_');
  const cashbackTypes = promoCodeValues && arrayToOptions(promoCodeValues.promo_code_cashback_types, '_');
  const promoCodeTypes = promoCodeValues && arrayToOptions(promoCodeValues.promo_code_types, ' ');
  const promoCodeValueTypes = promoCodeValues && arrayToOptions(promoCodeValues.promo_code_value_types, '_');

  const handleSubmit = async (
    values: PromoCodeFormFields,
    { setSubmitting }: FormikHelpers<PromoCodeFormFields>
  ) => {
    const payload = {
      ...values,
      prize_type: prizeType,
      asset: values.asset[0].originFileObj,
      amount_value_cents: values.amount_value_cents * 100,
      max_cap_amount_cents: values.max_cap_amount_cents && values.max_cap_amount_cents * 100,
      min_purchase_amount_cents: values.min_purchase_amount_cents && values.min_purchase_amount_cents * 100,
      whitelisted_outlet_ids: values.whitelisted_outlet_ids?.replaceAll('\n', ',').split(','),
      blacklisted_outlet_ids: values.blacklisted_outlet_ids?.replaceAll('\n', ',').split(','),
      whitelisted_deal_ids: values.whitelisted_deal_ids?.replaceAll('\n', ',').split(','),
      blacklisted_deal_ids: values.blacklisted_deal_ids?.replaceAll('\n', ',').split(','),
      whitelisted_e_card_ids: values.whitelisted_e_card_ids?.replaceAll('\n', ',').split(','),
      blacklisted_e_card_ids: values.blacklisted_e_card_ids?.replaceAll('\n', ',').split(','),
    };
    const formData = appendFormData(payload);
    try {
      await addNewPrize(
        { formData },
        {
          onSuccess: () => {
            successNotification();
          },
        }
      );
    }
    finally {
      setSubmitting(false);
      navigate('/fave_arcade/prize_catalog');
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialPromoCodeValues}
      validationSchema={PromoCodeFormSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="campaign_name"
            label="* Campaign Name"
            placeholder="Enter campaign name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_name"
            label="* Prize Name"
            placeholder="Enter prize name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_subtitle"
            label="Prize Subtitle (optional)"
            placeholder="Enter prize subtitle"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='textArea'
            name="prize_description"
            label="* Prize Description"
            placeholder="Enter prize description"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <ImageUpload
            name="asset"
            label="* Asset (image)"
            types={["image/jpeg", "image/png"]}
            imgSize={2}
          />
        </Col>
        <Col span={12}>
          <Input
            name="is_highlighted"
            label="Highlight the Prize"
            type="checkbox"
            checked={isChecked}
            onChangeCheckbox={(e, handleChange) => {
              setIsChecked(e)
              handleChange()
            }}
          />
        </Col>
        <Col span={24}>
          <Select
            name="product_types"
            label="* Apply to"
            placeholder="Please select one or more options"
            showArrow
            withCheckbox
            treeDefaultExpandAll
            style={{ width: "100%" }}
            options={productTypes}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="cost_of_token"
            label="* Price of promo code (tokens)"
            placeholder="Enter number of tokens"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={6}>
          <Select
            name="promo_code_type"
            label="* Promo code value"
            placeholder="Please select one option"
            showArrow
            options={promoCodeTypes}
            onSelect={setPromoValue}
          />
        </Col>
        {promoValue ?
          <Col span={6}>
            <Select
              name="promo_code_value_type"
              label="* Value Type"
              placeholder="Please select one option"
              showArrow
              options={promoCodeValueTypes}
              onSelect={setPromoType}
            />
          </Col> : null}
        {promoType === 'absolute_amount' ?
          <Col span={6}>
            <Input
              type='number'
              name="amount_value_cents"
              label={`* Amount (${currency})`}
              placeholder="Enter an amount"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col> : promoType === 'percentage' ?
            <Col span={6}>
              <Input
                type='number'
                name="percentage_value"
                label="* Amount percentage (%)"
                placeholder="Enter an amount"
                allowClear
                bordered
                style={{ marginBottom: 0 }}
              />
            </Col> : null
        }
        {promoType === 'percentage' ?
          <Col span={6}>
            <Input
              type='number'
              name="max_cap_amount_cents"
              label={`Max Cap (${currency})`}
              placeholder="Enter an amount"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col> : null}
        {promoType && promoValue === 'cashback' ?
          <Col span={6}>
            <Select
              name="cashback_type"
              label="* Cashback Type"
              placeholder="Please select one option"
              showArrow
              options={cashbackTypes}
            /></Col>
          : null
        }
        <Col span={24}>
          <Input
            name="promo_code_prefix"
            label="* Promo Code Prefix (code)"
            placeholder="Enter a prefix"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="validity_day"
            label="* Validity Period"
            placeholder="Enter number of days"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="max_campaign_limit"
            label="Number of Promos (optional)"
            placeholder="Enter a number"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="max_user_limit"
            label="User purchase limit (optional)"
            placeholder="Enter a number"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type='number'
            name="min_purchase_amount_cents"
            label={`Minimum (${currency}) purchase (optional)`}
            placeholder="Enter a number"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="whitelisted_outlet_ids"
            label="Outlets Whitelisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="blacklisted_outlet_ids"
            label="Outlets Blacklisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="whitelisted_deal_ids"
            label="Deals whitelisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="blacklisted_deal_ids"
            label="Deals Blacklisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="whitelisted_e_card_ids"
            label="eCards Whitelisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <Input
            type='textArea'
            name="blacklisted_e_card_ids"
            label="eCards Blacklisted ID (optional)"
            placeholder="Enter the IDs"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <SubmitButton disableIfNotValid disableIfNotDirty>
            {(_, isSubmitting) =>
              isSubmitting ? 'Submiting...' : 'Save'
            }
          </SubmitButton>
          <PopConfirm
            title="Are you sure you want to cancel? All progress will be lost for the current campaign."
            onConfirm={() => navigate('/fave_arcade/prize_catalog')}
            okText="Yes, I am sure"
            cancelText="Go Back"
          >
            <Button type='primary' size='large' className={style.mgLeft}>
              Cancel
            </Button>
          </PopConfirm>
        </Col>
      </Row>
    </Form >
  )
}

export default PromoCodeForm;
